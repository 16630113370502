import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getDaysDiff } from '../../../utils';

const useStyles = makeStyles(({ palette }) => ({
  dayCounterPillContainer: {
    backgroundColor: ({ viewOnly }) =>
      viewOnly ? '#E8E8E8' : palette.system.white,
    position: 'absolute',
    top: 24,
    borderRadius: 50,
    padding: '8px 12px',
    color: ({ viewOnly }) => (viewOnly ? '#777777' : palette.text.primary),
  },
}));

function DayCounterPill({
  startDate,
  endDate,
  tripType,
  countDownCopyChange,
  viewOnly,
}) {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  startDate?.setHours(0, 0, 0, 0);
  endDate?.setHours(0, 0, 0, 0);

  const classes = useStyles({ viewOnly });
  const daysCount =
    tripType === 'upcoming'
      ? getDaysDiff(date, startDate)
      : tripType === 'past'
      ? getDaysDiff(endDate, date)
      : getDaysDiff(date, endDate);
  if (!daysCount) return null;
  const copy = `${daysCount} ${countDownCopyChange}`;
  return (
    <Box className={classes.dayCounterPillContainer}>
      <Typography variant="h4Sub" align="center" textTransform="uppercase">
        {copy}
      </Typography>
    </Box>
  );
}

export default DayCounterPill;
