import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Typography,
  Popper,
  List,
  ListItem,
  Tooltip,
  Skeleton,
  Snackbar,
  Alert,
  CardContent,
  Box,
  IconButton,
  Collapse,
  Portal,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Close,
  CheckCircleOutline,
  CancelOutlined,
  StarRounded,
} from '@mui/icons-material';
import { useSounds, SOUNDS } from '../../../../../sounds';
import { ArrowIcon, GoogleMapsIcon } from '../../../../atoms/Icon';
import { trackEvents, Events } from '../../../../../intercom';
import { EVENTS, phTrackEvent } from '../../../../../analytics';
import PinToMapIcon from '../../PinToMapIcon';
import { useMapUtils } from '../../../organisms/Map';
import {
  formatNumber,
  getCategoryIdFromTypes,
  getPlaceTypeFromTypes,
  updateSessionStorageForLastEditedSection,
} from '../../../../../utils';
import AddToTripButtonNew from '../../AddToTripButton/AddToTripButtonNew';
import actions from '../../../../../redux/actions';
import Carousel from '../../../../molecules/Carousel';
import useTour from '../../../../molecules/Tour/useTour';
import { stepName } from '../../../../../assets/onboarding/steps';
import ITEM_TYPES from '../../../../../const';
import { createItem, updateItem } from '../../../../../redux/slices/Item';
import { createMapPin, deleteMapPin } from '../../../../../redux/slices/MapV2';
import useTripAccess from '../../../../../utils/use-trip-access';

const useCustomCardStyles = makeStyles(({ palette }) => ({
  hoverContent: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.2s ease-in-out, opacity 0.2s ease-in-out',
  },
  snackBar: {
    position: 'absolute',
    top: (props) => (props.isAiMenuOpen ? -132 : -60),
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  description: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    '-webkit-box-pack': 'end',
  },
  addressComponent: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: (props) =>
      props.openingHoursObj || props.formattedPhoneNumber ? '195px' : 'auto',
  },
  detailedAddressComponent: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  },
  flexLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    marginBottom: 4,
  },
  title: {
    marginBottom: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-flex',
    marginRight: '4%',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 3,
    backgroundColor: '#FFF',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    width: '25px',
    height: '25px',
    '&:hover': {
      color: '#f4f4f4',
      backgroundColor: '#f4f4f4',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    height: '100%',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  reviews: {
    color: '#1877F2',
    fontSize: '0.8rem',
    marginLeft: '5%',
  },
  nullReviews: {
    color: '#8A8A8A',
    fontStyle: 'italic',
    marginLeft: '5%',
  },
  defaultStartIcon: {
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px 0px',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  detailsBlock: {
    display: 'flex',
  },
  detailsBlockIcon: {
    flexShrink: 0,
    marginRight: 6,
  },
  endContainer: {
    flexShrink: 1,
    display: 'flex',
    marginTop: 8,
    justifyContent: 'space-between',
  },
  toggleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  toggleExpand: {
    color: '#686868',
    textDecoration: 'underline',
    fontSize: '0.8rem',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  datePopper: {
    zIndex: 1121,
  },
  dateList: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    boxShadow: '0 0 0 1px #DEDDDD',
  },
  dateListItem: {
    fontSize: 12,
    padding: '2px 12px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#222222',
    },
    '& .active': {
      backgroundColor: '#EAEAEA',
      fontWeight: 'bold',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  dateListItemActive: {
    fontSize: 12,
    padding: '2px 12px',
    backgroundColor: '#EAEAEA',
    fontWeight: 'bold',
    color: '#222222',
    '&:hover': {
      backgroundColor: '#EAEAEA',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  placeType: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltip: {
    background: 'rgba(0,0,0,.8)',
    fontSize: 12,
  },
  tooltipArrow: {
    color: 'rgba(0,0,0,.8)',
  },
  tooltipDisabled: {
    display: 'none',
  },
}));

function MapPopupCardNew({
  placeId,
  photo,
  photos,
  title,
  rating,
  description = '',
  website,
  maps,
  ratingCount,
  addressComponents,
  formattedAddress,
  formattedPhoneNumber,
  handleClose,
  tripId,
  lat,
  long,
  pinned,
  pinId,
  pins,
  type,
  types,
  categoryId,
  pinColor,
  openingHoursObj,
  isCommandBarEnabled,
  // handleImageError = () => {},
  initAction = () => {},
  postAction = () => {},
  setLoader = () => {},
}) {
  const [datePopperOpen, setDatePopperOpen] = useState(false);
  const [dateAnchor, setDateAnchor] = useState(null);
  const [currPlaceId, setCurrPlaceId] = useState(false);
  const [activeCardDetails, setActiveCardDetails] = useState({});
  const [cardLoading, setCardLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [active, setActive] = useState(pinned);
  const [isExpanded, setIsExpanded] = useState(false);
  const [portalContainer, setPortalContainer] = useState(null);
  const [showIconButton, setShowIconButton] = useState(false);
  const [showCarouselArrows, setShowCarouselArrows] = useState(false);
  const [currPin, setCurrPin] = useState(pinId);

  const deletedItemIdRef = useRef(null);
  const datePopperId = datePopperOpen ? 'date-popper' : undefined;

  const { playSound } = useSounds();
  const dispatch = useDispatch();

  const {
    getPlaceDetails,
    place,
    setPlace,
    getCachedPlacesItem,
    extractAddressComponents,
    createMapPinForPlace,
    setMarker,
  } = useMapUtils();

  const tripMapPins = useSelector(
    (state) => state.TripsV2.tripsAtc[tripId]?.mapPins || []
  );

  const { canEdit } = useTripAccess();

  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || []);
  const items = useSelector((state) => state.Item.items);
  const { isAiMenuOpen } = useSelector((state) => state.View.commandBar);
  const classes = useCustomCardStyles({
    isAiMenuOpen,
    openingHoursObj,
    formattedPhoneNumber,
  });

  const { tourInProgress, tourName, getTour } = useTour();

  // function to check for pins that exist with the same properties.
  const findPin = () => {
    let id;
    pins.forEach((pin) => {
      if (pin?.lat === lat && pin?.long === long && pin?.type === type) {
        id = pin?.id;
      }
    });
    return id;
  };

  // Check if a pin that's not a trip map pin, is added to the trip
  const checkIfAddedToPlan = () =>
    pins?.filter(
      (pin) =>
        !tripMapPins?.includes(pin?.id) &&
        pin?.placeId === placeId &&
        !pin?.tempRemove
    )?.length > 0 || false;

  const computeActiveCardProps = (cardPlaceId) => {
    const activeCardItems = {};
    const foundPin = Object.values(mapPins || {}).find(
      (pin) => pin.placeId === cardPlaceId
    );
    activeCardItems.mapPin = foundPin?.id ?? null;
    if (activeCardItems?.mapPin) {
      const foundItem = Object.values(items || {}).find(
        (item) =>
          item.mapPin === activeCardItems?.mapPin && item?.tripId === tripId
      );
      activeCardItems.itemId = foundItem?.id ?? null;
      activeCardItems.itemName = foundItem?.title ?? null;
      activeCardItems.itemType = foundItem?.type ?? null;
      const parentId = Object.values(items || {}).find((item) =>
        item?.children?.includes(foundItem?.id)
      );

      activeCardItems.parentId = parentId?.id ?? null;
      activeCardItems.parentName = parentId?.title ?? null;
    }
    setActiveCardDetails({ ...activeCardItems });
  };

  useEffect(() => {
    computeActiveCardProps(placeId);
  }, [placeId, mapPins, items]);

  useEffect(() => {
    if (active !== pinned || currPin !== pinId) {
      setActive(pinned);
      setCurrPin(pinId);
    }
  }, [pinned]);

  useEffect(() => {
    const container = document.getElementById('snackbar-container');
    setPortalContainer(container);
  }, []);
  useEffect(() => {
    getTour()
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .openTour({ delay: 1500 });
  }, [tourInProgress, tourName]);
  const handleDateHover = (e) => {
    if (datePopperOpen) return;
    setDatePopperOpen(true);
    setDateAnchor(e.currentTarget);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  function TextTooltipElement({ title: toolTipTitle, children }) {
    const thisRef = useRef(null);
    // const classes = useCustomCardStyles();
    const [showTooltip, setShowTooltip] = useState(false);
    const onHover = () => {
      if (
        !showTooltip &&
        thisRef.current.offsetWidth < thisRef.current.scrollWidth
      ) {
        setShowTooltip(true);
      } else if (showTooltip) {
        setShowTooltip(false);
      }
    };
    return (
      <Tooltip
        title={toolTipTitle}
        placement="top"
        classes={{
          tooltip: showTooltip ? classes.tooltip : classes.tooltipDisabled,
        }}
        enterDelay={600}
        leaveDelay={0}
        className="dynamic-text"
        ref={thisRef}
        onMouseOver={onHover}>
        {children}
      </Tooltip>
    );
  }

  const handleDeleteMapPin = async () => {
    if (checkIfAddedToPlan()) {
      if (activeCardDetails?.mapPin) {
        await Promise.all([
          dispatch(
            deleteMapPin({
              context: {
                tripId,
              },
              variables: {
                id: activeCardDetails?.mapPin,
              },
            })
          ),
          // also remove the geo location from an item
          activeCardDetails?.itemId &&
            dispatch(
              updateItem({
                variables: {
                  id: activeCardDetails?.itemId,
                  content: {
                    address: null,
                  },
                },
              })
            ),
        ]);

        if (activeCardDetails?.itemId) {
          deletedItemIdRef.current = activeCardDetails?.itemId;
        }

        if (activeCardDetails?.itemName && activeCardDetails?.parentName) {
          setToastMessage(
            `Deleted ${activeCardDetails?.itemName} from ${activeCardDetails?.parentName}`
          );
        } else {
          setToastMessage(`Deleted ${title}`);
        }
        setShowSnackBar(true);
      }
      setActive(false);
      setCurrPin(null);
      setPlace((val) => ({
        ...val,
        pinId: null,
        categoryId: null,
      }));
    }
  };

  // TODO: Add placeType
  const placeType = getPlaceTypeFromTypes(types);

  const renderOpeningDays = () => {
    const day = new Date().getDay();
    const getClassName = (index) => {
      if (day === index + 1 && day !== 0) {
        return classes.dateListItemActive;
      }
      if (day === 0 && index === 6) {
        return classes.dateListItemActive;
      }
      return classes.dateListItem;
    };
    if (openingHoursObj) {
      return openingHoursObj.weekday_text.map((str, index) => (
        <ListItem className={getClassName(index)} key={index}>
          {str.split(': ', 2).map((ele, i) => (
            <span key={i}>{ele}</span>
          ))}
        </ListItem>
      ));
    }
    return null;
  };

  const getOpeningDaysBlockText = () => {
    const weekDay = new Date().getDay();
    const weekDayText = openingHoursObj?.weekday_text[
      weekDay === 0 ? 6 : weekDay - 1
    ].split(': ', 2);
    if (
      openingHoursObj?.open_now ||
      (!openingHoursObj?.open_now && weekDayText[1] !== 'Closed')
    ) {
      return `${weekDayText[1]} (${weekDayText[0].slice(0, 3)})`;
    }
    return weekDayText[0];
  };

  // Fallback image in case Places API returns no image or an invalid image
  const fallBackUrl = '/images/DefaultMapPopupBanner.png';

  /* eslint-disable-next-line */
  const [imgUrl, setImgUrl] = useState(photo || fallBackUrl);

  // useEffect(() => {
  //   if (photo) {
  //     const img = new Image();
  //     img.src = photo;

  //     img.onload = () => {
  //       if (img.height === 100 && img.width === 100) {
  //         setImgUrl(fallBackUrl);
  //         if (
  //           placeId &&
  //           process.env.REACT_APP_DISABLE_PLACES_IMAGE !== 'true'
  //         ) {
  //           handleImageError();
  //         }
  //       } else {
  //         setImgUrl(photo);
  //       }
  //     };

  //     img.onerror = () => {
  //       setImgUrl(fallBackUrl);
  //       if (placeId) {
  //         handleImageError();
  //       }
  //     };
  //   }
  // }, [photo, placeId, handleImageError]);

  // Use the provided `photos` array or fallback image if no photos are available
  const carouselImages = photos && photos.length > 0 ? photos : [imgUrl];

  const createNewTripMapPin = async (pinDetails = {}) => {
    const checkId = findPin();
    if (checkId) {
      return;
    }
    const mapPinData = await dispatch(
      createMapPin({
        variables: {
          lat,
          long,
          type,
          placeId,
          pinColor,
          tripId,
          parentId: tripId,
          categoryId:
            categoryId ||
            (type === 'ACCOMMODATION'
              ? 'stay'
              : getCategoryIdFromTypes(pinDetails?.types)),
          ...pinDetails,
          pinDetails: {
            title,
            photo,
            rating,
            ratingCount,
            types,
            website,
            mapUrl: maps,
          },
        },
      })
    );
    setMarker(null);
    setActive(true);
    setPlace((val) => ({
      ...val,
      pinned: true,
      pinId: mapPinData?.payload?.createMapPin?.id,
      categoryId: mapPinData?.payload?.createMapPin?.categoryId,
    }));
    setCurrPin(mapPinData?.payload?.createMapPin?.id);
    return mapPinData?.payload?.createMapPin?.id;
  };

  /* eslint-disable-next-line */
  const refreshPlaceDetails = async () => {
    setCardLoading(true);
    window?.heap?.track('Map-card-refreshed');

    try {
      // get details from sessionStorage, if it doesn't exist,
      let details = getCachedPlacesItem(placeId) || null;

      if (details === null) {
        details = await getPlaceDetails(placeId, type, true);
        window?.heap?.track('Map-card-new-request');
      } else {
        window?.heap?.track('Map-card-cache-used');
      }

      setPlace({
        ...place,
        maps: details?.maps,
        website: details?.website,
        photo: details?.photo,
        photos: details?.photos,
        rating: details?.rating,
        lat: details?.lat,
        long: details?.long,
        ratingCount: details?.ratingCount,
        openingHoursObj: details?.openingHoursObj,
        addressComponents: details?.addressComponents,
        formattedAddress: details?.formattedAddress,
        types: details?.types,
        formattedPhoneNumber: details?.formattedPhoneNumber,
      });
    } catch (err) {
      // Error - probably because it's not a valid placeId
    }
    setCardLoading(false);
  };

  useEffect(() => {
    if (placeId && placeId !== currPlaceId) {
      setCurrPlaceId(placeId);
      refreshPlaceDetails();
    }
  }, [placeId]);

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleAddActivity = async (
    locationId,
    sectionId,
    sectionName,
    locationName,
    autoAdd = false
  ) => {
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().closeTour();
    }
    setLoader(true);
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    const result = await initAction({
      newLocationId: locationId,
      newSectionId: sectionId,
    });
    playSound(SOUNDS.inOutClick);

    // action already done, no need to repeat activity
    if (result === 'NO-ACTION') {
      setLoader(false);
      return;
    }

    phTrackEvent({
      event: autoAdd
        ? EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_AUTO_ADD
        : EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_MANUAL_ADD,
    });
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_AUTO_ADD_ACTIVITY}${title}`,
    });
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    const lastUpdatedSectionDetails = JSON.parse(
      window?.sessionStorage?.getItem('lastUpdatedSectionDetails') || '{}'
    );
    const lastUpdatedSectionId =
      (autoAdd &&
        lastUpdatedSectionDetails &&
        lastUpdatedSectionDetails[locationId]) ||
      sectionId;
    let addedActivity = null;
    if (sectionId === null) {
      const headingId = (
        await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.HEADING,
              title: 'Things to do',
              tripId,
              parentId: locationId,
              index: 0,
            },
            index: 0,
            tripId,
            parentId: locationId,
          })
        )
      )?.payload?.createItem?.id;
      addedActivity = await dispatch(
        createItem({
          variables: {
            type: ITEM_TYPES.ACTIVITY,
            title,
            mapPin: mapPin?.id,
            tripId,
            parentId: headingId,
            index: 0,
          },
          index: 0,
          tripId,
          parentId: headingId,
        })
      );
      setToastMessage(`Added to ${locationName} > Things to do`);
      setShowSnackBar(true);
    } else {
      addedActivity = await dispatch(
        createItem({
          variables: {
            title,
            mapPin: mapPin?.id,
            parentId: lastUpdatedSectionId,
            index: items[lastUpdatedSectionId]?.children?.length || 0,
            type: ITEM_TYPES.ACTIVITY,
            content: {
              address,
            },
            tripId,
          },
          index: items[lastUpdatedSectionId]?.children?.length || 0,
          tripId,
          parentId: lastUpdatedSectionId,
        })
      );
      // updated the last updated section under destination
      updateSessionStorageForLastEditedSection(
        locationId,
        lastUpdatedSectionId
      );
      const element = document.getElementById(`activity-blade-${mapPin?.id}`);
      if (element && !getTour().tourInProgress) {
        const activityId = addedActivity?.payload?.createItem?.id;
        if (activityId) {
          dispatch(
            actions.View.setHighlightedToDo({
              todo: activityId,
            })
          );
        }
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (sectionName) {
        setToastMessage(`Added to ${locationName} > ${sectionName}`);
      } else {
        setToastMessage(`Added to ${locationName}`);
      }
      setShowSnackBar(true);
    }
    trackEvents(Events.LocationAddedFromMap);
    await postAction({
      locationId,
      sectionId,
    });
    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({
        delay: 1000,
      });
    } else {
      getTour()
        .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO, handleClose)
        .openTour({
          delay: 1000,
        });
    }
  };

  const handleUndo = async () => {
    setToastMessage('');
    setShowSnackBar(false);
    phTrackEvent({
      event: EVENTS.PLAN_MAP_CARD.ACTIVITY_DELETE_UNDO,
    });
    const createdMappinId = await createNewTripMapPin();

    // also attach the mapPinId to item
    if (deletedItemIdRef.current) {
      const address = extractAddressComponents(addressComponents);
      dispatch(
        updateItem({
          variables: {
            id: deletedItemIdRef.current,
            mapPin: createdMappinId,
            tripId,
            content: {
              address,
            },
          },
        })
      );
      deletedItemIdRef.current = null;
    }
  };

  return (
    <>
      <Card
        sx={{
          width: 280,
          height: 400,
          position: 'relative',
          zIndex: 0,
          borderRadius: '8px',
        }}
        onMouseEnter={() => {
          setShowIconButton(true);
          setShowCarouselArrows(true);
        }}
        onMouseLeave={() => {
          setShowIconButton(false);
          setShowCarouselArrows(false);
        }}>
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            zIndex: 0,
          }}>
          {showIconButton && (
            <IconButton
              className={classes.close}
              disableFocusRipple
              disableRipple
              onClick={handleClose}
              style={{ cursor: 'pointer', zIndex: 1 }}>
              <Close sx={{ fontSize: '14px', color: '#000000' }} />
            </IconButton>
          )}

          <Box
            sx={{
              position: 'relative',
              height: '100%',
              overflow: 'hidden',
              zIndex: 0,
            }}>
            <Box
              sx={{
                transition: 'height 0.3s ease-in-out',
                height: isExpanded ? '60%' : '100%',
                overflow: 'hidden',
                zIndex: 1,
              }}>
              <Carousel
                showArrows={showCarouselArrows}
                images={carouselImages}
                isRecomendationCard={Boolean(true)}
                isLargerHeight={Boolean(true)}
              />
            </Box>

            <CardContent
              sx={{
                position: 'absolute',
                bottom: '-10px',
                width: '100%',
                height: 'auto',
                backgroundColor: 'rgba(255, 255, 255)',
                transition: 'height 0.3s ease-in-out',
                overflow: 'hidden',
                padding: '10px',
                zIndex: 2,
              }}>
              <div className={classes.cardContent}>
                <div className={classes.headerContainer}>
                  <div className={classes.flexLeft}>
                    <div className={classes.title}>
                      <TextTooltipElement title={title}>
                        <Typography
                          noWrap
                          variant="h4"
                          sx={{ fontWeight: 'bold' }}>
                          {title}
                        </Typography>
                      </TextTooltipElement>
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        marginLeft: 'auto',
                      }}>
                      <PinToMapIcon
                        key={title}
                        mapPinDetails={{
                          id: pinId,
                          lat,
                          long,
                          pinDetails: {
                            title,
                            photo,
                            rating,
                            description,
                            website,
                            maps,
                            ratingCount,
                            types,
                          },
                          categoryId,
                          pinColor,
                          type,
                          placeId,
                        }}
                        tripId={tripId}
                        isActive={active}
                        isAdded={checkIfAddedToPlan()}
                        handleDeleteMapPin={handleDeleteMapPin}
                      />
                    </div>
                  </div>
                </div>
                {cardLoading ? (
                  <div>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton variant="rounded" width="100%" height="80" />
                  </div>
                ) : (
                  <>
                    <div className={classes.ratingContainer}>
                      {placeType && (
                        <TextTooltipElement title={placeType}>
                          <span className={classes.placeType}>{placeType}</span>
                        </TextTooltipElement>
                      )}
                    </div>
                    <div className={classes.detailsContainer}>
                      {!isExpanded && formattedAddress && (
                        <div className={classes.detailsBlock}>
                          <TextTooltipElement title={formattedAddress}>
                            <Typography
                              className={classes.addressComponent}
                              style={{ WebkitLineClamp: 1, lineClamp: 1 }}>
                              {formattedAddress}
                            </Typography>
                          </TextTooltipElement>
                          {openingHoursObj || formattedPhoneNumber ? (
                            <Typography
                              onClick={handleToggle}
                              sx={{
                                color: '#000000',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontWeight: '600',
                                marginLeft: '4px',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}>
                              See more
                            </Typography>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <Collapse in={isExpanded}>
                      <div className={classes.detailsContainer}>
                        {formattedAddress && (
                          <div className={classes.detailsBlock}>
                            <TextTooltipElement title={formattedAddress}>
                              <Typography
                                className={classes.detailedAddressComponent}
                                style={{ WebkitLineClamp: 1, lineClamp: 1 }}>
                                {formattedAddress}
                              </Typography>
                            </TextTooltipElement>{' '}
                          </div>
                        )}
                        {openingHoursObj && (
                          <div
                            className={classes.detailsBlock}
                            style={{ display: 'flex', alignItems: 'center' }}
                            onMouseOver={handleDateHover}
                            onMouseLeave={() => setDatePopperOpen(false)}>
                            <Typography className={classes.description}>
                              {openingHoursObj?.open_now ? (
                                <span style={{ color: '#149F22' }}>
                                  OPEN NOW
                                </span>
                              ) : (
                                <span style={{ color: '#E5493D' }}>CLOSED</span>
                              )}{' '}
                              · {getOpeningDaysBlockText()}
                            </Typography>
                            <ArrowIcon />
                            <Popper
                              id={datePopperId}
                              placement="top-start"
                              open={datePopperOpen}
                              anchorEl={dateAnchor}
                              className={classes.datePopper}>
                              <List className={classes.dateList}>
                                {renderOpeningDays()}
                              </List>
                            </Popper>
                          </div>
                        )}
                        {formattedPhoneNumber && (
                          <div className={classes.detailsBlock}>
                            <Typography className={classes.description}>
                              {formattedPhoneNumber}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <Typography
                        onClick={handleToggle}
                        sx={{
                          color: '#000000',
                          cursor: 'pointer',
                          fontSize: '12px',
                          fontWeight: '600',
                          width: 'fit-content',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}>
                        {isExpanded && 'See less'}
                      </Typography>
                    </Collapse>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}>
                      {maps && (
                        <Box
                          sx={{
                            '&:hover': {
                              backgroundColor: '#f4f4f4',
                            },
                            height: '30px',
                            width: '30px',
                            borderRadius: '4px',
                            padding: '3px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onClick={() => window.open(maps, '_blank')}>
                          <IconButton
                            sx={{
                              padding: 0,
                              margin: 0,
                            }}
                            disableFocusRipple
                            disableRipple>
                            <GoogleMapsIcon />
                          </IconButton>
                        </Box>
                      )}
                      {ratingCount > 0 && (
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          mt="2px">
                          <StarRounded
                            sx={{
                              color: '#ED702E',
                              height: 20,
                              width: 20,
                            }}
                          />
                          <Typography
                            variant="h5"
                            color="text.secondary"
                            ml="2px"
                            mt="1px"
                            sx={{ display: 'flex', alignItems: 'center' }}>
                            <b>{rating}</b>{' '}
                            {`(${
                              (ratingCount && formatNumber(ratingCount)) || 0
                            })`}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        display={canEdit ? 'flex' : 'none'}
                        alignItems="center"
                        justifyContent="flex-end"
                        id="add-to-trip-button-container"
                        sx={{ width: 'auto', marginLeft: 'auto' }}>
                        <AddToTripButtonNew
                          tripId={tripId}
                          isAdded={checkIfAddedToPlan()}
                          placeDetails={{
                            title,
                            description,
                            photo,
                            rating,
                            website,
                            maps,
                            ratingCount,
                            long,
                            lat,
                            types,
                            placeId,
                            categoryId,
                          }}
                          additionalPlaceDetails={{
                            openingHoursObj,
                            addressComponents,
                            formattedAddress,
                            formattedPhoneNumber,
                            maps,
                            website,
                          }}
                          addressComponents={addressComponents}
                          hoverContentClass={classes.hoverContent}
                          handleAddActivity={handleAddActivity}
                          setToastMessage={setToastMessage}
                          setShowSnackBar={setShowSnackBar}
                          isCommandBarEnabled={isCommandBarEnabled}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </CardContent>
          </Box>
        </Box>
      </Card>
      {portalContainer && (
        <Portal container={portalContainer}>
          <Snackbar
            className={classes.snackBar}
            open={showSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackBarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              maxWidth: '600px',
              width: '100%',
            }}>
            <Alert
              severity={!checkIfAddedToPlan() ? 'error' : 'success'}
              variant="filled"
              iconMapping={{
                success: <CheckCircleOutline fontSize="inherit" />,
                error: <CancelOutlined fontSize="inherit" />,
              }}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '8px',
                paddingLeft: '8px',
                paddingTop: '1px',
                paddingBottom: '1px',
              }}
              action={
                !checkIfAddedToPlan() && (
                  <Typography
                    onClick={handleUndo}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'white',
                      marginLeft: 4,
                      marginRight: 2,
                      paddingBottom: 0.5,
                    }}>
                    Undo
                  </Typography>
                )
              }>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flexGrow: 1,
                }}>
                {toastMessage}
              </Typography>
            </Alert>
          </Snackbar>
        </Portal>
      )}
    </>
  );
}

export default MapPopupCardNew;
