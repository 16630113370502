/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { parseISODate, removeTimezoneOffset } from '../../utils';
import IconDatePicker from './IconDatePicker';
import classList from '../classList';
import { updateTrip } from '../../redux/slices/Trips';
import { BookingsActions } from '../../redux/slices/Bookings';
import useTripAccess from '../../utils/use-trip-access';

const useStyles = makeStyles(() => ({
  iconButtonPropsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px',
  },
  dateStringPropsRoot: {
    marginLeft: '8px',
    marginRight: '0em',
  },
}));

export default function TripDateSelector({ trip, tripStartDate, tripEndDate }) {
  const classes = useStyles();
  const { slug } = useParams();
  const [tripDate, setTripDate] = useState({
    from: tripStartDate,
    to: tripEndDate,
  });

  const dispatch = useDispatch();
  const { isViewOnly } = useTripAccess();

  const updateTripDate = (date) => {
    const newStartDate = date?.from;
    const newEndDate = date?.to;

    const dateToUpdate = {
      from:
        newStartDate &&
        (typeof newStartDate === 'string'
          ? newStartDate
          : removeTimezoneOffset(newStartDate)?.toISOString()),
      to:
        newEndDate &&
        (typeof newEndDate === 'string'
          ? newEndDate
          : removeTimezoneOffset(newEndDate)?.toISOString()),
    };

    dispatch(
      updateTrip({
        variables: {
          id: slug,
          startDate: dateToUpdate?.from,
          endDate: dateToUpdate?.to,
        },
      })
    );
  };

  return (
    <Box
      onClick={(e) => {
        console.log('isViewOnly', isViewOnly);

        if (isViewOnly) {
          e.stopPropagation();
        }
      }}
      sx={{
        ...(isViewOnly && {
          opacity: 0.5,
          cursor: 'not-allowed',
        }),
      }}
    />
  );
}
