import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import graphqlClient from '../../V3/graphql/index';
import QUERY from '../../V3/graphql/queries';
import { trackEvents, Events } from '../../intercom';
import { BookingsActions } from './Bookings';
import { RecommendationsActions } from './Recommendations';
import { createItem, deleteItem, ItemActions, moveItem } from './Item';
import ITEM_TYPES, { DROP_TYPES, TRIP_ACCESS_ROLES } from '../../const';
import {
  getFilesPropertiesV2,
  getMapPinListV2,
  getAsyncThunkV2,
} from '../helpers';

export const isLocalItem = (itemId, itemType) =>
  typeof itemId === 'string' && itemId?.startsWith(`local-${itemType}`);

const normalizeTrips = (GQLTrips, existingTrips = {}) => {
  const trips = {};
  GQLTrips.forEach((trip) => {
    trips[trip.id] = {
      // if the getUserTrip completes before the getAllTrips, prioritize information from the user trip.
      ...trip,
      status: 'IDLE',
      ...(existingTrips[trip?.id] || {}),
    };
  });
  return trips;
};

const normalizeTrip = (trips, GQLTrip, items, dispatch, tripItemIds) => {
  const { mapPins = [], files = [], saved = [], ...tripProperties } = GQLTrip;
  const trip = {
    ...trips[tripProperties.id],
    ...tripProperties,
    children: tripProperties?.children?.filter((childId) =>
      tripItemIds.includes(childId)
    ),
    status: 'SUCCESS',
  };
  const { files: allFiles, fileRelations: allFilesRelations } =
    getFilesPropertiesV2(files, items, tripProperties.id);

  if (items) {
    // add the items in the item slice
    const itemObj = {};
    items.forEach((item) => {
      itemObj[item.id] = {
        ...item,
        children: item?.children?.filter((childId) =>
          tripItemIds.includes(childId)
        ),
        mapPin: item?.mapPin?.id,
        files: item?.files?.map((file) => file?.id),
      };
    });
    dispatch(ItemActions.updateItems(itemObj));
  }

  dispatch(
    RecommendationsActions.initializeSaves({
      saved: saved || [],
      tripId: trip?.id,
    })
  );

  dispatch(
    BookingsActions.initializeSaves({
      saved: saved || [],
      tripId: trip?.id,
    })
  );

  return {
    ...trip,
    files: files?.map((file) => file?.id),
    mapPins: mapPins?.map((mapPin) => mapPin?.id),
    tripFiles: allFiles,
    saved,
    allFilesRelations,
  };
};

const initialState = {
  tripsAtc: {},
  status: 'IDLE', // IDLE, LOADING, SUCCESS
  mapPins: [],
  invitations: [],
  travelStats: null,
  accessRequestedTrips: [],
  accessRequests: {},
  exportPdf: {
    options: {
      format: 'pdf',
      includeImages: true,
      includeNotes: true,
    },
    mapPinImages: {},
  },
};

export const getTripsUsingAtc = createAsyncThunk(
  'TRIPS_V2/getTripsUsingAtc',
  async (_, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_ALL_TRIPS,
        variables: {},
        fetchPolicy: 'network-only',
      });
      if (error) throw new Error(error.message);
      // All normalization should happen here to be able to trigger the extra reducers with ready data.
      return {
        trips: data.getUserTrips,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const processChildItems = (itemId, items) => {
  const childIds = [itemId];
  items[itemId]?.children?.forEach((childId) => {
    const childItemIds = processChildItems(childId, items);
    childIds.push(...childItemIds);
  });
  if (items[itemId]?.type === ITEM_TYPES.TRANSPORTATION) {
    const connectionIds = items[itemId]?.content?.connections || [];
    childIds.push(...connectionIds);
  }
  return childIds;
};

export const getCompleteTripUsingAtc = createAsyncThunk(
  'TRIPS_V2/getCompleteTripUsingAtc',
  async (
    { tripId, ignoreRejection = false },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_TRIP,
        variables: { id: tripId },
        fetchPolicy: 'network-only',
      });

      if (error) throw new Error(error.message);
      const tripData = data?.getTripData?.trip;
      const itemData = data?.getTripData?.items;

      // parse through the entire trip, and filter out items that are not in the itemData
      const tripItemIds = [];
      const itemObj = itemData?.reduce((acc, item) => {
        acc[item?.id] = item;
        return acc;
      }, {});

      tripData?.children?.forEach((childId) => {
        const itemIds = processChildItems(childId, itemObj);
        tripItemIds.push(...itemIds);
      });

      const items = itemData?.filter((item) => tripItemIds.includes(item?.id));

      const { tripFiles, allFilesRelations, ...trip } = normalizeTrip(
        getState().tripsAtc.tripsAtc,
        tripData,
        items,
        dispatch,
        itemData?.map((item) => item?.id)
      );
      const mapPinList = getMapPinListV2(
        data?.getTripData?.trip?.mapPins,
        items
      );
      return {
        trip,
        mapPins: data?.getTrip?.mapPins?.map((mapPin) => mapPin?.id) || [],
        mapPinList,
        tripFiles,
        allFilesRelations,
        meta: { tripId },
      };
    } catch (e) {
      if (!ignoreRejection) return rejectWithValue(e);
      return {};
    }
  }
);

export const createTripUsingAtc = createAsyncThunk(
  'TRIPS_V2/createTrip',
  async ({ trip, callback }, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.mutate({
        mutation: QUERY.CREATE_TRIP,
        variables: trip,
      });
      if (error) throw error;
      trackEvents(Events.TripCreated);
      return { trip: data?.createTrip, callback };
    } catch (err) {
      rejectWithValue(err);
      return null;
    }
  }
);

export const addExampleTripV2 = getAsyncThunkV2(
  'TRIPS_V2/addExampleTrip',
  QUERY.ADD_EXAMPLE_TRIP
);

export const updateTripUsingAtc = createAsyncThunk(
  'TRIPS_V2/updateTripUsingAtc',
  async ({ variables }, { rejectWithValue }) => {
    const { data, error } = await graphqlClient.mutate({
      mutation: QUERY.UPDATE_TRIP,
      variables,
    });
    if (error) {
      rejectWithValue(error);
    }
    return data;
  }
);

export const deleteTripUsingAtc = createAsyncThunk(
  'TRIPS_V2/deleteTripUsingAtc',
  async (tripId, { dispatch, rejectWithValue }) => {
    try {
      const { error } = await graphqlClient.mutate({
        mutation: QUERY.DELETE_TRIP,
        variables: { id: tripId },
      });
      if (error) {
        throw error;
      }
      dispatch(ItemActions.deleteItemsByTripId({ tripId }));
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createTripInvitation = getAsyncThunkV2(
  'TRIPS_V2/createTripInvitation',
  QUERY.CREATE_INVITATION
);

export const deleteTripInvitation = getAsyncThunkV2(
  'TRIPS_V2/deleteTripInvitation',
  QUERY.DELETE_INVITATION
);

export const addUserToTrip = getAsyncThunkV2(
  'TRIPS_V2/addUserToTrip',
  QUERY.ADD_USER_TO_TRIP
);

export const removeUserFromTrip = getAsyncThunkV2(
  'TRIPS_V2/removeUserFromTrip',
  QUERY.REMOVE_USER_FROM_TRIP
);

export const updateTripUserRole = getAsyncThunkV2(
  'TRIPS_V2/updateTripUserRole',
  QUERY.UPDATE_TRIP_USER_ROLE
);

export const createAccessRequest = getAsyncThunkV2(
  'TRIPS_V2/createAccessRequest',
  QUERY.CREATE_ACCESS_REQUEST
);

export const acceptRequestAndAddToTrip = getAsyncThunkV2(
  'TRIPS_V2/acceptRequestAndAddToTrip',
  QUERY.ACCEPT_REQUEST_AND_ADD_TO_TRIP
);

export const getPendingAccessRequests = getAsyncThunkV2(
  'TRIPS_V2/getPendingAccessRequests',
  QUERY.GET_PENDING_ACCESS_REQUESTS
);

export const getPendingAccessRequestByUserIdAndTripId = getAsyncThunkV2(
  'TRIPS_V2/getPendingAccessRequestByUserIdAndTripId',
  QUERY.GET_PENDING_ACCESS_REQUEST_BY_USER_ID_AND_TRIP_ID
);

export const updateRequestStatus = getAsyncThunkV2(
  'TRIPS_V2/updateRequestStatus',
  QUERY.UPDATE_REQUEST_STATUS
);

const TripAtcSlice = createSlice({
  name: 'TRIPS_V2',
  initialState,
  reducers: {
    addChildToTrip: (state, { payload: { tripId, childId, index } }) => {
      const currentChildren = state.tripsAtc[tripId]?.children || [];
      state.tripsAtc[tripId].children = [
        ...currentChildren.slice(0, index),
        childId,
        ...currentChildren.slice(index),
      ];
    },
    removeFileFromTrip: (state, { payload: { tripId, fileId } }) => {
      state.tripsAtc[tripId].files = state.tripsAtc[tripId].files?.filter(
        (file) => file !== fileId
      );
    },
    setActiveBookingId: (state, { payload: { bookingId, tripId } }) => {
      if (!tripId || !state.tripsAtc[tripId]) return;
      state.tripsAtc[tripId].activeBookingId = bookingId;
    },
    setExportPdfMapPinImages: (state, { payload }) => {
      state.exportPdf.mapPinImages = payload;
    },
    setExportPdfOptions: (
      state,
      { payload: { format, includeImages, includeNotes } }
    ) => {
      state.exportPdf.options = {
        ...state.exportPdf.options,
        format,
        includeImages,
        includeNotes,
      };
    },
    updateTripUserRole: (state, { payload }) => {
      // if user doesn't exist, add them to the trip, update otherwise
      if (state.tripsAtc[payload.tripId]) {
        if (
          !state.tripsAtc[payload.tripId]?.users?.find(
            (user) => user.id === payload.userId
          )
        ) {
          state.tripsAtc[payload.tripId].users.push({
            id: payload.userId,
            role: payload.role,
          });
        } else {
          state.tripsAtc[payload.tripId] = {
            ...state.tripsAtc[payload.tripId],
            users: state.tripsAtc[payload.tripId].users.map((user) =>
              user.id === payload.userId
                ? { ...user, role: payload.role }
                : user
            ),
          };
        }
      }
      if (payload.role === TRIP_ACCESS_ROLES.REMOVED) {
        // remove trip from the user's trips
        state.tripsAtc = Object.keys(state.tripsAtc).reduce((acc, tripId) => {
          if (tripId !== payload.tripId) {
            acc[tripId] = state.tripsAtc[tripId];
          }
          return acc;
        }, {});
      }
    },
    updateTripPrivacySettings: (state, { payload }) => {
      if (state.tripsAtc[payload.tripId]) {
        state.tripsAtc[payload.tripId].privacySettings =
          payload.privacySettings;
      }
    },
  },
  extraReducers: {
    [getTripsUsingAtc.pending]: (state, { meta: { setLoader = true } }) => {
      if (setLoader) state.status = 'LOADING';
    },
    [getTripsUsingAtc.fulfilled]: (state, { payload }) => {
      state.status = 'SUCCESS';
      const { trips } = payload;
      state.count = trips?.length || 0;
      if (payload?.trips?.length)
        state.tripsAtc = {
          ...(state.tripsAtc || {}),
          ...(normalizeTrips(trips, state?.tripsAtc) || {}),
        };
    },
    [createTripInvitation.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: {
            variables: { tripId, userEmail },
          },
        },
      }
    ) => {
      state.tripsAtc = {
        ...state.tripsAtc,
        [tripId]: {
          ...(state.tripsAtc[tripId] || {}),
          invitations: [
            ...(state.tripsAtc[tripId]?.invitations || []),
            {
              id: payload?.createInvitation?.id,
              email: userEmail,
            },
          ],
        },
      };
    },
    [deleteTripInvitation.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { tripId, id },
          },
        },
      }
    ) => {
      state.tripsAtc = {
        ...state.tripsAtc,
        [tripId]: {
          ...(state.tripsAtc[tripId] || {}),
          invitations: state.tripsAtc[tripId]?.invitations?.filter(
            (invitation) => invitation.id !== id
          ),
        },
      };
    },
    [removeUserFromTrip.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { tripId, userId },
          },
        },
      }
    ) => {
      state.tripsAtc = {
        ...state.tripsAtc,
        [tripId]: {
          ...(state.tripsAtc[tripId] || {}),
          users: state.tripsAtc[tripId]?.users?.filter(
            (user) => user.id !== userId
          ),
        },
      };
    },
    [getTripsUsingAtc.rejected]: (state, { payload }) => {
      state.status = 'IDLE';
      state.error = payload;
    },
    [getCompleteTripUsingAtc.pending]: (
      state,
      {
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      state.tripsAtc[tripId] = {
        ...state.tripsAtc[tripId],
        status: 'LOADING',
      };
    },
    [getCompleteTripUsingAtc.fulfilled]: (state, { payload }) => {
      const { trip, mapPins } = payload;
      const tripId = trip?.id;
      state.tripsAtc[tripId] = {
        ...state.tripsAtc[tripId],
        ...trip,
        mapPins,
        status: 'SUCCESS',
      };
    },
    [getCompleteTripUsingAtc.rejected]: (state, { meta: { arg: tripId } }) => {
      if (Object.prototype.hasOwnProperty.call(state.tripsAtc, tripId))
        state.tripsAtc[tripId].status = 'IDLE';
    },
    [createTripUsingAtc.fulfilled]: (
      state,
      {
        payload: {
          trip: { id: tripId },
        },
        meta: {
          arg: { trip, shouldUpdateStore = false },
        },
      }
    ) => {
      if (shouldUpdateStore) {
        state.tripsAtc[tripId] = {
          id: tripId,
          coverImage: trip?.coverImage,
          title: trip?.title,
          startDate: trip?.startDate,
          endDate: trip?.endDate,
          status: 'IDLE',
          items: [],
        };
      }
    },
    [updateTripUsingAtc.fulfilled]: (
      state,
      {
        payload: {
          updateTrip: { version },
        },
        meta: {
          arg: { variables, newAddedUser },
        },
      }
    ) => {
      const trip = {
        ...variables,
        ...(newAddedUser
          ? {
              sharedUsers: [
                ...(variables?.sharedUsers?.filter(
                  (user) => user !== newAddedUser?.id
                ) || []),
                newAddedUser,
              ],
            }
          : {}),
        ...(version && { version }),
      };
      state.tripsAtc[trip?.id] = {
        ...(state.tripsAtc[trip?.id] || {}),
        ...trip,
      };
    },
    [deleteTripUsingAtc.fulfilled]: (state, { meta }) => {
      delete state.tripsAtc[meta.arg];
    },
    [deleteTripUsingAtc.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [createItem.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            localId,
            parentId,
            tripId,
            index,
            shouldAppendItem,
            localItem,
            variables: { ignoreParentUpdate, type },
          },
        },
        payload: {
          createItem: { id },
        },
      }
    ) => {
      const currentChildren = state.tripsAtc[tripId]?.children || [];
      let updatedChildren = currentChildren;
      if (parentId === tripId && !ignoreParentUpdate) {
        // replace local item with itemId
        if (localId && isLocalItem(localId, type)) {
          updatedChildren = currentChildren.map((itemId) =>
            itemId === localId ? id : itemId
          );
        } else {
          updatedChildren = [
            ...currentChildren.slice(0, index),
            id,
            ...currentChildren.slice(index),
          ];
        }

        // Insert local item into the items slice
        if (shouldAppendItem && localItem?.id) {
          updatedChildren = [...updatedChildren, localItem?.id];
        }
      }

      state.tripsAtc[tripId].children = updatedChildren;
    },
    [deleteItem.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: { id, parentId, tripId },
          },
        },
      }
    ) => {
      if (parentId === tripId) {
        state.tripsAtc[tripId] = {
          ...state.tripsAtc[tripId],
          children: state.tripsAtc[tripId]?.children?.filter(
            (itemId) => itemId !== id
          ),
        };
      }
    },

    [moveItem.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: {
              itemId,
              sourceId,
              sourceType,
              targetId,
              targetType,
              targetIndex,
            },
          },
        },
      }
    ) => {
      if (sourceType === DROP_TYPES.TRIP) {
        const currentChildren = state.tripsAtc[sourceId]?.children || [];
        const updatedChildren = currentChildren?.filter(
          (childId) => childId !== itemId
        );
        state.tripsAtc[sourceId] = {
          ...state.tripsAtc[sourceId],
          children: updatedChildren,
        };
      }
      if (targetType === DROP_TYPES.TRIP) {
        const currentChildren = state.tripsAtc[targetId]?.children || [];
        const updatedChildren = [
          ...currentChildren.slice(0, targetIndex),
          itemId,
          ...currentChildren.slice(targetIndex),
        ];
        state.tripsAtc[targetId] = {
          ...state.tripsAtc[targetId],
          children: updatedChildren,
        };
      }
    },

    [moveItem.rejected]: (
      state,
      {
        meta: {
          arg: {
            variables: { sourceId, targetId, sourceType, targetType, itemId },
            sourceIndex = 0,
          },
        },
      }
    ) => {
      if (sourceType === DROP_TYPES.TRIP) {
        const currentChildren = state.tripsAtc[sourceId]?.children || [];
        const updatedChildren = [
          ...currentChildren.slice(0, sourceIndex),
          itemId,
          ...currentChildren.slice(sourceIndex),
        ];
        state.tripsAtc[sourceId] = {
          ...state.tripsAtc[sourceId],
          children: updatedChildren,
        };
      }
      if (targetType === DROP_TYPES.TRIP) {
        const currentChildren = state.tripsAtc[targetId]?.children || [];
        const updatedChildren = currentChildren?.filter(
          (childId) => childId !== itemId
        );
        state.tripsAtc[targetId] = {
          ...state.tripsAtc[targetId],
          children: updatedChildren,
        };
      }
    },
    [updateTripUserRole.fulfilled]: (state, { payload }) => {
      state.tripsAtc[payload.updateTripUserRole.id] = {
        ...state.tripsAtc[payload.updateTripUserRole.id],
        users: payload.updateTripUserRole.users,
      };
    },
    [createAccessRequest.fulfilled]: (state, { meta }) => {
      state.accessRequestedTrips = [
        ...state.accessRequestedTrips,
        meta?.arg?.variables?.tripId,
      ];
    },
    [acceptRequestAndAddToTrip.fulfilled]: (state, { payload, meta }) => {
      state.accessRequests[meta?.arg?.variables?.tripId] = [
        ...state.accessRequests[meta?.arg?.variables?.tripId].filter(
          (request) => request.id !== payload.updateAccessRequestStatus.id
        ),
      ];
      // add the new user to the trip
      state.tripsAtc[meta?.arg?.variables?.tripId] = {
        ...state.tripsAtc[meta?.arg?.variables?.tripId],
        users: [
          ...state.tripsAtc[meta?.arg?.variables?.tripId].users,
          {
            ...payload.updateAccessRequestStatus.user,
            role: TRIP_ACCESS_ROLES.EDITOR,
          },
        ],
      };
    },
    [updateRequestStatus.fulfilled]: (state, { payload }) => {
      state.accessRequests[payload?.updateAccessRequestStatus?.tripId] = [
        ...state.accessRequests[
          payload?.updateAccessRequestStatus?.tripId
        ].filter(
          (request) => request.id !== payload.updateAccessRequestStatus.id
        ),
      ];
    },
    [getPendingAccessRequests.fulfilled]: (state, { payload, meta }) => {
      state.accessRequests[meta?.arg?.variables?.tripId] =
        payload.getPendingAccessRequestsByTripId || [];
    },
    [getPendingAccessRequestByUserIdAndTripId.fulfilled]: (
      state,
      { meta, payload }
    ) => {
      if (payload?.getPendingAccessRequestByUserIdAndTripId?.id) {
        state.accessRequestedTrips = [
          ...state.accessRequestedTrips,
          meta?.arg?.variables?.tripId,
        ];
      }
    },
  },
});

export const TripAtcActions = TripAtcSlice.actions;
export const TripAtcReducer = TripAtcSlice.reducer;
