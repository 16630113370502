import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Collapse,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AddRounded,
  DeleteOutlineRounded,
  DragIndicatorRounded,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { useMapUtils } from '../Map';
import classList from '../../../classList';
import actions from '../../../../redux/actions';
import {
  ONE_DAY_MS,
  parseISODate,
  removeTimezoneOffset,
} from '../../../../utils';
import PlacesImage from '../../molecules/PlacesImage';
import { getStaysSearchConfig } from '../../../organisms/Stays/bookingsUtils';
import InlineBlade from '../../molecules/InlineBlade';
import { deleteItem, updateItem } from '../../../../redux/slices/Item';
import ITEM_TYPES, { COST_TYPES } from '../../../../const';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import useTripAccess from '../../../../utils/use-trip-access';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  root: {
    flex: 1,
    color: '#000000',
    backgroundColor: '#F4FCFD',
    border: '1px solid #DEDDDD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      border: '1px solid #A7A7A7',
      color: '#000000',
      backgroundColor: '#F4FCFD',
      cursor: 'pointer',
    },
  },
  isDragging: {
    border: `2px solid ${palette.primary.light}`,
    boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: '#8A8A8A',
    opacity: '1',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  actionIconDrag: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    padding: '8px 0% 8px 8px',
  },
  description: {
    ...typography.h5,
    marginTop: 4,
    fontSize: 14,
    color: '#8A8A8A',
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: 60,
    objectFit: 'cover',
  },
  link: {
    color: '#A7A7A7',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    textDecorationLine: 'underline',
  },
  linkIcon: {
    color: '#A7A7A7',
    width: '20px',
    padding: '0 0.25em',
  },
  bladeContainer: {
    marginLeft: 12,
    paddingLeft: 'calc(3.6rem - 27px)',
    [breakpoints.up('sm')]: {
      paddingLeft: 'calc(3.6rem + 15px)',
    },
  },
  spacing: {
    marginBottom: 0,
  },
  accomodationDiv: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
      marginRight: 12,
    },
  },
}));

function AccommodationBlock({
  hotelId,
  parentId,
  activeAccommodation,
  setActiveAccommodation,
  dragHandleProps = {},
  setOpenAccomodationPanel,
  isDragging,
  isSectionHighlight,
  isLocationDragging,
  locationBias = null,
  isParentDragging = false,
}) {
  const [isHovered, setHovered] = useState(false);
  const [mobileSingleClick, setMobileSingleClick] = useState(false);

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slug: tripId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { focusPin } = useMapUtils();
  const { canEdit } = useTripAccess();
  // redux data
  const hotel = useSelector((state) => state.items.items[hotelId]);
  const hotelMapPin = useSelector(
    (state) =>
      (hotel?.mapPin && (state.MapV2.mapPins[tripId] || {})[hotel.mapPin]) || {}
  );
  const booking = useSelector((state) =>
    hotel?.content?.isBooking
      ? state.TripsV2.tripsAtc[tripId]?.bookings?.find(
          (b) => b?.id === hotel?.content?.bookingId
        )
      : null
  );
  const tripItems = useSelector((state) => state.Item.items || {});
  const tripItemsArray = Object.values(tripItems);

  const updateAccommodation = (attributes) => {
    const updatedAttributes = { ...attributes };
    try {
      // to not update unupdated attributes
      Object.keys(updatedAttributes).forEach((attr) => {
        if (hotel[attr] === updatedAttributes[attr]) {
          delete updatedAttributes[attr];
        }
      });
      if (
        updatedAttributes?.mapPin &&
        hotel?.mapPin?.id &&
        updatedAttributes?.mapPin !== hotel?.mapPin?.id
      ) {
        updatedAttributes.oldMapPin = hotel?.mapPin?.id;
      }
    } catch (err) {
      return err;
    }
    if (!updatedAttributes || updatedAttributes.length === 0) return null;
    dispatch(
      updateItem({
        variables: { id: hotelId, parentId, tripId, ...updatedAttributes },
        parentId,
        tripId,
      })
    );
    return true;
  };

  const handleDeleteAccomodation = async () => {
    dispatch(
      deleteItem({
        variables: {
          id: hotelId,
          parentId,
          tripId,
          mapPin: hotelMapPin,
        },
        parentId,
        tripId,
      })
    );
  };

  const openHotelBookingsModal = () => {
    const { cityLocationDetails = null, date = null } = getStaysSearchConfig({
      fromDate: hotel?.content?.checkInDate,
      toDate: hotel?.content?.checkOutDate,
      mapPin: hotelMapPin,
      title: hotel?.title,
    });
    dispatch(
      actions.Recommendations.setCity({
        city: {
          coordinates: {
            latitude: cityLocationDetails?.latitude,
            longitude: cityLocationDetails?.longitude,
          },
          title: cityLocationDetails?.title,
          placeId: cityLocationDetails?.placeId,
        },
        tripId,
      })
    );

    if (hotelMapPin?.hotelId) {
      window.localStorage.setItem('prevTripId', tripId);
      const urlParams = new URLSearchParams({
        checkInDate: format(
          date?.from || new Date(Date.now() + 4 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        checkOutDate: format(
          date?.to || new Date(Date.now() + 6 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        rooms: '2|0|1',
      });
      return navigate(`/hotel/${hotelMapPin?.hotelId}?${urlParams.toString()}`);
    }

    dispatch(
      actions.Bookings.triggerSearch({
        tripId,
        cityLocationDetails,
        date,
      })
    );

    return navigate(`/trips/${tripId}/explore?focus=stays`);
  };

  // function to check single or double click on mobile screens
  let wait = false;
  let timer;
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 5000);

    return 'single';
  };

  // formatting data
  const formatDate = (date) => {
    return date ? format(parseISODate(date, true), 'MMM do') : '';
  };

  const description =
    hotel?.content?.checkInDate && hotel?.content?.checkOutDate
      ? `
	  ${formatDate(hotel?.content?.checkInDate, 'iii, MMM d')} - ${formatDate(
          hotel?.content?.checkOutDate,
          'iii, MMM d'
        )}`
      : '';
  const link = hotel?.content?.links ? hotel?.content?.links?.[0] : null;

  useEffect(() => {
    if (activeAccommodation === hotel.id && hotel.mapPin) {
      focusPin(hotel.mapPin);
    }
  }, [activeAccommodation]);

  return (
    <div className={classes.spacing}>
      <Grid container direction="row" onMouseLeave={() => setHovered(false)}>
        {isLocationDragging || isSectionHighlight || isParentDragging ? null : (
          <div className={classes.accomodationDiv}>
            <Grid
              item
              className={`${classes.actionContainer} ${classList.itemActionContainer}`}>
              {isHovered && canEdit && !isMobile ? (
                <>
                  {hotel?.content?.isBooking ? null : (
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={() =>
                        handleDeleteAccomodation(
                          hotel.id,
                          hotel.mapPin,
                          hotel.files
                        )
                      }
                    />
                  )}
                  <AddRounded
                    className={classes.actionIcon}
                    onClick={() => {
                      setOpenAccomodationPanel(true);
                      phTrackEvent({
                        event: EVENTS.PLAN_ADD.STAY_ONHOVER_ADD_ICON_CLICK,
                      });
                    }}
                  />
                </>
              ) : null}
              <span className={classes.actionIconDragSpan} {...dragHandleProps}>
                {isHovered && canEdit && !isMobile ? (
                  <DragIndicatorRounded className={classes.actionIconDrag} />
                ) : null}
              </span>
            </Grid>
          </div>
        )}
        <Collapse
          in={activeAccommodation !== hotel.id}
          timeout={0}
          sx={{ flex: '1 1 0' }}>
          <Grid
            display="flex"
            alignItems="center"
            onMouseLeave={() => {
              setMobileSingleClick(false);
            }}>
            {mobileSingleClick && canEdit && (
              <span
                className={classes.actionIconDragSpan}
                style={{ marginLeft: '0', marginRight: '4px' }}
                {...dragHandleProps}>
                <DragIndicatorRounded className={classes.actionIconDrag} />
              </span>
            )}
            <Grid
              {...dragHandleProps}
              item
              container
              fullWidth
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.root} 
              ${classList.item} ${isDragging ? classes.isDragging : ''} ${
                classList.accommodationBlock
              }`}
              style={{
                marginLeft:
                  isLocationDragging || isSectionHighlight || isParentDragging
                    ? 12
                    : 0,
              }}
              onMouseEnter={() => {
                if (!isMobile) setHovered(true);
                else setMobileSingleClick(true);
              }}
              onClick={() => {
                if (!isMobile) {
                  setHovered(false);
                  setActiveAccommodation(hotel.id);
                  // find activeLoacationId and activeSectionId
                  const activeLocationId = tripItemsArray.find((item) =>
                    item.children?.includes(hotel?.id)
                  )?.id;
                  const activeSectionId = tripItems[
                    activeLocationId
                  ]?.children?.find(
                    (childId) => tripItems[childId]?.type === ITEM_TYPES.HEADING
                  );

                  dispatch(
                    actions.View.setCommandBarV2({
                      activeLocationId,
                      activeSectionId,
                    })
                  );
                } else {
                  const clickType = todoClickedMobile() || 'single';
                  if (clickType === 'single') {
                    setMobileSingleClick(true);
                  } else if (clickType === 'double') {
                    setMobileSingleClick(false);
                    setHovered(false);
                    setActiveAccommodation(hotel.id);
                  }
                }
              }}>
              <Grid item xs={9} className={classes.mainContainer}>
                <Box alignItems="center" flexDirection="column">
                  <Typography variant="h4">{hotel.title}</Typography>
                  <div className={classes.description}>{description}</div>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.imageContainer}>
                <PlacesImage
                  fallbackImage="/images/hotel.svg"
                  mapPin={hotel?.mapPin}
                  className={classes.image}
                  fallbackStyle={{
                    objectFit: 'contain',
                  }}
                />
              </Grid>
            </Grid>
            {mobileSingleClick && canEdit && (
              <DeleteOutlineRounded
                className={classes.actionIcon}
                onClick={() =>
                  handleDeleteAccomodation(hotel.id, hotel.mapPin, hotel.files)
                }
              />
            )}
          </Grid>
        </Collapse>
      </Grid>
      {activeAccommodation === hotel.id && (
        <div
          className={classes.bladeContainer}
          onMouseOver={() => {
            if (isMobile) setHovered(true);
          }}
          onMouseLeave={() => {
            if (isMobile) setHovered(false);
          }}>
          <InlineBlade
            key={activeAccommodation}
            id={hotelId}
            name={hotel?.title}
            hasDate={false}
            hasLink
            hasCost
            hasLocation
            address={
              hotel?.content?.address?.streetAddress ||
              hotel?.content?.address?.city ||
              hotel?.content?.address?.state
            }
            notes={hotel?.content?.description}
            open={activeAccommodation === hotel.id}
            setOpen={(open) => {
              setActiveAccommodation(open);
              if (isMobile) setHovered(open);
            }}
            locationBias={locationBias}
            isAccomodation
            hasCustomCalendar
            linkVal={link}
            mapPinId={hotel?.mapPin}
            updateLocationCallback={(locationAttributes) => {
              const { mapPin = null, ...location } = locationAttributes;
              updateAccommodation({
                content: {
                  address: {
                    ...location,
                  },
                },
                ...(mapPin ? { mapPin: mapPin?.id, mapPinObj: mapPin } : {}),
              });
            }}
            updateLinkCallback={(updatedLink) =>
              updateAccommodation({ content: { links: [updatedLink] } })
            }
            updateNotesCallback={(notes) =>
              updateAccommodation({ content: { description: notes } })
            }
            costVal={{
              amount: hotel?.content?.expense?.amount,
              per: hotel?.content?.expense?.costType === COST_TYPES[0] ? 0 : 1,
              currency: hotel?.content?.expense?.currency,
            }}
            updateCostCallback={({ amount, currency, per }) =>
              updateAccommodation({
                content: {
                  expense: {
                    amount: parseFloat(amount),
                    costType: COST_TYPES[parseInt(per, 10)],
                    currency,
                  },
                },
              })
            }
            updateNameCallback={(name) => updateAccommodation({ title: name })}
            accomodationProps={{
              checkinDate: hotel?.content?.checkInDate,
              checkoutDate: hotel?.content?.checkOutDate,
              isBooking: hotel?.content?.isBooking,
              bookingStatus: booking?.status,
              bookingId: hotel?.content?.bookingId,
              updateCheckinDate: (date) =>
                updateAccommodation({
                  content: {
                    checkInDate: removeTimezoneOffset(date)?.toISOString(),
                  },
                }),
              updateCheckoutDate: (date) =>
                updateAccommodation({
                  content: {
                    checkOutDate: removeTimezoneOffset(date)?.toISOString(),
                  },
                }),
              handleOpenBookingsModal: openHotelBookingsModal,
            }}
            // updateAccommodation={(accommodation) =>
            //   updateAccomodationAttributes(accommodation)
            // }
            fileAttachmentModalProps={{
              attachedToID: hotelId,
              attachedToType: 'Accommodation',
              attachedFiles: hotel?.files,
              attachedToText: hotel?.title,
              attachFunc: (newFiles) => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    updateItem({
                      id: hotelId,
                      variables: {
                        id: hotelId,
                        files: newFiles,
                      },
                      attachedToType: 'Accommodation',
                      tripId,
                    })
                  )
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
                });
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AccommodationBlock;
