import { useDispatch } from 'react-redux';
import { ItineraryActionButton } from '../../../atoms/Button/index';
import actions from '../../../../redux/actions';
import ITEM_TYPES from '../../../../const';
import { getLocalItemId } from '../../../../utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import useTripAccess from '../../../../utils/use-trip-access';

function AddItemButton({
  parentId,
  tripId,
  index,
  itemId,
  type = ITEM_TYPES.ACTIVITY,
}) {
  const dispatch = useDispatch();
  const { isViewOnly } = useTripAccess();
  if (isViewOnly) {
    return null;
  }

  const handleItemClick = () => {
    if (type === ITEM_TYPES.ACCOMMODATION) {
      dispatch(actions.Item.setOpenItemSearchbar({ id: itemId }));
    } else {
      const localItem = {
        id: getLocalItemId(type),
        type,
        title: '',
      };
      dispatch(
        actions.Item.createLocalItem({
          localId: localItem?.id,
          item: localItem,
          tripId,
          parentId,
          index,
          updateNewItemId: true,
        })
      );
      if (parentId === tripId) {
        dispatch(
          actions.TripsV2.addChildToTrip({
            tripId,
            childId: localItem?.id,
            index: index + 1,
          })
        );
      }
    }
    phTrackEvent({
      event:
        type === ITEM_TYPES.ACTIVITY
          ? EVENTS.PLAN_ADD.ACTIVITY_ADD_ICON_CLICK
          : type === ITEM_TYPES.ACCOMMODATION
          ? EVENTS.PLAN_ADD.STAY_ADD_ICON_CLICK
          : EVENTS.PLAN_ADD.HEADING_ADD_ICON_CLICK,
    });
  };

  return (
    <ItineraryActionButton
      disableRipple
      onClick={handleItemClick}
      style={{ marginTop: 4 }}>
      +{' '}
      {type === ITEM_TYPES.HEADING
        ? 'Heading'
        : type === ITEM_TYPES.ACTIVITY
        ? 'Activity'
        : type === ITEM_TYPES.ACCOMMODATION
        ? 'Accommodation'
        : ''}
    </ItineraryActionButton>
  );
}

export default AddItemButton;
