import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useTripAccess = (_tripId) => {
  const tripId = _tripId || useParams().slug;

  if (!tripId) {
    return {
      tripRole: null,
      isViewOnly: true,
      canEdit: true,
      isInTrip: true,
    };
  }

  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);

  const { userId } = useSelector((state) => state.Auth);

  const tripRole =
    trip?.users?.find((user) => user.id === userId)?.role ||
    trip?.privacySettings?.defaultJoinRole;

  return {
    tripRole,
    isViewOnly: tripRole === 'VIEWER',
    canEdit: tripRole === 'OWNER' || tripRole === 'EDITOR',
    isInTrip: trip?.users?.find((user) => user.id === userId),
  };
};

export default useTripAccess;
