import { useCallback, useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Skeleton, Box } from '@mui/material';
import RecommendationCard from '../../molecules/Card/RecommendationCard';
import SortOptionsMenu from './SortOptionsMenu';
import recommendationCategories from '../../../../assets/newRecommendationCategories.json';
import actions from '../../../../redux/actions';
import useTripAccess from '../../../../utils/use-trip-access';

/**
 *
 * @param {Array} recommendations list of recommendations to render
 * @returns A container with recommendation cards
 */

function RecommendationCardContainer({
  recommendations = [],
  title = '',
  loading = false,
  fallbackText = '',
  handleRefetch = () => {},
  handleSortFieldUpdate = () => {},
  infiniteScroll = false,
  scrollRefetching = false,
  isSavedList = false,
  ...rest
}) {
  const dispatch = useDispatch();
  const { slug: tripId } = useParams();
  const { isViewOnly } = useTripAccess();

  const [selectedSortOption, setSelectedSortOption] = useState(0);

  const { recomendationSortFieldOptions: sortFields } =
    recommendationCategories;

  function Loader() {
    return (
      <>
        {[...Array(10).keys()].map((i) => (
          <Grid item xs={12} md={6}>
            <Skeleton
              variant="rounded"
              height={250}
              sx={{ bgcolor: 'system.lightgrey' }}
              key={i}
            />
          </Grid>
        ))}
      </>
    );
  }

  const scrollRef = useRef(null);

  const handleObserver = useCallback((entries) => {
    if (entries?.length > 0 && entries[0].isIntersecting) {
      handleRefetch();
    }
  }, []);

  const handleMenuItemClick = (idx) => {
    if (!Number.isNaN(+idx)) {
      setSelectedSortOption(+idx);
      const sortOption = Object.values(sortFields)[+idx];
      dispatch(
        actions.Recommendations.setSortOption({
          sortOption,
          tripId,
        })
      );
      handleSortFieldUpdate(sortOption);
    }
  };

  useEffect(() => {
    if (!infiniteScroll) return;
    const scrollObserver = new IntersectionObserver(handleObserver);
    if (scrollRef.current) scrollObserver.observe(scrollRef.current);
  }, [handleObserver]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="20px"
        marginBottom="16px">
        <Typography variant="h4" fontWeight={700}>
          {title}
        </Typography>
        {recommendations?.length > 0 ? (
          <div style={{ alignSelf: 'flex-start' }}>
            <SortOptionsMenu
              selectedSortOption={selectedSortOption}
              sortFieldName={Object.keys(sortFields)}
              handleMenuItemClick={handleMenuItemClick}
            />
          </div>
        ) : null}
      </Box>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={2}
        mb={4}
        {...rest}
        sx={{
          display: 'flex',
          justifyContent:
            recommendations?.length === 0 && isSavedList ? 'center' : 'left',
        }}>
        {!loading ? (
          <>
            {recommendations?.length > 0 ? null : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
                }}>
                <Typography
                  variant="h4"
                  color="text.light"
                  align="center"
                  px={2}
                  pt={4}>
                  {fallbackText}
                </Typography>
              </Box>
            )}
            {recommendations.map(
              (place, i) =>
                place && (
                  <Grid item xs={12} md={6}>
                    <RecommendationCard
                      disableAddingToTrip={isViewOnly}
                      disableAddingToSavedList={isViewOnly}
                      key={`recomedation-card-${i}`}
                      index={i}
                      place={place}
                      isSavedList={isSavedList}
                    />
                  </Grid>
                )
            )}
            {infiniteScroll ? (
              scrollRefetching ? (
                <Loader />
              ) : (
                <div ref={scrollRef} />
              )
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </Grid>
    </>
  );
}

export default RecommendationCardContainer;
