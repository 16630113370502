import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createAccessRequest } from '../../../../../redux/slices/TripV2';
import { TRIP_ACCESS_ROLES } from '../../../../../const';
import functions from '../../../../../firebase/functions';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

function RequestAccessButton({ size = 'md' }) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { userId } = useSelector((state) => state.Auth);
  const { firstName, lastName } = useSelector(
    (state) => state.Auth?.userData?.user
  );

  const requestedTrips =
    useSelector((state) => state.TripsV2?.accessRequestedTrips) || [];
  const requested = requestedTrips.includes(slug);
  const requestAccess = async () => {
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.REQUEST_EDIT_ACCESS_CLICK,
    });
    if (requested) return;
    dispatch(
      createAccessRequest({
        variables: { tripId: slug, userId, role: TRIP_ACCESS_ROLES.EDITOR },
      })
    ).then(() => {
      functions.sendTripAccessRequestEmail({
        requestingUserName: `${firstName} ${lastName}`,
        tripId: slug,
        hostBase: window.location.origin,
      });
    });
  };
  return (
    <Button
      onClick={requestAccess}
      disabled={requested}
      sx={{
        backgroundColor: '#fff',
        color: '#4E4E4E',
        borderRadius: '4px',
        border: '1px solid #D9D9D9',
        padding: size === 'md' ? '5px 12px' : '3px 6px',
        textTransform: 'none',
        fontSize: size === 'md' ? '14px' : '11px',
        cursor: requested ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#4E4E4E',
        },
      }}>
      {requested
        ? 'Requested'
        : size === 'md'
        ? 'Request Edit Access'
        : 'Request to Edit'}
    </Button>
  );
}

export default RequestAccessButton;
