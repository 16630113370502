import {
  Grid,
  Typography,
  Fade,
  Snackbar,
  SnackbarContent,
  Stack,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LinkIcon } from '@iconicicons/react';
import { HappyEmoji } from '../../../../atoms/Icon';
import AccessDropdown from './AccessDropdown';
import { TRIP_ACCESS_ROLES } from '../../../../../const';
import { updateTripUsingAtc } from '../../../../../redux/slices/TripV2';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  input: {
    border: '1px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: 14,
  },
  accessDropdownAnchor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    padding: '4px 0px 8px 12px',
    borderRadius: 4,
    alignItems: 'center',
    cursor: 'pointer',
  },

  copyLink: {
    color: '#ED702E',
    cursor: 'pointer',
    padding: '4px 8px',
    borderRadius: 4,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: palette.primary.extraLight,
    },
  },
  link: {
    color: '#8A8A8A',
    fontSize: '0.875rem',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  linkContainer: {
    minWidth: 0,
    flexBasis: '50%',
    display: 'flex',
    flex: 1,
  },
  snackbarRoot: {
    position: 'absolute',
    top: 10,
  },
  snackbarContent: {
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#ED702E',
    minWidth: 'min-content',
    padding: '0.25em 3em',
    borderRadius: 4,
  },
}));

const LinkDetailsCopy = {
  [TRIP_ACCESS_ROLES.EDITOR]: {
    title: 'Anyone with this link can edit',
    description: 'Anyone with this link can edit the trip',
  },
  [TRIP_ACCESS_ROLES.VIEWER]: {
    title: 'Anyone with this link can view',
    description: 'Anyone with this link can view the trip',
  },
  Off: {
    title: 'Link sharing is off',
    description: 'Only those invited via email will have access via link',
  },
};

function LinkInvitation({ onClose, snackbarText, handleCopyLink, tripId }) {
  const classes = useStyles();
  const tripPrivacySettings = useSelector(
    (state) => state.TripsV2.tripsAtc[tripId]?.privacySettings
  );

  const defaultJoinRole =
    tripPrivacySettings?.defaultJoinRole || TRIP_ACCESS_ROLES.EDITOR;

  const copyKey = tripPrivacySettings?.linkSharingEnabled
    ? defaultJoinRole
    : 'Off';

  const linkDetails = LinkDetailsCopy[copyKey];
  const joinLink = `${window.location.origin}/join/${tripId}`;
  const viewLink = `${window.location.origin}/trips/${tripId}/planner`;

  const dispatch = useDispatch();

  const upateTripRole = (role) => {
    dispatch(
      updateTripUsingAtc({
        variables: {
          id: tripId,
          privacySettings:
            role === 'Off'
              ? {
                  linkSharingEnabled: false,
                  defaultJoinRole,
                }
              : {
                  linkSharingEnabled: true,
                  defaultJoinRole: role,
                },
        },
      })
    );
    if (role === 'Off') {
      phTrackEvent({
        event: EVENTS.TRIP_INVITE.LINK_SHARING_OFF_UPDATE,
      });
    } else {
      phTrackEvent({
        event:
          role === TRIP_ACCESS_ROLES.EDITOR
            ? EVENTS.TRIP_INVITE.TRIP_SHARE_EDITOR_SUBMIT
            : EVENTS.TRIP_INVITE.TRIP_SHARE_VIEWER_SUBMIT,
      });
    }
  };

  const copy = () => {
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.LINK_BUTTON_CLICK,
    });
    handleCopyLink(
      defaultJoinRole === TRIP_ACCESS_ROLES.EDITOR ? joinLink : viewLink
    );
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={Fade}>
          <SnackbarContent
            className={classes.snackbarContent}
            message={snackbarText}
          />
        </Snackbar>
      </div>
      <Grid
        container
        direction="column"
        style={{ marginTop: 24, flex: 1, padding: '0px 24px' }}>
        <Grid item>
          <Typography
            style={{
              color: '#222',
              fontWeight: 600,
            }}>
            Invite via link
          </Typography>
        </Grid>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 8,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 12,
            }}>
            <div
              style={{
                backgroundColor: '#F4F4F4',
                borderRadius: '50%',
                padding: 4,
                height: '28px',
              }}>
              <HappyEmoji
                sx={{
                  height: 20,
                  width: 20,
                  color: '#222222',
                }}
              />
            </div>
            <Stack direction="column" spacing={0}>
              <Typography fontSize={14} style={{ flex: 1, cursor: 'default' }}>
                {linkDetails?.title}
              </Typography>
              <Typography color="#8A8A8A" fontSize="12px">
                {linkDetails?.description}
              </Typography>
            </Stack>
          </div>

          <AccessDropdown
            currentRole={
              tripPrivacySettings?.linkSharingEnabled ? defaultJoinRole : 'Off'
            }
            showRestrictedOption
            controlled
            onRoleChange={upateTripRole}
          />
        </div>
        <Grid
          container
          style={{
            marginTop: 12,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '5px',
          }}>
          <Button
            onClick={copy}
            disabled={!tripPrivacySettings?.linkSharingEnabled}
            startIcon={<LinkIcon color="#141414" />}
            style={{
              marginRight: 12,
              backgroundColor: '#fff',
              border: '1px solid #DEDDDD',
              borderRadius: 4,
              color: '#4E4E4E',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#4E4E4E',
              },
            }}>
            Copy link
          </Button>
          <Button
            style={{
              zIndex: 1,
            }}
            onClick={onClose}>
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default LinkInvitation;
