import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  AttachFile,
  Close,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Typography,
  Popper,
  ClickAwayListener,
  Autocomplete,
  Checkbox,
  InputBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { attachFiletoItems } from '../../../../redux/slices/FilesV2';
import { ButtonDefault } from '../../../atoms/Button/index';
import ITEM_TYPES from '../../../../const';
import { updateItem } from '../../../../redux/slices/Item';
import { updateTripUsingAtc } from '../../../../redux/slices/TripV2';
import actions from '../../../../redux/actions';
import useTripAccess from '../../../../utils/use-trip-access';

const attachmentPopperStyles = makeStyles((theme) => ({
  attachPopper: {
    borderRadius: 4,
    width: '280px',
    backgroundColor: '#FFF',
    zIndex: 1100,
  },
  attachmentOption: {
    padding: '2px 0',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
  AutoCompleteListbox: {
    background: '#FFFFFF',
    boxShadow: 'none',
    padding: 0,
  },
  searchInput: {
    boxShadow: '0 0 0 1px #7E7E7E',
    padding: '4px 14px',
    background: '#F4F4F4',
    borderRadius: 4,
    width: '100%',
  },
  AutoCompleteListboxPopper: {
    padding: 14,
    boxShadow: '0 0 0 1px #7E7E7E',
    borderRadius: 4,
    // width: '100%',
    // minWidth: '200px',
  },
  sectionLabel: {
    fontSize: '12px',
    color: '#4E4E4E',
    fontWeight: 'bold',
    borderBottom: '1px solid #DEDDDD',
    paddingBottom: '8px',
    padding: '8px 0',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  sectionUl: {
    marginBottom: '8px',
  },
  attachItemsButton: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  chip: {
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 12,
    padding: '13px 4px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
    },
  },
  attachButton: {
    marginTop: 8,
  },
  emptyAttachmentText: {
    color: 'transparent',
    '&:hover': {
      color: '#BCBCBC',
    },
    padding: '8px 4px',
    height: '100%',
    width: '100%',
    display: (props) => (props.canEdit ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

function ItemAttachment({ fileId, tripId, tripFiles }) {
  const { canEdit } = useTripAccess();
  const classes = attachmentPopperStyles({ canEdit });
  const [anchorElm, setAnchorEl] = useState(null);
  const [openPopper, setOpen] = useState(false);
  const id = openPopper ? 'attachment-popper' : undefined;
  const dispatch = useDispatch();
  const tripChildrenIds = useSelector(
    (state) => state.TripsV2.tripsAtc[tripId]?.children || []
  );
  const items = useSelector((state) => state.Item.items || {});

  const parsedTripItemIds = useMemo(() => {
    // run a DFS to parse thru all child items
    const processChildIds = (childId) => {
      if (items[childId]?.type === ITEM_TYPES.TRANSPORTATION) {
        return items[childId]?.content?.connections;
      }
      const children = items[childId]?.children || [];
      return children.map((child) => [child, ...processChildIds(child)]).flat();
    };
    return tripChildrenIds
      .map((childId) => [childId, ...processChildIds(childId)])
      .flat();
  }, [items, tripChildrenIds]);

  const tripItems = useSelector((state) =>
    Object.values(state.Item.items || {})
      .filter(
        (item) =>
          item?.tripId === tripId &&
          item?.type !== ITEM_TYPES.DESTINATION &&
          item?.type !== ITEM_TYPES.HEADING &&
          item?.type !== ITEM_TYPES.TRANSPORTATION
      )
      ?.reduce((acc, item) => {
        acc[item?.id] = item;
        return acc;
      }, {})
  );
  const fileRelations = useSelector(
    (state) => state.FilesV2.fileRelations[tripId]
  );

  const getName = (type, elemId) => {
    if (elemId === tripId) {
      return '';
    }
    if (type === ITEM_TYPES.TRANSPORT) {
      return tripItems[elemId]?.content?.flightNumber;
    }
    return tripItems[elemId]?.title;
  };

  const attachedItems = fileRelations
    ?.filter(
      (relation) =>
        relation.fileId === fileId && relation.attachedToType !== 'Trip'
    )
    .map((relation) => {
      return {
        id: relation.attachedToId,
        title: getName(relation.attachedToType, relation.attachedToId),
        type: relation.attachedToType,
      };
    });

  const tripItemsList = useMemo(() => {
    return parsedTripItemIds
      ?.filter(
        (itemId) =>
          ![
            ITEM_TYPES.DESTINATION,
            ITEM_TYPES.HEADING,
            ITEM_TYPES.TRANSPORTATION,
          ].includes(items[itemId]?.type)
      )
      ?.map((itemId) => {
        const item = tripItems[itemId];
        return {
          id: itemId,
          title: getName(item?.type, itemId),
          type: item?.type,
        };
      });
  }, [parsedTripItemIds]);

  const [val, setValue] = useState([...attachedItems]);

  const arrCompare = (arr1, arr2) => {
    let equal = false;
    if (arr1.length === arr2.length) {
      equal = true;
      arr1.forEach((element) => {
        if (!arr2.includes(element)) equal = false;
      });
    }
    return equal;
  };

  const itemsArr = val?.map((item) => item.id);
  const attachedItemsArr = attachedItems?.map((item) => item.id);
  const handleClick = (event) => {
    if (canEdit) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (anchorElm) {
      anchorElm.focus();
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const handleAttach = async () => {
    if (!arrCompare(itemsArr, attachedItemsArr)) {
      const attachmentItems = val
        ?.filter((item) => !attachedItemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });
      const detachedItems = attachedItems
        ?.filter((item) => !itemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });

      const toBeAdded = !fileRelations?.some(
        (relation) =>
          relation.fileId === fileId &&
          !detachedItems?.map((item) => item.id).includes(relation.attachedToId)
      );

      await new Promise(() => {
        dispatch(
          attachFiletoItems({
            variables: {
              id: fileId,
              tripId,
              removeFromItems: detachedItems?.map((item) => item.id),
              addToItems: attachmentItems?.map((item) => item.id),
            },
            addToItems: attachmentItems,
            removeFromItems: detachedItems,
            addToTrip: toBeAdded,
          })
        ).then(() => {
          dispatch(
            actions.Item.updateFileAttachments({
              id: fileId,
              removeFromItems: detachedItems?.map((item) => item.id),
              addToItems: attachmentItems?.map((item) => item.id),
            })
          );
          handleClose();
        });
      });
    }
  };

  const handleDelete = async (itemId, type) => {
    await new Promise(() => {
      dispatch(
        updateItem({
          variables: {
            id: itemId,
            files: tripItems[itemId]?.files?.filter((file) => file !== fileId),
          },
          attachedToType: type,
          tripId,
        })
      ).then(() => {
        setValue((prevVal) => prevVal.filter((item) => item.id !== itemId));
        if (
          attachedItemsArr?.length === 0 &&
          fileRelations?.filter((relation) => relation.attachedToId === itemId)
            .length === 0
        ) {
          dispatch(
            updateTripUsingAtc({
              variables: {
                id: tripId,
                files: [...(tripFiles || []), fileId],
              },
            })
          );
        }
      });
    });
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return (
      <div {...other} className={classes.AutoCompleteListboxPopper}>
        {props.children}
        {arrCompare(itemsArr, attachedItemsArr) ? (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            disabled>
            Attach file
          </ButtonDefault>
        ) : (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            onClick={() => handleAttach()}>
            Attach file
          </ButtonDefault>
        )}
      </div>
    );
  }

  return (
    <>
      <Box onClick={handleClick} className={classes.attachItemsButton}>
        {attachedItems.length > 0 ? (
          attachedItems.map((item) => {
            return (
              <Chip
                key={item.id}
                size="small"
                label={item.title}
                className={classes.chip}
                variant="outlined"
                onDelete={
                  canEdit ? () => handleDelete(item.id, item.type) : undefined
                }
                deleteIcon={canEdit ? <Close /> : null}
              />
            );
          })
        ) : (
          <Typography className={classes.emptyAttachmentText}>
            Click to search activity
          </Typography>
        )}
      </Box>
      <Popper
        id={id}
        open={openPopper}
        anchorEl={anchorElm}
        className={classes.attachPopper}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -39],
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              disableCloseOnSelect
              value={val}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  event.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setValue(newValue);
              }}
              PopperComponent={PopperComponent}
              options={tripItemsList}
              getOptionLabel={(option) => option.title}
              noOptionsText="No items to attach"
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  className={classes.attachmentOption}
                  key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              groupBy={(option) => option.type}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search activities..."
                  className={classes.searchInput}
                  sx={{ width: `${anchorElm?.offsetWidth}px` }}
                />
              )}
              ListboxProps={{
                sx: { padding: 0 },
              }}
              classes={{
                paper: classes.AutoCompleteListbox,
                groupLabel: classes.sectionLabel,
                groupUl: classes.sectionUl,
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ItemAttachment;
