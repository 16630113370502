import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BlockHeader from '../BlockHeader';
import { createItem } from '../../../../redux/slices/Item';
import ITEM_TYPES from '../../../../const';
import EmptyActivity from '../Activity/EmptyActivity';
import actions from '../../../../redux/actions';
import { removeTimezoneOffset } from '../../../../utils';
import { stepName } from '../../../../assets/onboarding/steps';
import useTour from '../../../molecules/Tour/useTour';
import useTripAccess from '../../../../utils/use-trip-access';

function EmptyHeading({ parentId }) {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { getTour } = useTour();
  const parentItem = useSelector((state) => state.Item.items[parentId]);
  const { slug: tripId } = useParams();

  const handleCreateHeading = async (title, additionalProps = {}) => {
    if (title === '' && Object.keys(additionalProps).length === 0) return;
    const res = await dispatch(
      createItem({
        variables: {
          title,
          type: ITEM_TYPES.HEADING,
          parentId,
          tripId,
          index: parentItem?.children?.length || 0,
          ...additionalProps,
        },
        tripId,
        parentId,
        index: parentItem?.children?.length || 0,
      })
    );
    dispatch(
      actions.View.setCommandBarV2({
        activeLocationId: parentId,
        activeSectionId: res?.payload?.createItem?.id,
      })
    );
    getTour().onActiveStep(stepName.MAP_SEARCH_BAR).openTour();
    getTour()
      .onActiveTour(['emptyTripFlowMobile', 'blankTripFlowMobile'])
      .onActiveStep(stepName.ADD_TODO)
      .closeTour()
      .openTour({
        delay: 3500,
      });
  };
  const { canEdit } = useTripAccess();

  return (
    <div
      onClick={() => {
        getTour()
          .onActiveStep(stepName.SET_HEADING, () => {
            inputRef?.current?.focus();
          })
          .closeTour()
          .goToNextStep();
      }}>
      <BlockHeader
        inputRef={inputRef}
        updateDate={(e) => {
          handleCreateHeading('', {
            content: {
              startDate: e.from && removeTimezoneOffset(e.from).toISOString(),
              endDate: e.to && removeTimezoneOffset(e.to).toISOString(),
            },
          });
        }}
        updateTitle={(title) => handleCreateHeading(title)}
        defaultTitle=""
        hideActions
        inputProps={{
          placeholder: 'New Heading',
          inputProps: { style: { padding: 2 } },
          name: 'placeholder',
        }}
        iconDatePickerProps={{
          useRange: true,
          defaultDate: {
            from: null,
            to: null,
          },
          placement: 'bottom-end',
        }}
        isSectionHighlight={false}
        handleDelete={() => {}}
        handleAdd={() => {}}
      />
      <div style={{ marginTop: 8 }} />
      {canEdit && <EmptyActivity shouldCreateParent parentId={parentId} />}
    </div>
  );
}

export default EmptyHeading;
