import { httpsCallable } from 'firebase/functions';
import { functions } from '../FirebaseIndex';
import functionNames from './functionNames';

const sendVerificationEmail = async ({ email, actionCodeSettings }) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendVerificationLinkEmail
    )({
      email,
      actionCodeSettings,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};
const sendLoginLinkEmail = async ({ email, actionCodeSettings }) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendLoginLinkEmail
    )({
      email,
      actionCodeSettings,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};

const sendPasswordResetEmail = async ({ email, actionCodeSettings }) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendPasswordResetEmail
    )({
      email,
      actionCodeSettings,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};

const sendTripAccessRequestEmail = async ({
  requestingUserName,
  tripId,
  hostBase = window.location.origin,
}) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendTripAccessRequestEmail
    )({
      requestingUserName,
      tripId,
      hostBase,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};

const sendTripAccessGrantedEmail = async ({
  requestedUserFirebaseId,
  tripId,
  hostBase = window.location.origin,
}) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendTripAccessGrantedEmail
    )({
      requestedUserFirebaseId,
      tripId,
      hostBase,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};

export default {
  sendVerificationEmail,
  sendLoginLinkEmail,
  sendPasswordResetEmail,
  sendTripAccessRequestEmail,
  sendTripAccessGrantedEmail,
};
