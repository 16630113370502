import { Button, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { PersonAdd } from '@mui/icons-material';
import classList from '../../../classList';
import { UnreadForwardedFilesNotificationDot } from '../../Icon';

const useStyles = makeStyles((theme) => ({
  inviteButton: {
    maxWidth: 159,
    minHeight: '2rem',
    padding: '6px 12px',
    lineHeight: '18px',
    marginLeft: ({ showNotificationDot }) =>
      showNotificationDot ? '10px' : '0px',
    [theme.breakpoints.down('sm')]: {
      '&.isSmallScreen': {
        minWidth: 46,
        padding: 8,
        '& .MuiButton-startIcon': {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
  },
}));

function InviteButton({ handleClick, showNotificationDot = false }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery('(max-width: 410px)');

  const classes = useStyles({ isSmallScreen, showNotificationDot });
  return (
    <div style={{ display: 'relative' }}>
      <Button
        id="invite-friends-button-web"
        onClick={handleClick}
        className={`${classes.inviteButton} ${classList.inviteButton} ${
          isSmallScreen ? 'isSmallScreen' : ''
        }`}
        startIcon={
          <PersonAdd
            style={{
              height: 24,
              width: 24,
            }}
          />
        }>
        {showNotificationDot && (
          <UnreadForwardedFilesNotificationDot
            style={{
              position: 'absolute',
              top: '15px',
              left: '-15px',
              height: 8,
              width: 8,
            }}
          />
        )}
        {isMobile ? (isSmallScreen ? null : 'Invite') : 'Invite Friends'}
      </Button>
    </div>
  );
}

export default InviteButton;
