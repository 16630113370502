import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Rating,
  Typography,
} from '@mui/material';
import { getRatingPropsV2 } from '../Stays/HotelDetails/utils';
import { getNumberOfNights } from '../Stays/bookingsUtils';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    borderRadius: '8px',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'none',
    backgroundColor: '#fff',
    padding: '22px',
    marginBottom: '20px',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '16px',
  },
  amenitiesText: {
    fontSize: '16px',
    color: '#80746C',
    marginTop: '8px',
  },
  confirmationText: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 95px',
    [breakpoints.down('lg')]: {
      padding: '40px 40px',
    },
    [breakpoints.down('sm')]: {
      padding: '40px 40px',
    },
  },
}));

function BookingSuccessPage({
  name,
  roomImage,
  address,
  rating,
  reviewsCount,
  roomName,
  checkInDate,
  checkOutDate,
  cityName,
  tripId,
  hotelDetails,
}) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [ratingSentiment, ratingColor, ratingTextColor] =
    reviewsCount > 25 ? getRatingPropsV2(rating) : [null, null, null];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const numberOfNights = getNumberOfNights({
    from: checkInDate,
    to: checkOutDate,
  });

  const handleClick = () => {
    if (tripId) {
      navigate(`/trips/${tripId}/planner?openBooking=latest_booking`);
    }
  };

  return (
    <Grid container sx={{ height: 'calc(100vh - 93px)', overflow: 'hidden' }}>
      <Grid item xs={12} md={6} className={classes.confirmationText}>
        <Box>
          <Box display="flex" alignItems="center" gap={1}>
            <DoneIcon sx={{ color: '#89C44A' }} />
            <Typography sx={{ fontSize: '28px' }}>It's official!</Typography>
          </Box>
          <Box sx={{ marginLeft: '30px', marginTop: '22px' }}>
            <Typography sx={{ fontSize: '22px' }}>
              Your reservation is confirmed
            </Typography>

            <Typography
              sx={{ fontSize: '16px', marginTop: '22px', fontWeight: 500 }}>
              You're going to {cityName}!
            </Typography>

            <Typography
              sx={{ fontSize: '16px', marginTop: '12px', fontWeight: 200 }}>
              We've added all of your booking details to your Pilot account so
              you can plan & manage the rest of your trip.
            </Typography>

            <Button
              sx={{
                marginTop: '24px',
                boxShadow: 'none',
                marginRight: '16px',
                color: '#000',
                backgroundColor: '#fff',
                border: '1px solid #E3DAD5',
                '&:hover': {
                  backgroundColor: '#f4f4f4',
                  boxShadow: 'none',
                  color: '#000',
                },
                '&:active': {
                  backgroundColor: '#D9D9D9',
                  boxShadow: 'none',
                  color: '#000',
                },
              }}
              onClick={() => {
                window.open(
                  'https://www.pilotplans.com/',
                  '_blank',
                  'noopener,noreferrer'
                );
              }}>
              Explore Pilot
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: '24px', boxShadow: 'none' }}
              onClick={handleClick}>
              See Itinerary
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: 'relative',
          backgroundImage: `url(${
            hotelDetails?.photos && hotelDetails?.photos?.length > 0
              ? hotelDetails?.photos?.[0]
              : roomImage
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box className={classes.root} sx={{ width: '70%', boxShadow: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                variant="rounded"
                src={roomImage}
                sx={{ width: 113, height: 125 }}
              />
            </Grid>
            <Grid item xs>
              {hotelDetails?.content?.starRating && (
                <Rating
                  value={hotelDetails?.content?.starRating}
                  readOnly
                  precision={0.5}
                  size="small"
                  sx={{
                    mt: 0.35,
                    mb: 0.45,
                  }}
                  max={hotelDetails?.content?.starRating || 0}
                />
              )}
              <Typography variant="h6" fontWeight="bold">
                {name}
              </Typography>
              <Typography color="text.secondary">{`${address}, ${cityName}`}</Typography>
              <Box mt={1} display="flex" alignItems="center">
                <Chip
                  label={rating}
                  sx={{
                    height: '38px',
                    lineHeight: '38px',
                    borderRadius: '4px',
                    backgroundColor: ratingColor,
                    color: ratingTextColor,
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
                <Box display="flex" alignItems="center" ml={1}>
                  <Typography variant="h5" fontWeight={450}>
                    {ratingSentiment}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    ml={1}
                    mt={0.25}>
                    ({reviewsCount}) <span>Reviews</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Typography fontWeight="bold">Dates</Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
              position="relative">
              <Typography>{formatDate(checkInDate)}</Typography>
              <Box
                sx={{
                  flex: 1,
                  borderBottom: '1px dashed rgba(0, 0, 0, 0.4)',
                  mx: 2,
                  position: 'relative',
                }}
              />
              <Typography>{formatDate(checkOutDate)}</Typography>
            </Box>
            <Typography
              sx={{
                textAlign: 'center',
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '14px',
                mt: '5px',
              }}>
              {`${numberOfNights} night`}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box display="flex" justifyContent="space-between" mt={1}>
            <Typography>{roomName}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default BookingSuccessPage;
