import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { AddRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ClickAwayListener, CircularProgress, Fade } from '@mui/material';
import TravelRestrictions from '../../../organisms/TravelRestrictions';
import { AddConnectingTransportButton } from '../../../atoms/Button';
import ConnectionBlock from './ConnectionBlock';
import classList from '../../../classList';
import { createItem, deleteItem } from '../../../../redux/slices/Item';
import ITEM_TYPES, { TRANSPORT_TYPES } from '../../../../const';
import { getLocalItemId, isLocalItem } from '../../../../utils';
import { updateFile } from '../../../../redux/slices/FilesV2';
import useTripAccess from '../../../../utils/use-trip-access';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    marginLeft: 'calc(3.6rem - 44px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 19px - 28px)',
    },
  },
}));

const getEmptyFlightProps = () => ({
  title: '',
  content: {
    description: '',
    links: [],
    expense: {
      amount: null,
      currency: null,
      costType: null,
    },
    fromAirport: '',
    toAirport: '',
    startDate: null,
    endDate: null,
  },
});

function ConnectionList({
  open,
  setOpen,
  newFlight,
  tripId,
  defaultFlightProps = {},
  handleDeleteTransportation = () => {},
  id,
  index = 0,
  transportType = TRANSPORT_TYPES.FLIGHT,
  parentId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [connectingFlightLoading, setConnectingFlightLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const [clickedAway, setClickedAway] = useState(false);
  const [flightList, setFlightList] = useState(
    defaultFlightProps?.content?.connections || [
      {
        ...getEmptyFlightProps(),
      },
    ]
  );
  const [newFlightIds, setNewFlightIds] = useState(['new-flight']);
  const { canEdit } = useTripAccess();
  useEffect(() => {
    if (newFlight) {
      // Indicates a new Transportation Block
      divRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      divRef.current?.focus();
    }
  }, []);

  const handleCreateConnection = async (
    flightInfo = {},
    additionalProps = {}
  ) =>
    dispatch(
      createItem({
        variables: {
          tripId,
          parentId: id,
          index: index + 1,
          type: ITEM_TYPES.TRANSPORT,
          ignoreParentUpdate: true,
          ...flightInfo,
        },
        tripId,
        parentId: id,
        index: index + 1,
        ...additionalProps,
      })
    );

  const handleCreateTransportation = async () => {
    try {
      setLoading(true);

      // create flights for each stop created
      const connectionIds = await Promise.all(
        flightList.map(async (flight) => {
          if (isLocalItem(flight?.id, ITEM_TYPES.TRANSPORT) || !flight?.id) {
            const newCreatedFlight = await handleCreateConnection(flight, {
              localId: flight?.id,
              transportType,
            });
            if (flight?.files) {
              flight?.files?.forEach((file) =>
                dispatch(
                  updateFile({
                    variables: {
                      id: file,
                      attachedToType: 'flight',
                      attachedToID: newCreatedFlight?.payload?.createFlight?.id,
                    },
                  })
                )
              );
            }
            return newCreatedFlight?.payload?.createItem?.id;
          }
          return flight?.id;
        })
      );
      await new Promise((resolve, reject) => {
        dispatch(
          createItem({
            variables: {
              tripId,
              parentId,
              index: index + 1,
              type: ITEM_TYPES.TRANSPORTATION,
              content: {
                transportType,
                connections: connectionIds,
              },
            },
            localId: id,
            parentId,
            tripId,
            index: index + 1,
          })
        )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    } catch (err) {
      return err;
    }
    setLoading(false);
    return null;
  };

  const handleUpdateTransportationBlade = (data, flightIndex) => {
    try {
      setFlightList([
        ...flightList.slice(0, flightIndex),
        {
          ...flightList[flightIndex],
          ...data,
          content: {
            ...(flightList[flightIndex]?.content || {}),
            ...(data?.content || {}),
          },
        },
        ...flightList.slice(flightIndex + 1),
      ]);
    } catch (err) {
      // Handle error
    }
  };

  const deleteConnectingFlight = async (flightIndex) => {
    const flightToBeDeleted = flightList[flightIndex];
    const updatedFlightList = [
      ...(flightList?.slice(0, flightIndex) || []),
      ...(flightList?.slice(flightIndex + 1) || []),
    ];

    if (updatedFlightList.length === 0) {
      handleDeleteTransportation();
      return;
    }
    if (!newFlight) {
      await dispatch(
        deleteItem({
          variables: {
            id: flightToBeDeleted.id,
            parentId: id,
            type: ITEM_TYPES.TRANSPORT,
            tripId,
          },
        })
      );
    }
    setFlightList(updatedFlightList);
  };

  const addConnectingFlight = async (e) => {
    e.stopPropagation();
    setConnectingFlightLoading(true);
    try {
      let newFlightId = getLocalItemId(ITEM_TYPES.TRANSPORT);
      if (!newFlight) {
        const newCreatedConnection = await handleCreateConnection({
          ignoreParentUpdate: false,
          transportType,
        });
        newFlightId = newCreatedConnection?.payload?.createItem?.id;
      }
      const newFlightList = [
        ...flightList,
        {
          id: newFlightId,
          ...getEmptyFlightProps(),
        },
      ];
      setFlightList(newFlightList);
      setNewFlightIds([...newFlightIds, newFlightId]);
    } catch (err) {
      return err;
    }
    setConnectingFlightLoading(false);
    return null;
  };

  const handleClickOutside = async (e) => {
    if (e?.target?.id === 'bookings-modal') return;
    setNewFlightIds([]);
    if (!clickedAway) {
      if (newFlight) {
        setClickedAway(true);
        await handleCreateTransportation();
      }
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
        setOpen(false);
      }, 300);
    }
  };

  return (
    <div
      ref={divRef}
      // style={{
      //   marginLeft: 'calc(3.6rem + 19px - 28px)',
      // }}

      className={`${classList.item} ${classes.root}`}>
      <ClickAwayListener onClickAway={handleClickOutside}>
        <Fade in={open && !transition} timeout={300}>
          <div>
            {transportType === TRANSPORT_TYPES.FLIGHT && (
              <div style={{ display: 'flex', marginLeft: 28 }}>
                <TravelRestrictions flightList={flightList} />
                <div style={{ flexGrow: 1 }} onClick={handleClickOutside} />
              </div>
            )}
            {flightList?.map((flight, idx) => (
              <ConnectionBlock
                key={flight.id}
                id={flight.id}
                transportId={id}
                open={open}
                setOpen={setOpen}
                newFlight={newFlight}
                defaultFlightProps={flight}
                transportType={transportType}
                index={idx}
                handleUpdateTransportationBlade={
                  handleUpdateTransportationBlade
                }
                handleDeleteConnectingFlight={() => deleteConnectingFlight(idx)}
                loading={idx === 0 ? loading : false}
                canShowDeleteIcon={flightList?.length > 1}
                handleClickOutside={handleClickOutside}
                newFlightIds={newFlightIds}
                setNewFlightIds={setNewFlightIds}
              />
            ))}
            {/* TODO: weigh out the cons vs pros of using mousedown vs onClick  */}
            {canEdit && (
              <AddConnectingTransportButton
                onMouseDown={addConnectingFlight}
                style={{ marginLeft: 28, width: 'calc(100% - 28px)' }}
                disableFocusRipple
                disableRipple
                disabled={connectingFlightLoading}
                endIcon={
                  connectingFlightLoading ? (
                    <CircularProgress style={{ height: 16, width: 16 }} />
                  ) : null
                }
                startIcon={<AddRounded style={{ color: '#8A8A8A' }} />}>
                Add connecting {transportType.toLowerCase()}
              </AddConnectingTransportButton>
            )}
          </div>
        </Fade>
      </ClickAwayListener>
    </div>
  );
}

export default ConnectionList;
