import { Button, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { acceptRequestAndAddToTrip } from '../../../../../redux/slices/TripV2';
import functions from '../../../../../firebase/functions';
import useTripAccess from '../../../../../utils/use-trip-access';
import { TRIP_ACCESS_ROLES } from '../../../../../const';

function AccessRequest({
  userFirstName,
  requestId,
  userId,
  userFirebaseUid,
  userEmail,
}) {
  const dispatch = useDispatch();
  const tripId = useParams().slug;

  const updateRequestStatus = (status) => {
    dispatch(
      acceptRequestAndAddToTrip({
        variables: {
          tripId,
          userId,
          firebaseUid: userFirebaseUid,
          updateAccessRequestStatusId: requestId,
          status,
        },
      })
    );
    functions.sendTripAccessGrantedEmail({
      requestedUserFirebaseId: userFirebaseUid,
      tripId,
      hostBase: window.location.origin,
    });
  };

  const approveRequest = () => {
    updateRequestStatus('Accepted');
  };

  const denyRequest = () => {
    updateRequestStatus('Denied');
  };

  return (
    <Grid item xs={12} key={requestId}>
      <Grid container style={{ alignItems: 'center', marginTop: 12 }}>
        <div
          className="access-request-container"
          style={{
            padding: '8px',
            backgroundColor: '#FFF3ED',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '4px',
            width: '100%',
          }}>
          <Typography fontSize="16px">
            {userFirstName} ({userEmail})
            <Typography fontSize="12px">
              has requested to edit the trip
            </Typography>
          </Typography>

          <div
            className="action-buttons"
            style={{
              display: 'flex',
              gap: '8px',
            }}>
            <Button
              onClick={denyRequest}
              sx={{
                height: '28px',
                color: '#4E4E4E',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#4E4E4E',
                  textDecoration: 'underline',
                },
              }}>
              Deny
            </Button>
            <Button
              onClick={approveRequest}
              sx={{
                height: '28px',
              }}
              variant="contained"
              color="primary">
              Approve
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
function AccessRequests() {
  const { tripRole } = useTripAccess();
  // show access requests only if the user is the owner of the trip
  if (tripRole !== TRIP_ACCESS_ROLES.OWNER) return null;

  const tripId = useParams().slug;
  const accessRequests = useSelector(
    (state) => state.TripsV2?.accessRequests[tripId]
  );
  return (
    <div style={{ width: '100%' }}>
      {accessRequests?.map((request) => (
        <AccessRequest
          key={request?.id}
          userId={request?.user?.id}
          userFirebaseUid={request?.user?.firebaseUid}
          userFirstName={request?.user?.firstName}
          requestId={request?.id}
          userEmail={request?.user?.email}
        />
      ))}
    </div>
  );
}

export default AccessRequests;
