import { useState } from 'react';
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from '@mui/material';
import { ChevronDownIcon } from '@iconicicons/react';
import { makeStyles } from '@mui/styles';
import UserAction from './UserAction';
import { TRIP_ACCESS_ROLES_FOR_NON_OWNER } from '../../../../../const';
import { capitalize } from '../../../../../utils';

const useStyles = makeStyles(() => ({
  accessDropdownAnchor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    padding: '4px 0px 8px 12px',
    borderRadius: 4,
    alignItems: 'center',
    cursor: ({ hideDropdown }) => (hideDropdown ? 'default' : 'pointer'),
  },
}));

function AccessDropdown({
  removeIndicator = false,
  currentRole,
  showRestrictedOption = false,
  onRoleChange,
  controlled = false,
  hideDropdown = false,
  ...props
}) {
  const [selectedRole, setSelectedRole] = useState(currentRole || 'Editor');
  const classes = useStyles({ hideDropdown });
  const [anchorEl, setAnchorEl] = useState(null);
  const displayRole = controlled ? currentRole : selectedRole;

  return (
    <div style={{ marginLeft: 8, display: 'flex' }}>
      {removeIndicator && <UserAction {...props} />}
      <div
        style={{
          paddingRight: props?.isInvitation ? 12 : 2,
        }}
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
        className={classes.accessDropdownAnchor}>
        {props?.isInvitation ? 'Invited' : capitalize(displayRole)}
        {!hideDropdown && <ChevronDownIcon />}
      </div>
      <div style={{ zIndex: 10 }}>
        <Popper
          open={!hideDropdown && Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-end"
          disablePortal>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper>
              <List
                style={{
                  maxHeight: 400,
                  overflowY: 'auto',
                  padding: 0,
                }}>
                {[
                  ...TRIP_ACCESS_ROLES_FOR_NON_OWNER,
                  ...(showRestrictedOption ? ['Off'] : []),
                ].map((role) => (
                  <ListItem
                    key={role}
                    onClick={() => {
                      setSelectedRole(role);
                      setAnchorEl(null);
                      if (controlled) {
                        onRoleChange?.(role);
                      }
                    }}>
                    <ListItemText>{capitalize(role)}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    </div>
  );
}

export default AccessDropdown;
