import { useState, useEffect, useRef } from 'react';
import {
  Box,
  InputBase,
  IconButton,
  Popper,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import NoteContent from './NoteContent';
import useTripAccess from '../../utils/use-trip-access';
import { preventFocus } from '../../utils';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '536px',
    width: 'min(536px, 100%)',
    height: '100%',
  },
  header: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #DEDDDD',
    marginBottom: '16px',
  },
  headerText: {
    color: '#222222',
    fontSize: '24px',
    fontWeight: 700,
  },
  headerDropdown: {
    width: '12vw',
    padding: '4px 0',
    borderRadius: '4px',
    marginLeft: '20px',
    boxShadow:
      '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 1px rgba(0, 0, 0, 0.05)',
  },
  dropdownOption: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    color: '#222222',
    fontSize: '14px',
    paddingLeft: '16px',
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.primary.extraLight,
      cursor: 'pointer',
    },
  },
  selectedOption: {
    color: palette.primary.main,
    backgroundColor: palette.primary.extraLight,
  },
}));

function NoteContainer({ noteObj, noteUpdateCallback, noteDeleteCallback }) {
  const classes = useStyles();
  const [noteName, setNoteName] = useState(noteObj?.name || null);
  const [noteContent, setNoteContent] = useState(noteObj?.content || null);
  const [noteMode, setNoteMode] = useState('default');
  const [headerDropdownAnchor, setHeaderDropdownAnchor] = useState(null);
  const dropdownRef = useRef(null);
  const { isViewOnly } = useTripAccess();
  useEffect(() => {
    setNoteName(noteObj?.name || null);
    setNoteContent(noteObj?.content || null);
    setNoteMode('default');
  }, [noteObj]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHeaderDropdownAnchor(null);
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  const optionsList = [
    {
      label: 'Note',
      callback: () => setNoteMode('note'),
    },
    {
      label: 'Checklist',
      callback: () => setNoteMode('checklist'),
    },
    {
      label: 'Delete',
      callback: () => noteDeleteCallback(),
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <InputBase
          onFocus={(e) => {
            if (!isViewOnly) {
              preventFocus(e);
            }
          }}
          value={noteName || ''}
          placeholder="New Note"
          onChange={(e) => !isViewOnly && setNoteName(e.target.value)}
          onBlur={(e) =>
            noteUpdateCallback({ ...noteObj, name: e.target.value })
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              noteUpdateCallback({ ...noteObj, name: e.target.value });
            }
          }}
          inputProps={{ className: classes.headerText }}
          fullWidth
        />
        {!isViewOnly && (
          <IconButton
            disableRipple
            onClick={(e) => setHeaderDropdownAnchor(e.currentTarget)}
            size="large">
            <MoreHoriz />
          </IconButton>
        )}
        <Popper
          open={Boolean(headerDropdownAnchor)}
          anchorEl={headerDropdownAnchor}
          placement="right-start">
          <Paper className={classes.headerDropdown} ref={dropdownRef}>
            {optionsList.map((option, idx) => (
              <div key={idx.toString()}>
                {idx === optionsList.length - 1 && (
                  <Divider style={{ color: '#A7A7A7', height: '1px' }} />
                )}
                <Typography
                  className={`${classes.dropdownOption} ${
                    option.label.toLowerCase() === noteMode ||
                    (noteMode === 'default' &&
                      (noteContent?.find(
                        (node) => node.type === 'check-list-item'
                      )
                        ? option.label === 'Checklist'
                        : option.label === 'Note'))
                      ? classes.selectedOption
                      : ''
                  }`}
                  onClick={() => {
                    setHeaderDropdownAnchor(null);
                    option.callback();
                  }}>
                  {option.label}
                </Typography>
              </div>
            ))}
          </Paper>
        </Popper>
      </Box>
      <NoteContent
        key={noteObj?.id}
        content={noteContent}
        type={noteMode}
        contentUpdateCallback={(newContent) => {
          noteUpdateCallback({
            ...noteObj,
            name: noteName,
            content: newContent,
          });
          setNoteContent(newContent);
        }}
      />
    </Box>
  );
}

export default NoteContainer;
