import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Modal,
  Typography,
  Grid,
  IconButton,
  InputBase,
  Button,
  Divider,
  Fade,
  CircularProgress,
  Collapse,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
// import { EditIcon } from '@iconicicons/react';

import copy from 'copy-to-clipboard';

import {
  createTripInvitation,
  getPendingAccessRequests,
  updateTripUserRole,
} from '../../../../../redux/slices/TripV2';
import FeedbackPopup from '../../../../molecules/Feedback/FeedbackPopup';
import { HappyEmoji } from '../../../../atoms/Icon';
import { firebaseAuth } from '../../../../../provider/AuthProvider';
import UserAvatar from '../../../../molecules/UserAvatar';
import config from '../../../../config';
import { trackEvents, Events } from '../../../../../intercom';
import classList from '../../../../classList';
import getCfConnector from '../../../../cfConnector';
import { EVENTS, phTrackEvent } from '../../../../../analytics';
import AccessDropdown from './AccessDropdown';
import LinkInvitation from './LinkInvitation';
import { TRIP_ACCESS_ROLES_FOR_NON_OWNER } from '../../../../../const';
import AccessRequests from './AccessRequests';

const useStyles = makeStyles(({ palette }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  iconButton: {
    height: 24,
    width: 24,
  },
  icon: {
    height: 16,
    width: 16,
  },
  root: {
    outline: 0,
    backgroundColor: '#FFF',
    padding: '24px 0px',
    borderRadius: 8,
    boxShadow:
      '0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',

    // repsonsivity
    minWidth: 300,
    width: '95%',
    maxWidth: 500,
  },

  input: {
    border: '1px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: 14,
  },
  accessDropdownAnchor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    padding: '4px 0px 8px 12px',
    borderRadius: 4,
    alignItems: 'center',
    cursor: 'pointer',
  },
  activeAnchor: {
    backgroundColor: '#F4F4F4',
  },
  menu: {
    marginTop: 8,
    '& .MuiList-root': {
      paddingTop: 4,
      paddingBottom: 4,
      border: '1px solid #DEDDDD',
      borderRadius: 4,
    },
  },
  inviteButton: {
    marginLeft: 12,
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#DEDDDD',
    },
  },
  emailTag: {
    backgroundColor: palette.primary.light,
    fontSize: '0.875rem',
    padding: '4px 8px',
    borderRadius: 4,
  },
}));

function UserListItem({
  itemText = '',
  profilePhoto = null,
  index = 0,
  customEmojiProps = {},
  customAvatarStyles = {},
  isOwner = false,
  removeIndicator,
  tripId,
  tripUser,
  isInvitation,
  setFeedback = () => {},
}) {
  console.log({ tripUser });

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.Auth);

  const handleUserRoleChange = (role) => {
    dispatch(
      updateTripUserRole({
        variables: {
          tripId,
          userId: tripUser.id,
          role,
        },
      })
    );
  };
  return (
    <Grid container style={{ alignItems: 'center', marginTop: 12 }}>
      <UserAvatar
        user={{}}
        index={index}
        avatarProps={{
          style: {
            height: 28,
            width: 28,
            ...customAvatarStyles,
          },
          src: profilePhoto,
        }}
        showBorder={false}
        CustomContent={(props) => (
          <HappyEmoji {...props} {...customEmojiProps} />
        )}
      />
      <Typography
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 16,
        }}>
        {itemText}
      </Typography>
      {isOwner ? (
        <Typography
          style={{
            color: '#8A8A8A',
            fontSize: '0.875rem',
            marginRight: 8,
          }}>
          Owner
        </Typography>
      ) : (
        <AccessDropdown
          removeIndicator={removeIndicator}
          controlled
          currentRole={tripUser?.role}
          tripId={tripId}
          user={tripUser}
          isInvitation={isInvitation}
          invitation={tripUser}
          onRoleChange={handleUserRoleChange}
          setFeedback={setFeedback}
          hideDropdown={userId === tripUser?.id || isInvitation}
        />
      )}
    </Grid>
  );
}

function ShareTripModal({
  tripId,
  context = 'trip',
  trackerMeta = null,
  open,
  handleClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);
  const [newUserRole, setNewUserRole] = useState(
    trip?.privacySettings?.defaultJoinRole ||
      TRIP_ACCESS_ROLES_FOR_NON_OWNER.EDITOR
  );

  // to store the current input email, and list of emails (future)
  const [shareEmail, setShareEmail] = useState('');
  const [shareEmailList, setShareEmailList] = useState([]);

  const [inputActive, setInputActive] = useState(false);
  const [snackbarText, setSnackbarText] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [feedback, setFeedback] = useState(null);

  // timer to close the feedback popup after 15s
  let feedbackTimer;
  useEffect(() => {
    if (feedback) {
      feedbackTimer = window.setTimeout(() => {
        setFeedback(null);
      }, 15000);
    }

    return () => window.clearTimeout(feedbackTimer);
  }, [feedback]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPendingAccessRequests({
          variables: {
            tripId,
          },
        })
      );
    }
  }, [open]);

  const handleInputBlur = () => {
    setInputActive(false);
    if (shareEmail !== '') setShareEmailList([shareEmail]);
    setShareEmail('');
  };

  const handleInputFocus = () => {
    setInputActive(true);
    setShareEmailList([]);
    setShareEmail(shareEmailList?.length > 0 ? shareEmailList[0] : '');
  };

  const isValidEmail = (
    !inputActive && shareEmailList?.length > 0 ? shareEmailList[0] : shareEmail
  )
    .toLowerCase()
    .match(/\S+@\S+\.\S+/);

  const { user = {} } = useContext(firebaseAuth);
  const { origin } = window.location;

  const inviteLink = `${origin}/join/${tripId}`;

  const handleCopyLink = (link) => {
    trackEvents(Events.InviteLinkCopied);
    phTrackEvent({
      event:
        context === 'dashboard'
          ? EVENTS.TRIPS_DASHBOARD.TRIP_SHARE_LINK_COPY_CLICK
          : EVENTS.TRIP_NAV.INVITE_LINK_COPY_CLICK,
      meta: trackerMeta,
    });
    copy(link);
    setSnackbarText('Link copied!');
  };

  const checkRepeatedEmail = (email) => {
    if (shareEmailList?.length > 0) {
      const allEmails = [
        ...(trip?.users?.map((tripUser) => tripUser.email) || []),
        ...(trip?.invitations?.map((invitation) => invitation.email) || []),
      ];
      if (allEmails?.includes(email)) {
        return true;
      }
    }
    return false;
  };

  const handleSendEmail = () => {
    if (showLoader) return;
    setShowLoader(true);
    const email = shareEmailList[0];
    if (checkRepeatedEmail(email)) {
      setFeedback({
        type: 'ERROR',
        feedbackText:
          'An user with this email has already been added or invited!',
      });
      setShowLoader(false);
      setShareEmailList([]);
      return;
    }
    dispatch(
      createTripInvitation({
        variables: {
          userEmail: email,
          tripId,
          role: newUserRole,
        },
      })
    )
      .then(async () => {
        /*
          TODO: Manage send email as part of the createInvitation call on the backend.
        */
        try {
          await (
            await getCfConnector()
          ).post(config.emailAPI, {
            toEmail: email,
            tripName:
              trip?.title ||
              (user?.firstName ? `${user?.firstName}'s trip` : 'a trip'),
            inviterName: user?.displayName,
            tripLink: inviteLink,
            tripCoverImage: trip?.coverImage === '' ? null : trip?.coverImage,
          });
        } catch (err) {
          setFeedback({
            type: 'ERROR',
            feedbackText:
              'There was a problem with sending the email! Please try again after sometime',
          });
        }
      })
      .then(() => {
        phTrackEvent({
          event:
            context === 'dashboard'
              ? EVENTS.TRIPS_DASHBOARD.TRIP_SHARE_INVITE_SEND
              : EVENTS.TRIP_NAV.INVITE_SEND,
          meta: trackerMeta,
        });
        setFeedback({ feedbackText: 'Invite sent!', type: 'SUCCESS' });
      })
      .catch((err) => {
        console.log(err);
        setFeedback({
          type: 'ERROR',
          feedbackText:
            'Looks like the user has already been added! If not, please try again.',
        });
      })
      .finally(() => {
        setShareEmailList([]);
        setShowLoader(false);
      });
  };

  const inputRef = useRef();

  function EmailTag({ tag }) {
    return (
      <div
        className={classes.emailTag}
        onClick={() => {
          inputRef?.current?.focus();
        }}>
        {tag}
      </div>
    );
  }

  function onClose() {
    setFeedback(null);
    handleClose();
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <>
        <Collapse
          in={Boolean(feedback)}
          style={{
            minWidth: 300,
            width: '95%',
            maxWidth: 500,
            marginBottom: 8,
          }}>
          <FeedbackPopup {...feedback} />
        </Collapse>
        <Grid
          container
          className={`${classes.root} ${classList.shareTripModal}`}
          direction="column">
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0px 24px',
            }}>
            <Typography
              variant="h3Sub"
              style={{ flex: '1 0', display: 'flex', fontWeight: 600 }}>
              {trip?.title}
            </Typography>
            <IconButton className={classes.iconButton} onClick={onClose}>
              <Close className={classes.icon} />
            </IconButton>
          </Grid>

          {
            // Invite via Email
          }
          <Grid
            container
            direction="column"
            style={{ marginTop: 24, padding: '0px 24px' }}>
            <Grid item>
              <Typography
                style={{
                  color: '#222',
                  fontWeight: 600,
                }}>
                Invite a tripmate
                <Fade
                  in={showLoader}
                  style={{
                    transitionDelay: showLoader ? '800ms' : '0ms',
                  }}
                  unmountOnExit>
                  <CircularProgress size={10} style={{ marginLeft: 8 }} />
                </Fade>
              </Typography>
            </Grid>
            <Grid
              item
              style={{ marginTop: 12, display: 'flex', flexDirection: 'row' }}>
              <InputBase
                inputRef={inputRef}
                placeholder={
                  shareEmailList?.length > 0 ? '' : 'Add an email address'
                }
                className={classes.input}
                fullWidth
                value={shareEmail}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.currentTarget.blur();
                  }
                }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={(e) => setShareEmail(e.target?.value?.trim())}
                inputProps={{
                  style: {
                    padding: 0,
                  },
                }}
                startAdornment={
                  !inputActive &&
                  shareEmailList?.length > 0 &&
                  shareEmailList?.map((userEmail, idx) => (
                    <EmailTag tag={userEmail} key={idx} />
                  ))
                }
                endAdornment={
                  <AccessDropdown
                    controlled
                    currentRole={newUserRole}
                    onRoleChange={setNewUserRole}
                  />
                }
              />
              <Button
                disabled={!isValidEmail}
                onClick={handleSendEmail}
                className={classes.inviteButton}>
                Invite
              </Button>
            </Grid>
          </Grid>

          {
            // trip users container
          }
          <div style={{ marginTop: 4, padding: '0px 16px 0px 24px' }}>
            <Grid
              container
              direction="row"
              style={{
                overflowY: 'scroll',
                maxHeight: 120,
                minHeight: 0,
              }}>
              {[
                ...(trip?.users || []),
                ...(trip?.invitations?.map(({ email: userEmail, id }) => ({
                  firstName: userEmail,
                  isInvitation: true,
                  invitationId: id,
                })) || []),
              ]?.map((tripUser, index) => (
                <Grid item xs={12} key={tripUser?.id || tripUser?.invitationId}>
                  <UserListItem
                    key={index}
                    profilePhoto={tripUser?.profilePicture}
                    itemText={`${tripUser?.firstName || ''} ${
                      tripUser?.lastName || ''
                    }`}
                    index={index}
                    isOwner={tripUser?.id === trip?.owner?.id && index === 0}
                    removeIndicator={user?.uid === trip?.owner?.firebaseUid}
                    tripId={tripId}
                    isInvitation={tripUser?.isInvitation}
                    tripUser={tripUser}
                    setFeedback={setFeedback}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="row"
              style={{
                overflowY: 'scroll',
                maxHeight: 120,
                minHeight: 0,
              }}>
              <AccessRequests />
            </Grid>
          </div>

          {
            // Invite via Link
          }
          <Divider style={{ marginTop: 24 }} />
          <LinkInvitation
            tripId={tripId}
            setSnackbarText={setSnackbarText}
            snackbarText={snackbarText}
            inviteLink={inviteLink}
            onClose={onClose}
            handleCopyLink={handleCopyLink}
          />
        </Grid>
      </>
    </Modal>
  );
}

export default ShareTripModal;
