import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
  useMediaQuery,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {
  StarRounded,
  ArrowDropDownRounded,
  Close,
  AddRounded,
  CheckRounded,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Bookmark } from '../../../../atoms/Icon';
import actions from '../../../../../redux/actions';
import {
  addSavedItemV2,
  deleteSavedItemV2,
} from '../../../../../redux/slices/Recommendations';
import recommendationCategories from '../../../../../assets/newRecommendationCategories.json';
import { formatRatingCount } from '../../../../../utils';
import CustomCarousel from '../../../../molecules/Carousel';
import { useSounds, SOUNDS } from '../../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../../analytics';
import NotificationPopper from '../../../organisms/Stays/NotificationPopper';
import { useMapUtils } from '../../../organisms/Map';
import { SecondaryOutlinedButton } from '../../../../atoms/Button/index';
import AddToTripButton from '../../AddToTripButton';
import { createItem, deleteItem } from '../../../../../redux/slices/Item';
import ITEM_TYPES, { SAVED_ITEM_TYPES } from '../../../../../const';
import useTripAccess from '../../../../../utils/use-trip-access';

const useStyles = makeStyles(({ palette }) => ({
  card: ({ isPopupCard }) => ({
    // width: isPopupCard ? 300 : '100%',
    borderRadius: 4,
    boxShadow: isPopupCard ? 'auto' : 'none',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      boxShadow: isPopupCard ? 'auto' : 'none',
      cursor: 'pointer',
    },
  }),
  cardContent: ({ isPopupCard }) => ({
    height: isPopupCard ? '130px' : '100px',
    padding: isPopupCard ? '6px 12px 12px 12px !important' : '2px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '12px !important',
  }),
  buttonContainer: {
    marginLeft: 'auto',
  },
  actionIconBtn: {
    backgroundColor: '#FFF',
    padding: 8,
    height: '30px',
    width: '30px',
    position: 'absolute',
    top: 10,
    zIndex: 3,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  saveIconContainer: {
    right: 4,
  },
  closeIconContainer: {
    right: 4,
  },
  addToTripContainer: {
    right: 44,
    '&.Mui-disabled': {
      cursor: 'default',
      pointerEvents: 'all !important',
      backgroundColor: '#FFF',
      color: palette.text.light,
      '&:hover': {
        backgroundColor: '#FFF',
        '& > svg': {
          color: palette.text.light,
        },
      },
    },
  },
  savedIcon: ({ saved: isSaved }) => ({
    color: isSaved ? palette.primary.main : 'currentColor',
    fill: isSaved ? palette.primary.main : 'none',
  }),
}));

function RecommendationCard({
  place = {},
  index,
  isPopupCard = false,
  isSavedList = false,
  disableAddingToTrip = false,
  disableAddingToSavedList = false,
}) {
  const { slug: tripId } = useParams();

  const savedItem = useSelector(
    (state) =>
      place?.referenceId &&
      Object.values(state.Recommendations.saved[tripId] || {}).filter(
        (obj) => obj?.referenceId === place?.referenceId
      )
  );
  const isSaved = Boolean(savedItem?.length);

  // repeated state for faster local user feedback
  const [saved, setSaved] = useState(isSaved);
  const [cardHovered, setCardHovered] = useState(false);
  const [activeActionProps, setActiveActionProps] = useState(null);
  const [popperNotification, setPopperNotification] = useState(null);
  const [destinationSelectorOpen, setDestinationSelectorOpen] = useState(null);
  const [addToTripLoader, setAddToTripLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const destinationSelectorRef = useRef(null);
  const isNewDestination = useRef(false);
  const isUpdateSelection = useRef(false);
  const iconContainerRef = useRef();
  const dropDownRef = useRef();

  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || {});
  const tripDestinations = useSelector((state) =>
    Object.values(state.Item.items || {})
      .filter(
        (item) => item.tripId === tripId && item.type === ITEM_TYPES.DESTINATION
      )
      ?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {})
  );
  const items = useSelector((state) => state.Item.items || {});
  const locationContext = useSelector(
    (state) => state.Recommendations.recommendations[tripId]?.city || {}
  );

  const classes = useStyles({ saved, isPopupCard });
  const { createMapPinForPlace, createMapPinForPlaceId } = useMapUtils();
  const { playSound } = useSounds();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => setSaved(isSaved), [isSaved]);
  const { canEdit } = useTripAccess();

  const dispatch = useDispatch();

  const navigateToDetails = () => {
    dispatch(
      actions.View.setPoiView({
        isInView: true,
        poi: place,
        cardIndex: index,
      })
    );
    dispatch(
      actions.Recommendations.setActivePoisPlaceMarker({
        cardIndex: index,
        posiPlace: place,
      })
    );
  };

  const handleCardClick = () => {
    phTrackEvent({
      event: EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_CLICK,
    });
    navigateToDetails();
  };

  const handleCloseClick = () => {
    dispatch(actions.Recommendations.setActivePoisPlaceMarker(null));
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    if (loading) return;
    setLoading(true);
    if (isSaved) {
      playSound(SOUNDS.removeBaritone);
      setSaved(false);
      await dispatch(
        deleteSavedItemV2({
          variables: {
            id: savedItem?.length > 0 && savedItem[0].id,
          },
          tripId,
        })
      ).catch(() => setSaved(true));
    } else {
      playSound(SOUNDS.heartChime);
      phTrackEvent({
        event: EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_SAVED_LIST_ADD,
      });
      setSaved(true);
      await dispatch(
        addSavedItemV2({
          variables: {
            tripId,
            itemToSave: {
              referenceId: place.referenceId,
              savedData: place,
              tripId,
              type: SAVED_ITEM_TYPES.ACTIVITY,
            },
          },
        })
      ).catch(() => setSaved(false));
    }
    setLoading(false);
  };

  function FormattedTagsList() {
    const topTags = place?.rankedCategories?.slice(0, 2);
    return topTags?.length > 0 ? (
      <div
        style={{
          display: 'flex',

          flexGrow: 1,
        }}>
        {topTags?.map((tag) => (
          <div
            style={{
              backgroundColor: '#F4F4F4',
              padding: '4px 8px',
              borderRadius: 12,
              marginRight: 6,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}>
            <Typography variant="h5" fontSize={12} color="text.light" noWrap>
              {recommendationCategories.ref[tag]?.name || ''}
            </Typography>
          </div>
        ))}
      </div>
    ) : isPopupCard ? (
      <div style={{ padding: '4px 8px', visibility: 'hidden' }} />
    ) : null;
  }

  const handleCardMouseEnter = () => {
    setCardHovered(true);
    if (!isMobile && !isPopupCard) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({
          cardIndex: index,
          tripId,
        })
      );
    }
  };

  const handleCardMouseLeave = () => {
    setCardHovered(false);
    if (!isMobile && !isPopupCard) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({ cardIndex: null, tripId })
      );
    }
  };

  const isActivityAddedToTrip = useMemo(
    () =>
      (Object.values(mapPins)
        ?.map((mapPin) => mapPin?.placeId === place?.referenceId)
        ?.filter(Boolean)?.length || 0) > 0,
    [mapPins, tripId, place?.referenceId]
  );

  // Process filtered locations to add mapPin, hotelsCount, etc.
  const validLocations = useMemo(
    () =>
      Object.values(tripDestinations || {})
        .filter((loc) => loc.tripId === tripId && loc.title !== '')
        .map((loc) => {
          let mapPin;
          if (loc.mapPin) {
            mapPin = mapPins[loc.mapPin];
          }
          const childHeadings =
            loc.children?.filter(
              (childId) => items[childId]?.type === ITEM_TYPES.HEADING
            ) || [];
          return {
            id: loc.id,
            title: loc.title,
            mapPin,
            sectionsCount: childHeadings?.length || 0,
            defaultSectionId:
              childHeadings?.length > 0 ? childHeadings[0] : null,
          };
        })
        .filter((tag) => Boolean(tag)),
    [tripDestinations, mapPins]
  );

  const similarLocations = useMemo(
    () =>
      validLocations?.filter(
        (location) =>
          location?.title?.toLowerCase()?.trim() ===
          locationContext?.title?.toLowerCase()?.trim()
      ),
    [locationContext, validLocations]
  );

  // eslint-disable-next-line no-unused-vars
  const autoAddToTrip = async ({ sectionId, locationId }) => {
    let destinationId = locationId;
    let headingId = sectionId;
    let locationMapPinId;
    if (!destinationId) {
      try {
        locationMapPinId = await createMapPinForPlaceId(
          locationContext?.placeId,
          'LOCATION'
        );
        const location = await dispatch(
          createItem({
            variables: {
              title: locationContext.title,
              tripId,
              parentId: tripId,
              mapPin: locationMapPinId,
              index: 0,
              type: ITEM_TYPES.DESTINATION,
            },
            index: 0,
            parentId: tripId,
            tripId,
          })
        ).then((data) => data.payload.createItem);
        destinationId = location?.id;
        isNewDestination.current = true;
      } catch (err) {
        return;
      }
    }
    if (!headingId) {
      headingId = (
        await dispatch(
          createItem({
            variables: {
              title: 'Things to do',
              tripId,
              parentId: destinationId,
              type: ITEM_TYPES.HEADING,
              index: 0,
            },
            index: 0,
            parentId: destinationId,
            tripId,
          })
        )
      )?.payload?.createItem?.id;
    }
    const mapPin = await createMapPinForPlace(
      {
        title: place?.title,
        placeId: place?.referenceId,
        website: place?.website,
        photo: place?.thumbnail,
        lat: place?.coordinates?.latitude,
        long: place?.coordinates?.longitude,
      },
      'ACTIVITY'
    );

    const newActivityId = (
      await dispatch(
        createItem({
          variables: {
            title: place?.title,
            mapPin: mapPin?.id,
            parentId: headingId,
            index: 0,
            tripId,
            type: ITEM_TYPES.ACTIVITY,
            content: {
              address: {
                streetAddress: place?.address,
              },
            },
          },
          index: 0,
          parentId: headingId,
          tripId,
        })
      )
    )?.payload?.createItem?.id;

    setActiveActionProps({
      locationId: destinationId,
      locationMapPinId,
      isNewLocation: Boolean(locationMapPinId),
      isNewSection: false,
      sectionId,
      activityId: newActivityId,
      activityMapPinId: mapPin?.id,
    });
    setPopperNotification(
      tripDestinations[locationId]?.title || locationContext.title
    );
  };

  const handleActivityAddToTrip = async () => {
    if (addToTripLoader) return;
    const location = similarLocations?.length > 0 ? similarLocations[0] : null;
    setAddToTripLoader(true);
    await autoAddToTrip({
      locationId: location?.id,
      sectionId: location?.defaultSectionId,
    });
    setAddToTripLoader(false);
  };

  const handleUndoAction = async ({ newLocationId, newSectionId }) => {
    if (
      newLocationId === activeActionProps?.locationId &&
      newSectionId === activeActionProps?.sectionId &&
      newLocationId &&
      newSectionId
    ) {
      return 'NO-ACTION';
    }

    if (!activeActionProps) return null;

    const {
      locationId = null,
      locationMapPinId = null,
      activityId = null,
      activityMapPinId = null,
      sectionId = null,
      isNewSection = null,
    } = activeActionProps;

    if (activityId === null) return null;

    if (isNewDestination.current) {
      await dispatch(
        deleteItem({
          variables: {
            id: locationId,
            tripId,
            parentId: tripId,
          },
          parentId: tripId,
          tripId,
          mapPin: locationMapPinId,
        })
      );
    } else if (isNewSection) {
      await dispatch(
        deleteItem({
          variables: {
            id: sectionId,
            parentId: locationId,
            tripId,
          },
          parentId: locationId,
          tripId,
        })
      );
    }
    await dispatch(
      deleteItem({
        variables: {
          id: activityId,
          parentId: locationId,
          tripId,
          mapPin: activityMapPinId,
        },
        mapPin: activityMapPinId,
        parentId: locationId,
        tripId,
      })
    );
    setActiveActionProps(null);
    return null;
  };

  return (
    <Card
      className={`${classes.card} recommendation-poi-card`}
      onMouseEnter={handleCardMouseEnter}
      onMouseLeave={handleCardMouseLeave}
      id={`recommendation-poi-card-${place?.referenceId}`}>
      <div ref={iconContainerRef} />
      <Box position="relative" onClick={(e) => e?.stopPropagation()}>
        <NotificationPopper
          open={Boolean(popperNotification)}
          anchorEl={iconContainerRef?.current}
          onClose={(e, reason) => {
            if (reason === 'timeout') {
              setActiveActionProps(null);
            }
            setPopperNotification(null);
          }}
          onCancel={async () => {
            setPopperNotification(null);
            if (validLocations?.length <= 1) {
              setAddToTripLoader(true);
              await handleUndoAction({
                newLocationId: null,
                newSectionId: null,
              });
              setAddToTripLoader(false);
            } else {
              isUpdateSelection.current = true;
              setDestinationSelectorOpen(true);
            }
          }}
          destinationName={popperNotification}
          isNewDestination={isNewDestination?.current}
          cancelButtonLabel={validLocations?.length <= 1 ? 'Undo' : 'Change'}
        />
        {!isPopupCard && canEdit && (
          <>
            <AddToTripButton
              isRecommendationCard
              tripId={tripId}
              setLoader={setAddToTripLoader}
              customAnchor={iconContainerRef?.current}
              initAction={handleUndoAction}
              postAction={({ locationId }) =>
                setPopperNotification(tripDestinations[locationId]?.title)
              }
              handleClose={setDestinationSelectorOpen}
              trigger={destinationSelectorOpen}
              CustomButton={(props) => (
                <div>
                  {!disableAddingToSavedList && (
                    <IconButton
                      disabled={isActivityAddedToTrip || addToTripLoader}
                      className={`${classes.addToTripContainer} ${classes.actionIconBtn}`}
                      id={`poi-card-add-to-trip-button-${index}`}
                      onClick={async (e) => {
                        if (
                          similarLocations?.length > 1 ||
                          isUpdateSelection?.current ||
                          isSavedList
                        ) {
                          props?.onClick(e);
                          isUpdateSelection.current = false;
                        } else {
                          await handleActivityAddToTrip();
                        }
                      }}
                      ref={destinationSelectorRef}>
                      {addToTripLoader ? (
                        <CircularProgress size={14} />
                      ) : isActivityAddedToTrip ? (
                        <CheckRounded
                          fontSize="small"
                          sx={{ color: '#43A047' }}
                        />
                      ) : (
                        <AddRounded fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </div>
              )}
              placeDetails={{
                title: place?.title,
                placeId: place?.referenceId,
                website: place?.website,
                photo: place?.thumbnail,
                lat: place?.coordinates?.latitude,
                long: place?.coordinates?.longitude,
                address: place?.address,
              }}
              id={`recommendations-poi-card-add-to-trip-button-${place?.referenceId}`}
              className="recommendations-poi-card-add-to-trip-button"
              source="recommendations"
            />

            {!disableAddingToSavedList && canEdit && (
              <IconButton
                onClick={handleSaveClick}
                className={`${classes.saveIconContainer} ${classes.actionIconBtn} recommendations-poi-card-save-button`}
                id={`recommendations-poi-card-save-button-${place?.referenceId}`}>
                <Bookmark
                  height={16}
                  width={16}
                  className={classes.savedIcon}
                />
              </IconButton>
            )}
          </>
        )}
        {cardHovered && isPopupCard && (
          <IconButton
            onClick={handleCloseClick}
            className={`${classes.closeIconContainer} ${classes.actionIconBtn}`}>
            <Close
              height={16}
              width={16}
              sx={{ fontSize: '14px', color: '#000000' }}
            />
          </IconButton>
        )}

        <CustomCarousel
          onImageClick={handleCardClick}
          showArrows={cardHovered}
          images={place?.images}
          isRecomendationCard
        />
      </Box>
      <CardContent className={classes.cardContent}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px',
          }}>
          <Typography
            variant="h3"
            fontSize={16}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {place.title}
          </Typography>

          {isPopupCard && !disableAddingToSavedList && (
            <IconButton
              sx={{ borderRadius: '8px', width: '30px', height: '30px' }}
              onClick={handleSaveClick}
              className="recommendations-poi-card-save-button"
              id={`recommendations-poi-card-save-button-${place?.referenceId}`}>
              <Bookmark height={16} width={16} className={classes.savedIcon} />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: isPopupCard ? 'flex' : 'block',
            flexDirection: isPopupCard ? 'row' : 'column',
            justifyContent: 'space-between',
          }}>
          <FormattedTagsList />
          {place?.rating && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                marginTop: !isPopupCard ? '8px' : '0px',
              }}>
              <StarRounded
                sx={{ height: 20, width: 20, color: '#ED702E', mr: '4px' }}
              />
              <Typography
                variant="h5"
                fontSize={14}
                color="#4E4E4E"
                justifyItems="center"
                alignContent="center">
                <b>{place.rating ? (place.rating / 2).toFixed(1) : ''}</b>
                {place.ratingCount
                  ? ` (${formatRatingCount(place.ratingCount)})`
                  : ''}
              </Typography>
            </div>
          )}
        </Box>
        <div
          style={{
            display: 'flex',
            paddingTop: '6px',
            marginTop: 'auto',
            justifyContent: 'center',
          }}>
          {isPopupCard && !disableAddingToTrip && (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                width: '100%',
                justifyItems: canEdit ? 'flex-start' : 'flex-end',
              }}>
              <ButtonGroup
                variant="outlined"
                sx={{
                  boxShadow: 'none',
                  '& .MuiButtonGroup-grouped': {
                    minWidth: 0,
                  },
                }}>
                {canEdit && (
                  <>
                    <SecondaryOutlinedButton
                      ref={dropDownRef}
                      sx={{
                        padding: '2px 12px',
                        fontSize: '12px',
                        borderRight: 'none',
                        borderRadius: 1,
                        '&:hover': {
                          borderColor: '#D9D9D9',
                          backgroundColor: '#F4F4F4',
                          borderRight: 'none',
                          color: '#000',
                        },
                      }}
                      onClick={async () => {
                        await handleActivityAddToTrip();
                      }}>
                      Add to trip
                    </SecondaryOutlinedButton>
                    <AddToTripButton
                      isRecommendationCard
                      placeDetails={{
                        title: place?.title,
                        placeId: place?.referenceId,
                        website: place?.website,
                        photo: place?.thumbnail,
                        lat: place?.coordinates?.latitude,
                        long: place?.coordinates?.longitude,
                        rating: place?.rating,
                        ratingCount: place?.ratingCount,
                        description: place?.description,
                      }}
                      postAction={({ locationId }) =>
                        setPopperNotification(
                          tripDestinations[locationId]?.title
                        )
                      }
                      customAnchor={dropDownRef?.current}
                      CustomButton={(props) => (
                        <SecondaryOutlinedButton
                          {...props}
                          sx={{
                            padding: '2px 2px',
                            borderRadius: 1,
                            borderLeft: 'none',
                            '&:hover': {
                              borderColor: '#D9D9D9',
                              backgroundColor: '#F4F4F4',
                              color: '#000',
                              transition: 'none',
                              borderLeft: 'none',
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              left: '0',
                              top: '30%',
                              height: '40%',
                              width: '1px',
                              backgroundColor: '#000',
                              transition: 'none',
                            },
                          }}
                          size="small">
                          <ArrowDropDownRounded
                            sx={{ padding: 0, margin: 0 }}
                          />
                        </SecondaryOutlinedButton>
                      )}
                      tripId={tripId}
                      id={`recommendations-page-add-to-trip-button-${place?.referenceId}`}
                      className="recommendations-page-add-to-trip-button"
                      source="recommendations-details"
                    />
                  </>
                )}
              </ButtonGroup>
              <Button
                sx={{
                  padding: '2px 24px',
                  fontSize: '12px',
                  '&:hover': {
                    backgroundColor: '#D35E07',
                  },
                }}
                onClick={navigateToDetails}>
                See details
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default RecommendationCard;
