import { useState } from 'react';
import { InputBase, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classList from '../../../classList';
import { getTextWidth, preventFocus } from '../../../../utils';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  titleInput: {
    ...typography.h2Sub,
    fontSize: '26px',
    padding: '0px 8px',
    borderRadius: '4px',
    backgroundColor: '#FFF',
    zIndex: 11,
    [breakpoints.down('sm')]: {
      fontSize: 22,
      marginTop: 0,
      width: '100%',
      padding: '0px 12px',
    },
    [breakpoints.up('sm')]: {
      marginRight: 5,
    },
  },
  titleInputBase: {
    overflow: 'hidden',
    zIndex: 11,
    textOverflow: 'ellipsis',
  },
  titleInputFilled: {
    '&:focus-within': {
      backgroundColor: palette.system.lightgrey,
    },
    '&:hover': {
      backgroundColor: palette.system.lightgrey,
    },
    '& .MuiInputBase-inputMultiline': {
      overflow: 'hidden',
    },
  },
  titleInputGhost: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: 'rgba(0,0,0,0.5);',
      },
    },
    '&:focus-within': {
      backgroundColor: palette.system.lightgrey,
    },
    '&:hover': {
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: 'rgba(0,0,0,0.8);',
        },
      },
    },
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: '#fff',
  },
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #E6E8ED',
    color: '#4A4A4A',
    fontSize: 16,
    borderRadius: 6,
  },
}));

// controlled component
function TripTitleInput({
  titleInputRef,
  handleTripNameChange,
  tripName,
  onFocus,
  disableEditing,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(tripName);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <InputBase
      id="trip-name-input"
      inputRef={titleInputRef}
      inputProps={{
        style: { padding: 0 },
      }}
      className={`${
        value === '' ? classes.titleInputGhost : classes.titleInputFilled
      } ${classes.titleInput} ${classList.tripTitle}`}
      placeholder="Name your trip"
      value={value}
      onFocus={(event) => {
        if (disableEditing) {
          preventFocus(event);
        } else if (onFocus) {
          onFocus();
        }
      }}
      onBlur={handleTripNameChange}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          titleInputRef.current?.blur();
        }
      }}
      sx={{
        ...(!isMobile &&
          (value === ''
            ? {
                maxWidth: 200,
              }
            : {
                maxWidth: '40vw',
                width: `${
                  getTextWidth(
                    value,
                    `600 ${isMobile ? '16px' : '26px'} Work Sans`
                  ) +
                  16 -
                  0.3 * value.length
                }px`,
              })),
      }}
      name="tripName"
      autoComplete="off"
      rowsMax={1}
      classes={{
        input: classes.titleInputBase,
      }}
    />
  );
}

export default TripTitleInput;
