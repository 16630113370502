import { useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Tooltip,
  Fade,
  useMediaQuery,
} from '@mui/material';

// import { useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@mui/styles';
import { CalendarToday } from '@mui/icons-material';

import { format } from 'date-fns';
import DateTimePicker from './DatePickers/DateTimePicker';
import DateRangePicker from './DatePickers/DateRangePicker';
import { trackEvents, Events } from '../../intercom';
import classList from '../classList';
import { CalendarMonthIcon } from '../atoms/Icon';

const useStyles = makeStyles(({ palette }) => ({
  iconButton: {
    height: 'auto',
    borderRadius: 4,
    padding: '4px 8px',
    color: palette.text.light,
    backgroundColor: palette.system.lightgrey,
    marginRight: (props) => (props.marginRight ? props.marginRight : 0),
    border: `1px solid transparent`,
    '&:hover': {
      backgroundColor: palette.system.lightgrey,
      color: palette.text.light,
      border: `1px solid ${palette.text.light}`,
    },
  },
  emptyButton: {
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    },
  },
  dateText: {
    marginLeft: 8,
    fontSize: 14,
    lineHeight: 1,
  },
  tooltip: {
    backgroundColor: '#4E4E4E',
    border: 'none',
    fontSize: 12,
    borderRadius: '4px',
    padding: '2px 4px',
    marginTop: '15px',
    zIndex: 1121,
  },
  tooltipArrow: {
    border: 'none',
    color: '#4E4E4E',
    zIndex: 1121,
  },
  defaultText: {
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
    fontSize: 14,
  },
}));

/**
 * Use this component as a datepicker.
 * @param {bool, default=true} useRange -> Calendar to pick a range of dates/a single date
 * @param {date || {from:date, to:date}, default=null} defaultDate -> The default date for the component
 * @param {object, default=null} iconButtonProps -> props to be applied to the calendar iconButton, can be used to modify custom components as the iconButton.
 * @param {object, default=null} iconProps -> props to be applied to the calendar icon itself, modify sizes here.
 * @param {object, default=null} dateStringProps -> props to be applied to the date string next to the calendar button.
 * @param {function, default=()=>{}} onDateUpdate -> function that gets called once the "DONE" button is called from the calendar. sends the selected range/day as a parameter to the function.
 * @param {number, default=1} numberOfMonths -> to decide how many calander to be opened
 * @param {string, default='MMM do'} dateStringFormat -> String to descide the format of date to be shown e.g 'MMM do', "yyyy-MM-dd". Format according to liberary date-fns
 * @param {bool, default=false} isBookings -> boolean value to open calander according to the check-in check-out functionality
 * @param {function, default=()=>{}} onButtonClick -> function that gets called once the IconDatePicker button is clicked
 * @param {function, default=()=>{}} onCalanderClose -> function that gets called once the calander is closed either by any outside click or by clicking "DONE" button
 */
function IconDatePicker({
  useRange = true,
  defaultDate,
  iconButtonProps,
  iconProps,
  customIcon = false,
  dateStringProps,
  onDateUpdate,
  buttonStyle,
  displayDate,
  customDisplay,
  isOpenSetter = () => {},
  showComponentTooltip = true,
  placement = 'bottom-start',
  numberOfMonths = 1,
  dateStringFormat = 'MMM do',
  isBookings = false,
  onButtonClick = () => {},
  onMouseDownEvent = () => {},
  onMouseUpEvent = () => {},
  onCalanderClose = () => {},
  disableScroll = false,
  disableEditing = false,
  // toDoId = null,
}) {
  const theme = useTheme();
  // const thingsToDo = useSelector((state) => state.Section.sections);
  // state to hold the formatted date string
  const [dateString, setDateString] = useState('');
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const [day, setDay] = useState(defaultDate || null);

  // let minDate = null;
  // // Find the corresponding toDo object based on toDoId
  // const matchingToDo = Object.values(thingsToDo || {}).find((toDo) =>
  //   toDo.todos.includes(toDoId)
  // );
  // if (matchingToDo) {
  //   minDate = matchingToDo?.startDate;
  // }

  /**
   * Formatting strings according to the following conditions:
   * 	- range
   * 		- Monday March 3rd: if only start date is defined/ start date=end date
   * 		- Mar 3rd - 4th: ranges within same month
   * 		- Mar 3rd - Apr 3rd: any other ranges
   * 	  - single day
   * 		- Monday March 3rd: All single days
   */
  const updateDateString = (updatedDate) => {
    let newDateString = '';
    if (useRange) {
      if (
        updatedDate?.from &&
        String(updatedDate?.to).valueOf() ===
          String(updatedDate?.from).valueOf()
      ) {
        newDateString = format(updatedDate?.from, dateStringFormat);
      } else if (updatedDate?.from && updatedDate?.to) {
        if (format(updatedDate.from, 'MMM') === format(updatedDate.to, 'MMM')) {
          newDateString = `${format(
            updatedDate.from,
            dateStringFormat
          )} - ${format(updatedDate.to, dateStringFormat)}`;
        } else {
          newDateString = `${format(
            updatedDate.from,
            dateStringFormat
          )} - ${format(updatedDate.to, dateStringFormat)}`;
        }
      } else if (updatedDate?.from) {
        newDateString = isBookings
          ? `${format(updatedDate?.from, dateStringFormat)} - ${'mm/dd/yy'}`
          : format(updatedDate?.from, dateStringFormat);
      }
    } else if (updatedDate) {
      newDateString = format(updatedDate, 'MMM do');
    }
    setDateString(newDateString);
  };

  const defaultRange = useRange && {
    from: defaultDate?.from,
    to: defaultDate?.to,
  };

  const openCalendar = (e) => {
    setCalendarAnchor(e.currentTarget);
    isOpenSetter(true);
  };

  const closeCalendar = (updatedDate) => {
    if (updatedDate) {
      const finalDate = isBookings
        ? {
            ...updatedDate,
            to: updatedDate.to
              ? updatedDate?.to
              : new Date(updatedDate.from.getTime() + 1 * 24 * 60 * 60 * 1000),
          }
        : updatedDate;
      onDateUpdate(finalDate);
      updateDateString(updatedDate);
      if (!defaultDate && !defaultRange) trackEvents(Events.DateAdded);
    } else if (updatedDate === null) {
      onDateUpdate(null);
    }

    onCalanderClose();
    setCalendarAnchor(null);
    isOpenSetter(false);
  };

  const isCalendarAnchor = Boolean(calendarAnchor);
  // eslint-disable-next-line no-unused-vars
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    updateDateString(defaultRange);
    setDay(defaultDate); // for fixing time mismatch after update
  }, []);

  useEffect(() => {
    if (isBookings && isMobile && calendarAnchor !== null && !disableScroll) {
      calendarAnchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [calendarAnchor]);
  return (
    <>
      {isCalendarAnchor &&
        (useRange ? (
          <DateRangePicker
            disableEditing={disableEditing}
            open={isCalendarAnchor}
            anchorEl={calendarAnchor}
            handleClose={closeCalendar}
            defaultRange={defaultRange}
            placement={placement}
            numberOfMonths={numberOfMonths}
            isBookings={isBookings}
            onDateUpdate={onDateUpdate}
          />
        ) : (
          <DateTimePicker
            disableEditing={disableEditing}
            open={isCalendarAnchor}
            handleClose={closeCalendar}
            anchorEl={calendarAnchor}
            selectedDate={day}
            handleDateChange={(newDay) => setDay(newDay)}
            handleSubmit={(newDay) => closeCalendar(newDay)}
            // minDate={minDate}
          />
        ))}
      <IconDatePickerButton
        disableEditing={disableEditing}
        dateString={dateString}
        openCalendar={openCalendar}
        displayDate={displayDate}
        dateStringProps={dateStringProps}
        iconProps={iconProps}
        customIcon={customIcon}
        customDisplay={customDisplay}
        iconButtonProps={iconButtonProps}
        buttonStyle={buttonStyle}
        useRange={useRange}
        showComponentTooltip={showComponentTooltip}
        onButtonClick={onButtonClick}
        onMouseDownEvent={onMouseDownEvent}
        onMouseUpEvent={onMouseUpEvent}
      />
    </>
  );
}

export function IconDatePickerButton({
  dateString = '',
  openCalendar = () => {},
  displayDate = true,
  dateStringProps = {},
  iconProps = {},
  iconButtonProps = {},
  customIcon = false,
  customDisplay,
  buttonStyle,
  useRange = false,
  showComponentTooltip = true,
  onButtonClick = () => {},
  onMouseDownEvent = () => {},
  onMouseUpEvent = () => {},
  disableEditing = false,
}) {
  const classes = useStyles(buttonStyle);
  if (showComponentTooltip) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        arrow
        title={dateString === '' ? (useRange ? 'Dates' : 'Date') : ''}
        enterDelay={500}
        disableInteractive
        TransitionComponent={Fade}>
        <IconButton
          onMouseDown={() => {
            onMouseDownEvent();
          }}
          onMouseUp={() => {
            onMouseUpEvent();
          }}
          onClick={(e) => {
            if (!disableEditing) {
              openCalendar(e);
              onButtonClick();
            }
          }}
          disableRipple
          className={`${classes.iconButton} ${
            !dateString ? classes.emptyButton : ''
          } ${classList.iconDatePickerButton}`}
          {...iconButtonProps}
          style={{
            ...(disableEditing && {
              cursor: 'default',
            }),
          }}
          size="small">
          {customIcon ? (
            <CalendarMonthIcon />
          ) : (
            <CalendarToday sx={{ height: 20, width: 20 }} {...iconProps} />
          )}
          {displayDate !== false && (
            <Typography className={classes.dateText} {...dateStringProps}>
              {dateString || 'Add Dates'}
            </Typography>
          )}
          {customDisplay}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton
      onClick={openCalendar}
      className={`${classes.iconButton} ${
        !dateString ? classes.emptyButton : ''
      } ${classList.iconDatePickerButton}`}
      {...iconButtonProps}
      size="small">
      {customIcon ? (
        <CalendarMonthIcon />
      ) : (
        <CalendarToday sx={{ height: 20, width: 20 }} {...iconProps} />
      )}
      {displayDate !== false && (
        <Typography className={classes.dateText} {...dateStringProps}>
          {dateString || 'Add Dates'}
        </Typography>
      )}
      {customDisplay}
    </IconButton>
  );
}

export default IconDatePicker;
