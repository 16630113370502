import { useState, useCallback, useEffect } from 'react';
import { Box, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Slate,
  Editable,
  withReact,
  useSlateStatic,
  useReadOnly,
  ReactEditor,
} from 'slate-react';
import {
  Editor,
  Transforms,
  Range,
  Point,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import useTripAccess from '../../utils/use-trip-access';

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkText: {
    flex: 1,
    '&:focus': {
      outline: 'none',
    },
  },
}));

const withChecklists = (editor) => {
  const { deleteBackward } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'check-list-item',
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          const newProperties = {
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'check-list-item',
          });
          return;
        }
      }
    }
    deleteBackward(...args);
  };

  return editor;
};

function NoteContent({ content, type, contentUpdateCallback }) {
  const [value, setValue] = useState(content);
  const [mode, setMode] = useState(type);
  const renderElement = useCallback(
    (props) => <Element {...props} updateCallback={contentUpdateCallback} />,
    []
  );
  const [editor] = useState(
    withChecklists(withHistory(withReact(createEditor())))
  );
  const { isViewOnly } = useTripAccess();
  useEffect(() => {
    let newValue;
    if (type === 'note') {
      newValue = value.map((node) => ({ ...node, type: 'paragraph' }));
    } else if (type === 'checklist') {
      newValue = value.map((node) => ({
        ...node,
        type: 'check-list-item',
        checked: false,
      }));
    } else if (type === 'default') {
      newValue = [...content];
    }

    setMode(type);
    setValue(newValue);
  }, [type, content]);

  return (
    <Box style={{ width: '100%' }}>
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => setValue(newValue)}>
        <Editable
          readOnly={isViewOnly}
          renderElement={renderElement}
          placeholder={
            mode === 'checklist'
              ? 'to-do'
              : 'Write about whatever you want here...'
          }
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (mode === 'checklist' ||
                (mode === 'default' &&
                  content?.find((node) => node.type === 'check-list-item')))
            ) {
              event.preventDefault();
              const newProperties = {
                type: 'check-list-item',
                checked: false,
                children: [{ text: '' }],
              };
              Transforms.insertNodes(editor, newProperties);
            }
          }}
          onBlur={() => contentUpdateCallback(value)}
        />
      </Slate>
    </Box>
  );
}

function Element(props) {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'check-list-item':
      return <CheckListItemElement {...props} />;
    default:
      return (
        <p style={{ color: '#8A8A8A', fontWeight: 500 }} {...attributes}>
          {children}
        </p>
      );
  }
}

function CheckListItemElement({
  attributes,
  children,
  element,
  updateCallback,
}) {
  const classes = useStyles();
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div className={classes.checkContainer} {...attributes}>
      <span contentEditable={false} style={{ marginRight: '10px' }}>
        <Checkbox
          checked={checked}
          size="small"
          disableRipple
          style={{ color: checked ? '#38CF90' : '#DEDDDD' }}
          onChange={(event) => {
            if (readOnly) return;
            const path = ReactEditor.findPath(editor, element);
            const newProperties = {
              checked: event.target.checked,
            };
            Transforms.setNodes(editor, newProperties, { at: path });
            updateCallback(editor.children);
          }}
        />
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={classes.checkText}
        style={{
          textDecoration: !checked ? 'none' : 'line-through',
          opacity: 1,
          color: '#8A8A8A',
          fontWeight: 500,
        }}>
        {children}
      </span>
    </div>
  );
}

export default NoteContent;
