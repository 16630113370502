import { useEffect, useRef, useState } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { database } from '../firebase/FirebaseIndex';
import {
  getUserForwardedFiles,
  getUserForwardedFilesV2,
} from '../redux/slices/Auth';
import actions from '../redux/actions';
import { importModelViews } from '../redux/slices/View';
import { EVENTS, phTrackEvent } from '../analytics';
import { getTripIdFromPathname, isTripV2 } from '../utils';
import { TripAtcActions } from '../redux/slices/TripV2';
import { TRIP_ACCESS_ROLES } from '../const';

function UserAlerts() {
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);
  const { userId } = useSelector((state) => state.Auth);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [showReviewButton, setShowReviewButton] = useState(false);
  const tripLoaded = useSelector((state) => state.Trips.status) === 'SUCCESS';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const tripId = getTripIdFromPathname(pathname);
  const navigate = useNavigate();
  const tripsAtc = useSelector((state) => state.TripsV2.tripsAtc);
  const firstPrivacyRead = useRef(false);

  useEffect(() => {
    if (tripId && firebaseUser?.uid && tripLoaded) {
      const trip = tripsAtc[tripId];
      const isInTrip = trip?.users?.find((user) => user.id === userId);
      const listenToNotificationsUnsub = onValue(
        ref(database, `tripPrivacySettings/${tripId}`),
        (snapshot) => {
          if (!firstPrivacyRead.current) {
            firstPrivacyRead.current = true;
            return;
          }
          if (!snapshot.exists()) return;
          const tripPrivacySettings = snapshot.val();
          console.log('tripPrivacySettings', tripPrivacySettings, { isInTrip });
          if (!tripPrivacySettings?.linkSharingEnabled && !isInTrip) {
            dispatch(
              TripAtcActions.updateTripUserRole({
                tripId,
                userId,
                role: TRIP_ACCESS_ROLES.REMOVED,
              })
            );
            navigate('/trips');
          } else {
            dispatch(
              TripAtcActions.updateTripPrivacySettings({
                tripId,
                privacySettings: tripPrivacySettings,
              })
            );
          }
        }
      );
      return () => {
        listenToNotificationsUnsub();
        firstPrivacyRead.current = false;
      };
    }
    return () => {};
  }, [tripId, firebaseUser?.uid, tripLoaded]);

  useEffect(() => {
    if (tripId && firebaseUser?.uid && tripLoaded && isTripV2(tripId)) {
      const listenToNotificationsUnsub = onValue(
        ref(database, `user_alerts/${firebaseUser.uid}/meta`),
        (snapshot) => {
          if (!snapshot.val()) return;
          const { newNotification } = snapshot.val();
          if (!newNotification) return;
          // console.log('newNotification', newNotification);
          onValue(
            ref(
              database,
              `user_alerts/${firebaseUser.uid}/alerts/${newNotification}`
            ),
            (notificationSnapshot) => {
              if (!notificationSnapshot.exists()) return;
              if (
                notificationSnapshot.val()?.type === 'emailImportInitAlert' ||
                notificationSnapshot.val()?.type === 'importProcessedAlert'
              ) {
                phTrackEvent({
                  event: EVENTS.SMART_IMPORT.TRIP_EMAIL_SUBMIT,
                });
                setShowReviewButton(true);
                dispatch(
                  actions.View.setShowUnreadForwardedFilesNotificationDot(true)
                );
                dispatch(
                  actions.View.setImportIdToReviewIfNotSet(
                    notificationSnapshot.val()?.meta?.importId
                  )
                );
                try {
                  dispatch(
                    isTripV2(tripId)
                      ? getUserForwardedFilesV2({
                          variables: {
                            id: firebaseUser.uid,
                          },
                        })
                      : getUserForwardedFiles()
                  ).catch((err) => {
                    console.error(err);
                  });
                } catch (e) {
                  console.error(e);
                }
                setSnackbarMessage(
                  notificationSnapshot.val()?.body ||
                    "We've detected details on one of your files"
                );
                setIsSnackbarOpen(true);
              }

              if (notificationSnapshot.val()?.type === 'tripAccessUpdated') {
                if (
                  tripId === notificationSnapshot.val()?.tripId &&
                  notificationSnapshot.val()?.newRole ===
                    TRIP_ACCESS_ROLES.REMOVED
                ) {
                  navigate('/trips');
                }
                console.log('tripAccessUpdated', notificationSnapshot.val());
                dispatch(
                  TripAtcActions.updateTripUserRole({
                    tripId: notificationSnapshot.val()?.tripId,
                    userId,
                    role: notificationSnapshot.val()?.newRole,
                  })
                );
                if (
                  tripId === notificationSnapshot.val()?.tripId &&
                  notificationSnapshot.val()?.newRole ===
                    TRIP_ACCESS_ROLES.EDITOR
                ) {
                  setSnackbarMessage(
                    'Your trip permissions have been updated. You can now edit the trip.'
                  );
                  setIsSnackbarOpen(true);
                }
              }
              // set it null so it doesn't trigger again
              set(ref(database, `user_alerts/${firebaseUser.uid}/meta`), {
                newNotification: null,
              });
            },
            {
              onlyOnce: true,
            }
          );
        }
      );
      return listenToNotificationsUnsub;
    }
    return () => {};
  }, [firebaseUser, tripLoaded, tripId, pathname]);

  return (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={4000}
      sx={{
        marginBottom: 10,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={() => setIsSnackbarOpen(false)}>
      <SnackbarContent
        sx={{
          backgroundColor: '#FFDF97',
          color: '#4E4E4E',
        }}
        message={
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center">
            <Typography>{snackbarMessage}</Typography>
            {showReviewButton && (
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  phTrackEvent({
                    event: EVENTS.SMART_IMPORT.TRIP_IMPORT_NOTIFICATION_CLICK,
                  });
                  setIsSnackbarOpen(false);
                  dispatch(actions.View.setIsFilesTabOpen(true));
                  dispatch(
                    actions.View.setImportsModelView(importModelViews.review)
                  );
                }}>
                Review
              </Typography>
            )}
          </Stack>
        }
      />
    </Snackbar>
  );
}

export default UserAlerts;
