import { CircularProgress } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import DeleteModal from '../../../../molecules/Modal/DeleteModal';
import {
  deleteTripInvitation,
  removeUserFromTrip,
} from '../../../../../redux/slices/TripV2';

const useStyles = makeStyles(() => ({
  accessDropdownAnchor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    padding: '4px 0px 8px 12px',
    borderRadius: 4,
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

function UserAction({
  user,
  invitation,
  isInvitation,
  tripId,
  setFeedback = () => {},
}) {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleRemoveUser = async () => {
    if (isInvitation) {
      setLoader(true);
      try {
        await dispatch(
          deleteTripInvitation({
            variables: {
              tripId,
              id: invitation?.invitationId,
              joinTrip: false,
            },
          })
        );
      } catch (err) {
        // handle error
      }
      setLoader(false);
    } else {
      dispatch(
        removeUserFromTrip({
          variables: {
            id: invitation?.id,
            tripId,
            userId: user?.id,
          },
        })
      );
    }
    setFeedback({
      type: 'SUCCESS',
      feedbackText: 'Removed from your trip!',
    });
    setDeleteModalOpen(false);
  };

  return (
    <>
      <div
        className={classes.accessDropdownAnchor}
        style={{
          color: '#E68A63',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setDeleteModalOpen(true)}>
        {loader ? <CircularProgress size={10} /> : 'Remove'}
      </div>
      <DeleteModal
        executeFunc={handleRemoveUser}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Slow down!"
        descriptionText={`You're about to remove ${
          user?.firstName || invitation?.email
        } from your trip. Are you sure you'd like to do that?`}
        confirmText="Yes, remove them."
        cancelText="No, keep them."
        modalProps={{
          style: { maxWidth: '392px' },
        }}
      />
    </>
  );
}

export default UserAction;
