import React, { useState, Fragment } from 'react';

import {
  IconButton,
  Box,
  Divider,
  Popper,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import {
  ArrowDropDownRounded,
  CheckRounded,
  DeleteOutline,
} from '@mui/icons-material';
import { updateMapPin } from '../../../redux/slices/MapV2';
import { useMapUtils } from '../organisms/Map';
import { PIN_ICONS, COLORS } from '../../../utils';
import useTripAccess from '../../../utils/use-trip-access';

const usePinToMapIconStyles = makeStyles(() => ({
  pinIconWrapper: {
    backgroundColor: '#FFF1E0',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFA766',
    },
  },
  iconButton: {
    padding: '4px 8px',
    color: '#ED702E',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(78, 78, 78, 1)',
    border: 'none',
    fontSize: 12,
  },
  tooltipArrow: {
    border: 'none',
    color: 'rgba(78, 78, 78, 1)',
  },
  selectorPins: {
    background: '#FFF',
    borderRadius: 4,
    boxShadow: '0 0 0 1px #FFA766',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '6px 8px',
  },
  selectorPinButton: {
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    padding: '6px 8px',
  },
}));

function ColorSelector({
  selectedColor = null,
  handlePinColorSelect = () => {},
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        paddingTop: 3,
        paddingBottom: 3,
      }}>
      {COLORS.map((color) => (
        <div
          onClick={() => {
            handlePinColorSelect(color);
          }}
          style={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: color,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {selectedColor && selectedColor === color ? (
            <CheckRounded sx={{ height: 10, width: 12, fill: '#FFF' }} />
          ) : null}
        </div>
      ))}
    </div>
  );
}

function DeleteMapPinButton({ handleDeleteMapPin, setAnchorEl }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#ED702E',
      }}
      onClick={() => {
        setAnchorEl(null);
        handleDeleteMapPin();
      }}>
      <DeleteOutline sx={{ marginRight: '0.5px', fontSize: '20px' }} />
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '500',
        }}>
        Delete map pin
      </Typography>
    </Box>
  );
}

function MapPinCustomizerMenu({
  anchorEl,
  handleUpdate,
  handleClose = () => {},
  selectedColor = null,
  selectedIcon = null,
  handleDeleteMapPin = () => {},
  setAnchorEl = () => {},
}) {
  const classes = usePinToMapIconStyles();

  const handlePinIconSelect = async (pinIconId) => {
    handleUpdate({
      categoryId: pinIconId,
    });
  };

  function CategoryRow({ data }) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {data.map((pinIcon, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <Divider
                orientation="vertical"
                textAlign="center"
                sx={{ borderColor: '#F4F4F4', height: 12, width: '1px' }}
              />
            )}
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.selectorPinButton}
              onClick={() => handlePinIconSelect(pinIcon.id)}
              id={`map-pin-${pinIcon.id}`}>
              <pinIcon.icon
                sx={{
                  height: 16,
                  width: 16,
                  fill: selectedIcon === pinIcon.id ? selectedColor : '#8A8A8A',
                }}
              />
            </IconButton>
          </Fragment>
        ))}
      </div>
    );
  }

  const pinIcons = Object.values(PIN_ICONS);
  return (
    <Popper
      id="map-pins-popper"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{ zIndex: 9999 }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box className={classes.selectorPins}>
          <CategoryRow data={pinIcons.slice(0, 5)} />
          <CategoryRow data={pinIcons.slice(5)} />
          <Divider
            sx={{ width: '100%', borderColor: '#F4F4F4', margin: '2px' }}
          />
          <ColorSelector
            selectedColor={selectedColor}
            handlePinColorSelect={(color) => handleUpdate({ pinColor: color })}
          />

          <>
            <Divider
              sx={{ width: '100%', borderColor: '#F4F4F4', margin: '2px' }}
            />
            <DeleteMapPinButton
              handleDeleteMapPin={handleDeleteMapPin}
              setAnchorEl={setAnchorEl}
            />
          </>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}

function PinToMapIcon({
  mapPinDetails,
  tripId,
  isAdded = false,
  isActive = false,
  handleDeleteMapPin = () => {},
}) {
  const classes = usePinToMapIconStyles();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const { setPlace } = useMapUtils();
  const { canEdit } = useTripAccess();

  const handlePinUpdate = async (pinProps = {}) => {
    if (Object.keys(pinProps || {}).length === 0) return;

    // if pin already exists
    setPlace((val) => ({
      ...val,
      ...pinProps,
    }));
    await dispatch(
      updateMapPin({
        context: {
          tripId,
        },
        variables: {
          id: mapPinDetails.id,
          ...pinProps,
        },
      })
    );
  };

  function CustomMapPinIcon() {
    const CustomIcon = isActive
      ? PIN_ICONS[mapPinDetails?.categoryId || 'default']?.icon
      : PIN_ICONS[mapPinDetails?.categoryId || 'default']?.outlinedIcon;
    return (
      <CustomIcon
        sx={{
          height: 16,
          width: 16,
          fill: 'currentColor',
          '& > path': {
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          },
        }}
      />
    );
  }

  return (
    <Box className={classes.pinIconWrapper} id="map-pins">
      {isAdded && (
        <>
          <IconButton
            disableFocusRipple
            disableRipple
            disabled={!canEdit}
            className={classes.iconButton}
            sx={{ borderRadius: '0 4px 4px 0' }}
            onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            id="map-pins-selector">
            <CustomMapPinIcon />
            {canEdit && (
              <ArrowDropDownRounded
                sx={{
                  height: 20,
                  width: 20,
                  margin: '0 4% 0 4%',
                }}
              />
            )}
          </IconButton>
          {anchorEl && (
            <MapPinCustomizerMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              handleUpdate={handlePinUpdate}
              handleClose={() => setAnchorEl(null)}
              selectedIcon={mapPinDetails?.categoryId || 'default'}
              selectedColor={mapPinDetails?.pinColor || '#ED702E'}
              handleDeleteMapPin={handleDeleteMapPin}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default PinToMapIcon;
