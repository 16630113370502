import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Button,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Typography,
  Backdrop,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles, useTheme } from '@mui/styles';
import { format } from 'date-fns';
import { useParams, useLocation } from 'react-router-dom';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import {
  FilterListIcon,
  BookingErrorIcon,
  RefreshIcon,
  PeopleOutlined,
  NoResultFoundIcon,
  LocationMapPinIcon,
} from '../../../atoms/Icon';
import HotelDetailsCard from './HotelDetailsCard';
import BookingsFilter from './BookingsFilter';
import HotelDetailsCardSkeleton from './HotelDetailsCardSkeleton';
import actions from '../../../../redux/actions';
import config from '../../../config';
import { useMapUtils } from '../Map';
import recommendationCategories from '../../../../assets/newRecommendationCategories.json';
import ALL_PROPERTY_TYPES from '../../../../assets/staysPropertyTypes.json';
import RoomAndGuestButton from './RoomAndPeopleButton';
import guestToString, {
  getStaysSearchConfig,
  stringToGuest,
} from './bookingsUtils';
import HotelsSaveListContainer from './HotelsSaveListContainer';
import CheckInCheckOutButton from './CheckInCheckOutButton';
import SortOptionsMenu from '../Recommendations/SortOptionsMenu';
import classList from '../../../classList';
import { SOUNDS, useSounds } from '../../../../sounds';
import { phTrackEvent, EVENTS } from '../../../../analytics';
import getCfConnector from '../../../cfConnector';
import ITEM_TYPES from '../../../../const';
import useTripAccess from '../../../../utils/use-trip-access';

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: '10px',
    maxWidth: '474px',
    height: '39px',
  },
  suggestionChip: {
    padding: '6px 12px',
    color: 'text.secondary',
    borderRadius: 24,
    height: '100%',
    '& > svg': {
      marginLeft: '-4px !important',
    },
    '& > .MuiChip-label': {
      fontSize: 14,
      paddingLeft: 8,
      paddingRight: 0,
      color: '#4E4E4E',
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  hotelsContainer: {
    margin: '10px 10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  iconDateContainer: {
    margin: 4,
    fontWeight: 400,
    height: '42px',
    backgroundColor: '#FFFFFF',
    color: '#4E4E4E',
    borderRadius: 24,
    border: `1px solid #D9D9D9`,
    // zIndex: theme.zIndex.drawer + 1,
    '& .MuiButton-startIcon': {
      '& svg': {
        transition: '0.25s',
      },
    },
    '&:hover': {
      color: '#4E4E4E',
      backgroundColor: '#F4F4F4',
      '& .MuiButton-startIcon': {
        '& svg': {
          color: theme.palette.primary.light,
        },
      },
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    flexShrink: 1,
  },
  icon: {
    fontSize: '14px',
    color: '#4E4E4E',
  },
  peopleCountMenuItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '63px',
    color: '#4E4E4E',
    '&:hover': {
      color: '#4E4E4E',
    },
  },
  citySearchBar: {
    fontSize: 16,
    backgroundColor: '#F4F4F4',
    borderRadius: 4,
    padding: '12px 8px',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #8A8A8A',
    },
  },
  focusedCitySearchbar: {
    fontSize: 16,
    backgroundColor: '#FFF',
    borderRadius: 4,
    padding: '12px 8px',
    border: '1px solid #FFA766',
    '&:hover': {
      border: '1px solid #FFA766',
    },
  },
  backDrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1122,
  },
  searchOptionsButton: {
    color: '#4E4E4E',
    backgroundColor: '#FFF',
    border: '1px solid #D9D9D9',
    borderRadius: '24px',
    maxHeight: '40px',
    margin: '4px',
    '&:hover': {
      border: '1px solid #D9D9D9',
      backgroundColor: '#F4F4F4',
      color: '#4E4E4E',
    },
  },
}));

function LocationChipContainer({ handleChipClick = () => {} }) {
  const classes = useStyles();

  const { slug: tripId } = useParams();
  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || {});

  const searchTags = useSelector((state) =>
    Array.from(
      new Set(
        Object.values(state.Item.items || {})
          ?.filter(
            (item) =>
              item.type === ITEM_TYPES.DESTINATION &&
              item.tripId === tripId &&
              item.title !== '' &&
              item.mapPin
          )
          ?.map((loc) => {
            const mapPin = mapPins[loc?.mapPin] || {};
            if (!mapPin) return null;
            return {
              title: loc.title,
              latitude: mapPin?.lat,
              longitude: mapPin?.long,
            };
          })
          ?.filter((tag) => Boolean(tag))
      )
    )
  );

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        width: '100%',
        flexWrap: 'wrap',
      }}>
      {searchTags?.map((tag) => (
        <Chip
          className={classes.suggestionChip}
          icon={
            <LocationOnIcon
              sx={{
                height: 16,
                width: 16,
              }}
            />
          }
          onMouseDown={() => handleChipClick(tag)}
          label={tag?.title}
          variant="outlined"
        />
      ))}
    </div>
  );
}

function PlacesToStayView() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const { slug: tripId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [cityLocationDetails, setCityLocationDetails] = useState(null);
  const {
    filterLabels: fLabels = [],
    hotelList,
    otherDetails,
    clickedCardIndex,
    search: { date = { from: null, to: null }, guestCount = {} } = {},
    numberOfNights,
    isSaveListVisible,
  } = useSelector((state) => state.Bookings[tripId] || {});

  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || {});
  const items = useSelector((state) => state.Item.items);
  const { city: citySearchDetails } = useSelector(
    (state) => state.Recommendations.recommendations[tripId] || {}
  );
  const { getPlaceDetails } = useMapUtils();
  const cardRefs = Array.from({ length: 200 }, () => useRef(null));

  const validLocations = useMemo(
    () =>
      Object.values(items || {})
        .filter(
          (loc) =>
            loc.tripId === tripId &&
            loc.title !== '' &&
            loc?.type === ITEM_TYPES.DESTINATION
        )
        .map((loc) => {
          const mapPin = loc.mapPin ? mapPins[loc.mapPin] : null;
          return {
            id: loc.id,
            title: loc.title,
            mapPin,
            hotelsCount:
              loc.children?.filter(
                (childId) => items[childId]?.type === ITEM_TYPES.ACCOMMODATION
              )?.length || 0,
            fromDate: loc.startDate,
            toDate: loc.endDate,
          };
        })
        .filter((tag) => Boolean(tag)),
    [items, tripId]
  );
  const [initialLoad, setInitialLoad] = useState(true);

  const staysHotelKeys = useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(mapPins || {})?.map((mapPin) => mapPin?.hotelId || null)
        )
      ),
    [mapPins]
  );

  const { features, propertyTypes, sortField, sortFieldName } =
    recommendationCategories.BookingOptions;

  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isError, setIsError] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isValueSelected, setIsValueSelected] = useState(false);
  const [anchorGuestEl, setAnchorGuestEl] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(0);
  const [fetchFlip, setfetchFlip] = useState(false);
  const city = useSelector((state) => state.View.exploreCitySearch);
  const setCity = (value) => dispatch(actions.View.setExploreCitySearch(value));
  const { isViewOnly } = useTripAccess();

  const [cityDetails, setCityDetails] = useState({
    latitude: cityLocationDetails?.latitude,
    longitude: cityLocationDetails?.longitude,
  });
  const [pageIndex, setPageIndex] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { playSound } = useSounds();

  const generateQueryString = (_cityDetail) => {
    const optionalParams = {};

    const checkInDate =
      date?.from || new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
    const checkOutDate =
      date?.to || new Date(Date.now() + 4 * 24 * 60 * 60 * 1000);
    const params = {
      sessionID: 'kjnekrfberifrbcekrjbiubgekifr',
      checkin: format(checkInDate, 'yyyy-MM-dd'),
      checkout: format(checkOutDate, 'yyyy-MM-dd'),
      destination: `latlon:${_cityDetail?.latitude},${_cityDetail?.longitude}`,
      responseOptions: 'toprates,images,reviews,filter',
      rooms: guestToString(guestCount),
      sortField: sortField[selectedSortOption],
      sortDirection:
        selectedSortOption === 2 || selectedSortOption === 3
          ? 'ascending'
          : 'descending',
      pageIndex,
    };

    for (let i = 0; i < fLabels.length; i += 1) {
      const [filterValue, filterType] = fLabels[i].split('#');

      if (filterType === 'starRating') {
        if ('starRating' in optionalParams) {
          optionalParams.starRating += `|${filterValue}`;
        } else {
          optionalParams.starRating = filterValue;
        }
      } else if (filterType === 'guestRatings') {
        if ('guestRating' in optionalParams) {
          optionalParams.guestRating += `|${filterValue}`;
        } else {
          optionalParams.guestRating = filterValue;
        }
      } else if (filterType === 'features') {
        if ('features' in optionalParams) {
          optionalParams.features += `|${features[filterValue]}`;
        } else {
          optionalParams.features = features[filterValue];
        }
      } else if (filterType === 'propertyTypes') {
        if ('propertyTypes' in optionalParams) {
          optionalParams.propertyTypes += `|${propertyTypes[filterValue]}`;
        } else {
          optionalParams.propertyTypes = propertyTypes[filterValue];
        }
      } else if (filterType === 'Price') {
        const [minP, maxP] = filterValue.split(',');
        optionalParams.minPrice = Number(minP).toFixed(2);
        optionalParams.maxPrice = Number(maxP).toFixed(2);
      }
    }

    const finalParams = { ...params, ...optionalParams };

    // Convert parameters to a query string
    const queryString = Object.keys(finalParams)
      .map((key) => `${key}=${finalParams[key]}`)
      .join('&');

    return queryString;
  };

  const shouldTriggerSearch = useSelector(
    (state) => state.Bookings[tripId]?.searchTrigger
  );
  const getPropsFromUrl = () => {
    const urlParams = new URLSearchParams(location.search);
    const searchProps = {};
    const allProps = [
      'checkInDate',
      'checkOutDate',
      'rooms',
      'll',
      'p',
      'sortOption',
      'filters',
    ];
    const requiredSearchProps = [
      'checkInDate',
      'checkOutDate',
      'rooms',
      'll',
      'p',
    ];

    allProps.forEach((prop) => {
      if (urlParams.get(prop)) {
        searchProps[prop] = urlParams.get(prop) || null;
      }
    });

    // remove null values
    Object.keys(searchProps)?.forEach(
      (prop) => !searchProps[prop] && delete searchProps[prop]
    );

    if (
      (Object.keys(searchProps) || []).filter((prop) =>
        requiredSearchProps.includes(prop)
      ).length === requiredSearchProps.length
    ) {
      // TODO: Additional validation for each prop
      return {
        title: searchProps?.p,
        latitude: searchProps?.ll?.split(',')[0],
        longitude: searchProps?.ll?.split(',')[1],
        checkInDate:
          new Date(searchProps?.checkInDate) ||
          new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
        checkOutDate:
          new Date(searchProps?.checkOutDate) ||
          new Date(Date.now() + 4 * 24 * 60 * 60 * 1000),
        rooms: stringToGuest(searchProps?.rooms || '2'),
        selectedSortOption: searchProps?.sortOption || 0,
        filters: searchProps?.filters?.split('+') || [],
      };
    }
    return null;
  };

  const updateGuestCount = (newGuestCount) => {
    dispatch(
      actions.Bookings.setGuestCount({ tripId, guestCount: newGuestCount })
    );
  };

  const searchWithUrlParams = (searchProps) => {
    dispatch(
      actions.Bookings.setSearchDate({
        tripId,
        date: {
          from: searchProps?.checkInDate,
          to: searchProps?.checkOutDate,
        },
      })
    );
    dispatch(
      actions.Bookings.setFilter({
        tripId,
        filterItems: searchProps?.filters || [],
      })
    );
    setSelectedSortOption(searchProps?.selectedSortOption || 0);
    setCityDetails({
      title: searchProps?.title,
      latitude: searchProps?.latitude,
      longitude: searchProps?.longitude,
    });
    setCity(searchProps?.title);
    updateGuestCount(searchProps?.rooms);
    setIsValueSelected(true);
    setfetchFlip(!fetchFlip);
  };
  useEffect(() => {
    const searchProps = getPropsFromUrl();
    if (searchProps) {
      searchWithUrlParams(searchProps);
    } else if (citySearchDetails) {
      setCity(citySearchDetails?.title);
      setCityDetails({
        latitude: citySearchDetails?.coordinates?.latitude,
        longitude: citySearchDetails?.coordinates?.longitude,
      });
      setfetchFlip(!fetchFlip);
      setIsValueSelected(true);
    } else if (validLocations?.length > 0) {
      try {
        const defaultLocationToSearch = (validLocations || []).sort(
          (a, b) => a.hotelsCount - b.hotelsCount
        )[0];
        // if defaultLocationToSearch fromDate and toDate are null then take the main trip date
        if (
          !defaultLocationToSearch.fromDate &&
          !defaultLocationToSearch.toDate
        ) {
          defaultLocationToSearch.fromDate = date?.from;
          defaultLocationToSearch.toDate = date?.to;
        }

        const {
          date: searchDate = null,
          cityLocationDetails: searchDetails = null,
        } = getStaysSearchConfig({
          ...defaultLocationToSearch,
        });
        if (searchDetails && searchDate) {
          setCityDetails({
            title: searchDetails?.title,
            latitude: searchDetails?.latitude,
            longitude: searchDetails?.longitude,
          });
          dispatch(actions.View.setExploreCitySearch(searchDetails?.title));
          dispatch(
            actions.Bookings.triggerSearch({
              tripId,
              cityLocationDetails: searchDetails,
              date: searchDate,
            })
          );
          setfetchFlip(!fetchFlip);
          setIsValueSelected(true);
        }
      } catch (err) {
        // error
        // console.log(err);
      }
    }
  }, [citySearchDetails, validLocations]);

  useEffect(() => {
    setfetchFlip(!fetchFlip);
  }, [date, guestCount, selectedSortOption, fLabels]);

  useEffect(() => {
    if (citySearchDetails) {
      setCity(citySearchDetails?.title);
      setCityDetails({
        latitude: citySearchDetails?.coordinates?.latitude,
        longitude: citySearchDetails?.coordinates?.longitude,
      });
      setfetchFlip(!fetchFlip);
      setIsValueSelected(true);
    } else if (validLocations?.length > 0) {
      try {
        const defaultLocationToSearch = (validLocations || []).sort(
          (a, b) => a.hotelsCount - b.hotelsCount
        )[0];
        // if defaultLocationToSearch fromDate and toDate are null then take the main trip date
        if (
          !defaultLocationToSearch.fromDate &&
          !defaultLocationToSearch.toDate
        ) {
          defaultLocationToSearch.fromDate = date?.from;
          defaultLocationToSearch.toDate = date?.to;
        }

        const {
          date: searchDate = null,
          cityLocationDetails: searchDetails = null,
        } = getStaysSearchConfig({
          ...defaultLocationToSearch,
        });
        if (searchDetails && searchDate) {
          setCityDetails({
            title: searchDetails?.title,
            latitude: searchDetails?.latitude,
            longitude: searchDetails?.longitude,
          });
          dispatch(actions.View.setExploreCitySearch(searchDetails?.title));
          dispatch(
            actions.Bookings.triggerSearch({
              tripId,
              cityLocationDetails: searchDetails,
              date: searchDate,
            })
          );
          setfetchFlip(!fetchFlip);
          setIsValueSelected(true);
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }, [citySearchDetails, validLocations]);

  useEffect(() => {
    setfetchFlip(!fetchFlip);
  }, [date, guestCount, selectedSortOption, fLabels]);

  const getHotelSearchData = async (_cityDetail) => {
    if (!_cityDetail?.latitude || !_cityDetail?.longitude) {
      return null;
    }
    if (!loadingButton) {
      setLoading(true);
    }
    setIsError(false);
    setNoResultFound(false);

    const queryString = generateQueryString(_cityDetail);

    // Update the search params
    const urlParams = new URLSearchParams(location.search);
    const searchProps = {
      p: _cityDetail?.title || city,
      ll: `${_cityDetail?.latitude},${_cityDetail?.longitude}`,
      checkInDate: date?.from && format(date?.from, 'yyyy-MM-dd'),
      checkOutDate: date?.to && format(date?.to, 'yyyy-MM-dd'),
      rooms: guestToString(guestCount),
      sortOption: selectedSortOption,
      filters: fLabels.join('+'),
    };

    Object.keys(searchProps).forEach((prop) => {
      if (searchProps[prop]) {
        urlParams.set(prop, searchProps[prop]);
      }
    });

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`
    );

    try {
      const url = `${
        config.recommendationsAPI
      }/search-hotels?${new URLSearchParams({
        searchQuery: queryString,
      })}`;

      const response = (await (await getCfConnector()).get(url)).data;
      setLoading(false);
      if (loadingButton) {
        setLoadingButton(false);
      }
      const { data: responseData, error: responseError, success } = response;
      if (success && !responseData.error) {
        // setData(responseData);
        return responseData;
      }
      if (responseError) {
        setIsError(true);
        dispatch(
          actions.Bookings.setHotelList({
            hotelList: [],
            tripId,
          })
        );
      }
    } catch (err) {
      setIsError(true);
    }
    setLoading(false);
    if (loadingButton) {
      setLoadingButton(false);
    }
    return null;
  };

  const loadData = async (preCityDetail = null) => {
    if (
      ((isValueSelected || initialLoad) &&
        cityDetails?.latitude &&
        cityDetails?.longitude) ||
      (preCityDetail?.latitude && preCityDetail?.longitude)
    ) {
      const coordinates =
        preCityDetail?.latitude && preCityDetail?.longitude
          ? preCityDetail
          : cityDetails;

      if (preCityDetail?.latitude && preCityDetail?.longitude) {
        setCity(preCityDetail?.title);
      }
      const resData = await getHotelSearchData({
        ...coordinates,
        title: preCityDetail?.title || city,
      });
      if (resData && !('results' in resData)) {
        setNoResultFound(true);
      }
      if (resData) {
        window?.heap?.track('stays_search_results', {
          city,
          ...cityDetails,
        });
        const currencyFormatter = new Intl.NumberFormat(undefined, {
          currency: resData?.currencyCode,
          style: 'currency',
        });
        const parsedDate = resData?.results?.map((obj) => {
          return {
            id: obj.id,
            hotelKey: obj.key,
            hotelName: obj.name,
            starRating: obj.starRating,
            guestRating: obj.guestRating,
            numberOfReviews: obj.numberOfReviews,
            guestRatingSentiment: obj.guestRatingSentiment,
            imageLink:
              obj.images?.length > 0 &&
              obj.images[obj.images.length - 1]?.large,
            rates: obj.rates,
            providers: resData.providers,
            currencyCode: resData.currencyCode,
            latitude: obj.latitude,
            longitude: obj.longitude,
            price: currencyFormatter.format(obj.lowestRate),
            nightlyPrice: currencyFormatter.format(
              (obj.lowestRate || 0) / numberOfNights
            ),
            referenceId: obj?.id?.toString(),
            images: obj.images,
            distance: obj?.distance,
            propertyType: ALL_PROPERTY_TYPES[obj?.propertyType || 0] || '',
          };
        });

        if (loadingButton) {
          dispatch(
            actions.Bookings.setHotelList({
              hotelList: [...hotelList, ...parsedDate],
              tripId,
            })
          );
        } else {
          dispatch(
            actions.Bookings.setHotelList({
              hotelList: parsedDate,
              tripId,
            })
          );
        }

        const parsedRegionData = {
          currencyCode: resData?.currencyCode,
          lowestTotalRate: resData?.lowestTotalRate,
          highestTotalRate: resData?.highestTotalRate,
          totalResults: resData?.totalResults,
          totalAvailableResults: resData?.totalAvailableResults,
          totalFilteredResults: resData?.totalFilteredResults,
          priceRanges: resData?.priceRanges,
        };

        dispatch(
          actions.Bookings.setOtherDetails({
            parsedRegionData,
            tripId,
          })
        );
      }
    }
  };

  const inputRef = useRef();

  useEffect(() => {
    if (
      initialLoad &&
      citySearchDetails?.coordinates?.longitude &&
      citySearchDetails?.coordinates?.latitude
    ) {
      setFocus(false);
      inputRef?.current?.blur();

      dispatch(
        actions.Bookings.triggerSearch({
          tripId,
          cityLocationDetails: {
            title: citySearchDetails?.title,
            latitude: citySearchDetails?.coordinates?.latitude,
            longitude: citySearchDetails?.coordinates?.longitude,
            placeId: citySearchDetails?.placeId,
          },
          date,
        })
      );
      loadData({
        title: citySearchDetails?.title,
        latitude: citySearchDetails?.coordinates?.latitude,
        longitude: citySearchDetails?.coordinates?.longitude,
      }).then(() => {
        setInitialLoad(false);
      });
    } else if (initialLoad && citySearchDetails?.redirect) {
      if (!citySearchDetails?.placeId) {
        setFocus(true);
        inputRef?.current?.focus();
      }
      setInitialLoad(false);
    }
  }, [citySearchDetails, loading, hotelList]);

  useEffect(() => {
    loadData();
  }, [fetchFlip]);

  useEffect(() => {
    if (shouldTriggerSearch) {
      dispatch(
        actions.Bookings.triggerSearch({ tripId, cityLocationDetails, date })
      );
      if (cityLocationDetails?.longitude && cityLocationDetails?.latitude) {
        setCityDetails({
          title: cityLocationDetails?.title,
          latitude: cityLocationDetails?.latitude,
          longitude: cityLocationDetails?.longitude,
        });
        setCity(cityLocationDetails?.title || '');
        setIsValueSelected(true);
        setfetchFlip(!fetchFlip);
      } else if (cityLocationDetails?.title) {
        setCity(cityLocationDetails?.title);

        // workaround to render focus after page is rendered to prevent popper scroll
        setTimeout(() => inputRef?.current?.focus(), 400);
      }
    }
  }, [shouldTriggerSearch]);

  // Match url with search props

  const updateDate = (newDate) => {
    dispatch(actions.Bookings.setSearchDate({ tripId, date: newDate }));
  };

  const handleCloseButtonClick = (e) => {
    e.preventDefault();
    setIsValueSelected(false);
    setFilterOpen(false);
    setCity('');
    setLoading(false);
    setIsError(false);
    setPageIndex(0);
    dispatch(actions.Bookings.setHotelList({ hotelList: [], tripId }));
    dispatch(
      actions.Bookings.setCityLocationDetails({
        setCityLocationDetails: null,
        tripId,
      })
    );
    dispatch(
      actions.Bookings.setFilter({
        filterItems: [],
        tripId,
      })
    );
  };

  const handleRefreshButtonClick = () => {
    setIsError(false);
    setfetchFlip(!fetchFlip);
  };

  const handleChipSelect = ({ title, latitude, longitude }) => {
    window?.heap?.track('stays_search_suggestion_click', {
      title,
    });
    setCity(title);
    setCityDetails({
      latitude,
      longitude,
    });
    dispatch(
      actions.Bookings.setCityLocationDetails({
        tripId,
        cityLocationDetails: {
          title,
          latitude,
          longitude,
        },
      })
    );
    setIsValueSelected(true);
    setfetchFlip(!fetchFlip);
  };

  const handleLocationSelect = async (locationResult) => {
    const placeId = locationResult?.place_id;
    const details = await getPlaceDetails(placeId, 'DIRECTIONS_PIN', true);
    setCityDetails({
      placeId,
      latitude: details?.lat,
      longitude: details?.long,
    });
    dispatch(
      actions.Bookings.setCityLocationDetails({
        tripId,
        cityLocationDetails: {
          placeId,
          latitude: details?.lat,
          longitude: details?.long,
          title: details?.title,
        },
      })
    );
    setfetchFlip(!fetchFlip);
  };

  const handleShowMoreClick = () => {
    setLoadingButton(true);
    setPageIndex((x) => x + 1);
    setfetchFlip(!fetchFlip);
  };

  const handleMenuItemClick = (idx) => {
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.SORT_UPDATE,
    });
    if (!Number.isNaN(+idx)) setSelectedSortOption(+idx);
  };

  const dynamicEndIcons =
    city.length > 0 ? (
      <IconButton onClick={handleCloseButtonClick}>
        <CloseIcon />
      </IconButton>
    ) : null;

  useEffect(() => {
    setfetchFlip(!fetchFlip);
  }, [guestCount, selectedSortOption, numberOfNights]);

  useEffect(() => {
    if (anchorGuestEl) {
      setOverlayOpen(true);
    } else {
      setOverlayOpen(false);
    }
  }, [anchorGuestEl]);

  const handleClickAway = () => {
    if (!isMobile && clickedCardIndex) {
      dispatch(
        actions.Bookings.setClickedCardIndex({ cardIndex: null, tripId })
      );
    }
  };

  return (
    <Grid container style={{ marginTop: 20 }}>
      <BookingsFilter filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
      {!isSaveListVisible && (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classList.staysSearchbarContainer}>
          {focus && <div className={classes.backDrop} />}
          <PlacesSearchBar
            fullWidth
            id="search-input"
            onChange={(searchResult) => {
              setCity(searchResult);
            }}
            enterToSelect
            autoSearch
            openOnFocus
            isCitySearch
            showBackdrop
            inputRef={inputRef}
            tripLocation={false}
            handleSelect={(option) => {
              playSound(SOUNDS.softPop);
              handleLocationSelect(option);
              setFocus(false);
              setIsValueSelected(true);
            }}
            popperPlacement="bottom"
            CustomSearchBar={InputBase}
            value={city}
            controlledValue={city}
            defaultPopperComponent={
              focus &&
              validLocations?.length > 0 && (
                <div
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: 12,
                    borderRadius: '4px',
                    minWidth: inputRef?.current?.offsetWidth,
                    zIndex: 1122,
                    boxShadow:
                      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                  }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="#4E4E4E">
                      From your trip
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt="14px">
                    <LocationChipContainer handleChipClick={handleChipSelect} />
                  </Grid>
                </div>
              )
            }
            searchBarProps={{
              variant: 'standard',
              fullWidth: true,
              inputProps: {
                style: {
                  padding: 0,
                },
              },
              placeholder: 'Search for a destination to get started',
              classes: {
                root: classes.citySearchBar,
                focused: classes.focusedCitySearchbar,
              },
              sx: {
                zIndex: focus ? 1123 : 0,
              },
              startIcon: (
                <LocationMapPinIcon
                  style={{
                    marginRight: 8,
                    marginLeft: 4,
                  }}
                />
              ),
              endIcon: dynamicEndIcons,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  inputRef?.current?.blur();
                  setFocus(false);
                }
              },
              onFocus: () => {
                setFocus(true);
              },
              onBlur: () => {
                setFocus(false);
              },
            }}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ paddingTop: isSaveListVisible ? 0 : 2 }}>
        {!isSaveListVisible && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <CheckInCheckOutButton
              date={date}
              updateDate={updateDate}
              overlayOpen={overlayOpen}
              setOverlayOpen={setOverlayOpen}
              setfetchFlip={setfetchFlip}
            />

            <Backdrop
              sx={{
                color: '#fff',
                zIndex: anchorGuestEl ? 1220 : 1099,
              }}
              open={overlayOpen}
              onClick={() => setAnchorGuestEl(null)}
            />

            {!anchorGuestEl ? (
              <Button
                startIcon={
                  <PeopleOutlined sx={{ color: '#1D1D1D', fontSize: '30px' }} />
                }
                className={classes.searchOptionsButton}
                sx={{
                  backgroundColor: anchorGuestEl ? '#D9D9D9' : '#FFFFFF',
                }}
                onClick={(e) => {
                  setAnchorGuestEl(e.currentTarget);
                }}>
                {guestCount?.total}
              </Button>
            ) : (
              <RoomAndGuestButton
                anchorEl={anchorGuestEl}
                setAnchorEl={setAnchorGuestEl}
                guestCount={guestCount}
                setGuestCount={updateGuestCount}
              />
            )}

            {(hotelList?.length !== 0 || noResultFound) && (
              <Button
                startIcon={
                  <FilterListIcon sx={{ color: '#1D1D1D', fontSize: '30px' }} />
                }
                className={classes.searchOptionsButton}
                sx={{
                  backgroundColor: filterOpen ? '#D9D9D9' : '#FFFFFF',
                }}
                onClick={() =>
                  setFilterOpen(
                    filterOpen
                      ? null
                      : document.getElementById('categories-reference')
                  )
                }>
                {`Filters ${fLabels?.length > 0 ? `(${fLabels?.length})` : ''}`}
              </Button>
            )}
          </Box>
        )}
        <Box sx={{ paddingTop: isSaveListVisible ? 0 : 1 }}>
          <Divider />
        </Box>
      </Grid>

      {loading && (
        <Grid container columnSpacing="12px" rowSpacing="2px" mt="16px">
          {[...Array(10).keys()]?.map((idx) => (
            <Grid item key={idx} xs={12} md={6}>
              <HotelDetailsCardSkeleton />
            </Grid>
          ))}
        </Grid>
      )}

      {!loading &&
        hotelList?.length > 0 &&
        city &&
        cityDetails?.longitude &&
        cityDetails?.latitude &&
        !isSaveListVisible && (
          <Box sx={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              mt="16px">
              <Typography
                noWrap
                variant="h4"
                sx={{ fontSize: '1rem', fontWeight: '600' }}>
                Search results
              </Typography>
              <SortOptionsMenu
                selectedSortOption={selectedSortOption}
                sortFieldName={sortFieldName}
                handleMenuItemClick={handleMenuItemClick}
              />
            </Box>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              onClickAway={handleClickAway}>
              <Grid container columnSpacing="12px" rowSpacing="2px" mt="16px">
                {hotelList?.map(
                  (
                    {
                      id,
                      hotelKey,
                      hotelName,
                      starRating,
                      guestRating,
                      numberOfReviews,
                      guestRatingSentiment,
                      providers,
                      rates,
                      imageLink,
                      images,
                      currencyCode,
                      propertyType,
                      latitude,
                      longitude,
                    },
                    index
                  ) => (
                    <Grid item xs={12} md={6}>
                      <HotelDetailsCard
                        disableAddingToTrip={isViewOnly}
                        disableAddingToSavedList={isViewOnly}
                        key={id}
                        hotelKey={hotelKey}
                        index={index}
                        hotelName={hotelName}
                        starRating={starRating}
                        guestRating={guestRating}
                        numberOfReviews={numberOfReviews}
                        guestRatingSentiment={guestRatingSentiment}
                        imageLink={imageLink}
                        rates={rates}
                        providers={providers}
                        currencyCode={currencyCode}
                        referenceId={id?.toString()}
                        images={images}
                        city={city}
                        cardRef={cardRefs[index]}
                        numberOfNights={numberOfNights}
                        propertyType={propertyType}
                        locationsList={validLocations}
                        accomodationKeyId={staysHotelKeys}
                        latlong={{ latitude, longitude }}
                        date={date}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </ClickAwayListener>
            <Box display="flex" justifyContent="center">
              <LoadingButton
                loading={loadingButton}
                disabled={
                  Math.floor((otherDetails?.totalFilteredResults || 0) / 25) <=
                  pageIndex
                }
                onClick={handleShowMoreClick}
                sx={{
                  backgroundColor: '#FFFFFF',
                  color: '#ED702E',
                  width: '50%',
                  border:
                    Math.floor(
                      (otherDetails?.totalFilteredResults || 0) / 25
                    ) <= pageIndex
                      ? '1px solid #8A8A8A'
                      : '1px solid #ED702E',
                  '&:hover': {
                    backgroundColor: '#FFF1E0',
                    color: '#ED702E',
                  },
                  ':active': {
                    backgroundColor: '#FFA766',
                    color: '#FFFFFF',
                  },
                }}>
                {Math.floor((otherDetails?.totalFilteredResults || 0) / 25) <=
                pageIndex
                  ? 'No More Deals'
                  : 'Load more deals'}
              </LoadingButton>
            </Box>
          </Box>
        )}

      {isError && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10%',
            width: '100%',
          }}>
          <BookingErrorIcon />
          <Typography
            sx={{
              color: '#8A8A8A',
              fontWeight: '400',
              fontSize: '24px',
              marginTop: '5%',
              marginBottom: '5%',
            }}>
            Some Error Occured
          </Typography>
          <Button
            startIcon={<RefreshIcon />}
            onClick={handleRefreshButtonClick}>
            Refresh Your Search
          </Button>
        </Box>
      )}

      {isSaveListVisible && !isError && (
        <HotelsSaveListContainer
          locationsList={validLocations}
          accomodationKeyId={staysHotelKeys}
        />
      )}

      {noResultFound && !isError && (
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '30%',
          }}>
          <NoResultFoundIcon />
          <Typography
            sx={{
              fontFamily: 'Inter',
              color: '#000000',
              fontWeight: '500',
              fontSize: '16px',
              marginTop: '5%',
            }}>
            No exact match found.
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

export default PlacesToStayView;
