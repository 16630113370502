/* eslint-disable */
import { useRef, useContext, useState, useEffect, useMemo } from 'react';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PostHogFeature, useFeatureFlagEnabled } from 'posthog-js/react';
import {
  // CircularProgress,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { CameraAlt as CameraAltIcon } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   DragDropContext,
//   Droppable,
//   Draggable,
//   useMouseSensor,
//   useTouchSensor,
// } from 'react-beautiful-dnd';
// import Tour from 'reactour';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { firebaseAuth } from '../../../provider/AuthProvider';
import { LightGreyButton } from '../../atoms/Button/index';
// import AddModuleButton from '../../molecules/AddModuleButton/index';
// import TransportBlock from '../../molecules/TransportBlock';
// import ItinerarySection from '../../organisms/createTrip/ItinerarySection';
import MessengerBlade from '../../organisms/MessengerBlade';
import Map from '../organisms/Map/CustomMap';
import { MapProvider } from '../organisms/Map';
import {
  ItineraryDndProvider,
  // useItineraryDnd,
} from '../../organisms/ItineraryDndProvider';
// import Transportation from '../../organisms/Transportation';
import FilesTab from '../organisms/FilesTab';
import AppLayout from '../../templates/AppLayout';
import LoadingLayout from '../../templates/LoadingLayout';
import CityPage from '../organisms/Recommendations/CityPage';
import { createLocation } from '../../../redux/slices/Location';
// import {
//   handleDndItems,
//   handleDndSections,
//   handleDndActivities,
//   handleDndHotels,
// } from '../../../redux/slices/sharedThunks';

import CitiesList from '../../../assets/citiesList';
// import Recommendations from '../../organisms/Recommendations/Recommendations';
import classList from '../../classList';
import ExportPDFButton from '../../atoms/Button/ExportPDFButton';
import TravelRestrictions from '../../organisms/TravelRestrictions';
import { Events, trackEvents } from '../../../intercom';
import BookingsModal from '../../molecules/Modal/BookingsModal';
import ExploreV1 from '../organisms/Recommendations/ExploreV1';
import TimezoneFixPopup from '../../molecules/GlobalMessagePopup/TimezoneFixPopup';
import TripDateSelector from '../molecules/TripDateSelectorExternal';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';
import { BookingsActions } from '../../../redux/slices/Bookings';
// import CommandBar from '../../organisms/CommandBar';
import FLAGS from '../../../featureFlags';
import BookingView from '../organisms/Stays/V2/BookingView';
import ExportPDFModal from '../molecules/Modal/ExportPDFModal';
import getCfConnector from '../../cfConnector';
import config from '../../config';
import {
  onboardingTourNames,
  flowVariants,
  getSteps,
  stepName,
  // steps,
  tourName as tourNames,
} from '../../../assets/onboarding/steps';
// import GuideStep from '../../atoms/GuideStep';
import actions from '../../../redux/actions';
import { checkIfTourIsCompleted } from '../../../utils/tourUtils';
import { useTour } from '../../molecules/Tour/useTour';
import {
  ImportIcon,
  UnreadForwardedFilesNotificationDot,
  VisaRequirementsIcon,
} from '../../atoms/Icon';
import ForwardedFilesModal from '../../molecules/Modal/ForwardedFilesModal';
import Notes from '../organisms/Notes';
import ITEM_TYPES from '../../../const';
import {
  getCompleteTripUsingAtc,
  getPendingAccessRequestByUserIdAndTripId,
  getPendingAccessRequests,
  updateTripUsingAtc,
} from '../../../redux/slices/TripV2';
import TripNavbarV2 from '../organisms/Navbar/TripNavbar';
import AutoPilotV2 from '../organisms/AutoPilot';
import ImageSelectorModalV2 from '../molecules/Modal/ImageSelectorModal/ImageSelectorModalV2';
import { handleTripCoverImage } from '../../../utils';
import TripItemsList from '../organisms/TripItemsList';
import { ViewActions } from '../../../redux/slices/View';
import { FilesV2Actions } from '../../../redux/slices/FilesV2';
import useTripAccess from '../../../utils/use-trip-access';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    height: `calc(100vh - ${props.tripNavHeight}px)`,
    overflow: 'hidden',
  }),
  plannerContainer: (props) => ({
    overflow: 'auto',
    height: `calc(100vh - ${props.tripNavHeight}px)`,
    paddingBottom: `${props.tripNavHeight + 60}px`,
    backgroundColor: '#FFF',
    flexDirection: 'column',

    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 470,
    },
    [theme.breakpoints.up('md')]: {
      width: '46.8%',
      maxWidth: 767,
    },
  }),
  exploreContainer: {
    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 473,
    },
    [theme.breakpoints.up('md')]: {
      width: '66.1%',
      maxWidth: 677,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 767,
    },
  },
  mapContainer: () => ({
    display: 'flex',
    flex: 1,
    height: `calc(100vh - 55px)`,
    // hard coded from design for responsiveness, don't change
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '0%',
    },
  }),
  coverImage: {
    height: 200,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    margin: '12px 0px 8px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  emptyCoverImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: '8px',
    margin: '12px 0 0 0',
    '&:hover': {
      cursor: 'pointer',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  spacing: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
  },
  exploreSpacing: {
    padding: '0px calc(9.6% - 1rem)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  secondarySpacing: {
    marginLeft: 'calc(9.6% - 2.4rem - 12px)',
    marginRight: 'calc(9.6% - 1rem)',
  },
  instructionsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0% 9.6% 0% calc(9.6% + 1.2rem + 6px)',
    [theme.breakpoints.down('sm')]: {
      padding: '0% 9.6% 0% 9.6%',
    },
  },
  instruction: {
    marginTop: '4vw',
    color: '#8A8A8A',
  },
  locationDroppable: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  item: {
    backgroundColor: 'transparent',
  },
  itemDragging: {
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
  },
  spacingTripTitle: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
    marginTop: '8px',
    marginBottom: '8px',
  },
  iconButtonPropsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px',
  },
  dateStringPropsRoot: {
    marginLeft: '8px',
    marginRight: '0em',
  },
  divider: {
    padding: '0% 0 0% calc(9.6% + 1.2rem + 6px)',
    '& .MuiDivider-middle': {
      marginLeft: '0em',
      marginRight: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0% 0 0% 9.6%',
    },
  },
  droppableSpacing: {
    marginBottom: '5%',
  },
  innerTabsContainer: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    marginTop: 140,
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
      marginTop: 18,
    },
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper1: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  buttonLeft: {
    display: 'flex',
    paddingTop: '12px',
    paddingBottom: '12px',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down(1650)]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },

  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
  },
  snackBarContainer: {
    position: 'fixed',
    bottom: (props) =>
      props.isCommandBarEnabled ? 'calc(25px + 45px)' : '25px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    width: 'auto',
  },
}));

function ItineraryV2() {
  const [openMessenger, setOpenMessenger] = useState(false);
  const [shouldDateUpdate, setShouldDateUpdate] = useState(true);
  const dispatch = useDispatch();
  const [pdfLoading, setPdfLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const plannerScrolled = useSelector((state) => state.View.plannerScrolled);
  const tripsObj = useSelector((state) => state.tripsAtc);
  const tripNavHeight = plannerScrolled ? 64 : 90;
  const { isFilesTabOpen } = useSelector((state) => state.View);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { isViewOnly } = useTripAccess();

  /* FEATURE FLAGS START */
  const isCommandBarEnabled = useFeatureFlagEnabled(FLAGS.UNIVERSAL_ADD_BAR);

  const { playSound } = useSounds();
  // redux state
  const { slug, tab } = useParams();
  const trip = useSelector((state) => state.tripsAtc.tripsAtc[slug]);
  //   const tripItems = useSelector((state) => state.Trips.trips[slug]?.items);
  const tripItems = useSelector((state) =>
    Object.values(state.items.items).filter((item) => item.tripId === slug)
  );
  //   const tripSections = useSelector((state) => state.Section?.sections) || {};
  const tripSections = tripItems.filter(
    (item) => item.type === ITEM_TYPES.HEADING
  );
  const bookingDataObj = useSelector((state) => state.Bookings[slug]);

  const showPoiDetailsPage = useSelector(
    (state) => state.View.poiDetails.isInView
  );
  const {
    inProgress: tourInProgress,
    isOpen: tourOpen,
    tourName: activeTourName,
  } = useSelector((state) => state.View.tour);
  const isOnboardingEnabled = useFeatureFlagEnabled(FLAGS.TRIP_ONBOARDING);
  const createTripFlowEnabled = useFeatureFlagEnabled(FLAGS.CREATE_TRIP_FLOW);
  const { userId } = useSelector((state) => state.Auth);

  const [searchParams] = useSearchParams();
  const { getTour } = useTour();

  window.showStep = (step, tourName, variant = 'default') => {
    if (tourName) {
      dispatch(actions.View.setTourName(tourName));
    }
    dispatch(actions.View.setVariant(variant));
    dispatch(actions.View.setTourCurrentStep(step));
    dispatch(
      actions.View.setTourCurrentStepName(getTour().steps[step]?.stepName)
    );
    getTour().openTour();
  };
  const isFirstTrip = Object.keys(tripsObj.tripsAtc || {}).length === 1;

  useEffect(() => {
    if (trip?.status !== 'SUCCESS') {
      return;
    }
    dispatch(
      actions.View.setVariant(
        isCommandBarEnabled ? flowVariants.commandBar : flowVariants.default
      )
    );
    if (
      !isFirstTrip &&
      !checkIfTourIsCompleted(
        [...Object.values(onboardingTourNames), tourNames.commandBarFlowWeb],
        flowVariants.commandBar
      ) &&
      isCommandBarEnabled &&
      !isMobile &&
      !getTour().tourInProgress
    ) {
      getTour().startTour(tourNames.commandBarFlowWeb);
      phTrackEvent({
        event: EVENTS.ONBOARDING.COMMAND_BAR_OB.SESSION_START,
      });
      return;
    }
    if (
      checkIfTourIsCompleted(Object.values(onboardingTourNames)) ||
      checkIfTourIsCompleted(
        Object.values(onboardingTourNames),
        flowVariants.commandBar
      ) ||
      !isFirstTrip ||
      !createTripFlowEnabled
    ) {
      return;
    }

    if (
      searchParams.get('o') === 'quickstart' &&
      isOnboardingEnabled &&
      !tourInProgress &&
      tripItems?.length > 0 &&
      trip?.status === 'SUCCESS' &&
      !isMobile &&
      Object.keys(tripSections)?.length > 0
    ) {
      const tourName = isMobile ? 'quickStartFlowMobile' : 'quickStartFlowWeb';
      if (!checkIfTourIsCompleted(tourName)) {
        getTour().startTour(tourName);
        phTrackEvent({ event: EVENTS.ONBOARDING.PLAN_OB_QS.SESSION_START });
      }
    }
    if (
      trip?.status === 'SUCCESS' &&
      searchParams.get('o') === 'create-a-trip' &&
      isOnboardingEnabled &&
      !tourInProgress
    ) {
      const tourName = isMobile ? 'emptyTripFlowMobile' : 'emptyTripFlowWeb';
      const blankTripFlowName = isMobile
        ? 'blankTripFlowMobile'
        : 'blankTripFlowWeb';
      const tourSteps = getSteps(tourName, {
        filterWithFeatureFlags: true,
        variant: isCommandBarEnabled
          ? flowVariants.commandBar
          : flowVariants.default,
      });
      if (
        !checkIfTourIsCompleted(tourName) &&
        !checkIfTourIsCompleted(blankTripFlowName)
      ) {
        const tripLocations = tripItems?.filter(
          (item) => item.type === ITEM_TYPES.DESTINATION
        );
        const tripHasTitle = trip.title;

        if (tripLocations?.length === 1 && tripHasTitle) {
          dispatch(actions.View.setTourCurrentStep(2));
          dispatch(actions.View.setTourSkippedStepCount(2));
          dispatch(actions.View.setTourCurrentStepName(tourSteps[2].stepName));
        } else {
          dispatch(actions.View.setTourCurrentStep(tripHasTitle ? 2 : 0));
          dispatch(
            actions.View.setTourCurrentStepName(
              tourSteps[tripHasTitle ? 1 : 0].stepName
            )
          );
          dispatch(actions.View.setTourSkippedStepCount(tripHasTitle ? 1 : 0));
        }
        dispatch(
          actions.View.setTourName(
            tripLocations?.length === 1 ? tourName : blankTripFlowName
          )
        );
        dispatch(actions.View.setTourInProgress(true));
        setTimeout(() => {
          dispatch(actions.View.setTourOpen(true));
          phTrackEvent({
            event: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SESSION_START,
          });
        }, 1000);
      }
    }
    if (
      searchParams.get('o') === 'sample-trip' &&
      isOnboardingEnabled &&
      !tourInProgress &&
      !isMobile
    ) {
      const tourName = isMobile ? 'sampleTripFlowMobile' : 'sampleTripFlowWeb';
      if (!checkIfTourIsCompleted(tourName)) {
        setTimeout(() => {
          getTour().startTour(tourName);
          phTrackEvent({
            event: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_START,
          });
        }, 1000);
      }
    }
  }, [tripItems, tripSections, trip, isOnboardingEnabled]);

  const tmpAudioDivRef = useRef();
  useEffect(() => {
    // TODO: REMOVE THIS IN BY JUNE 20 2024
    if (new URLSearchParams(location?.search).get('addToTrip') === '1') {
      navigate(`/join/${slug}`);
    } else if (
      !trip ||
      trip?.status === 'IDLE' ||
      new URLSearchParams(location?.search).get('forceRefresh') === 'true'
    ) {
      dispatch(getCompleteTripUsingAtc({ tripId: slug })).then(() => {
        dispatch(getPendingAccessRequests({ variables: { tripId: slug } }));
        playSound(SOUNDS.tripWelcomeTone);
      });
    }
  }, []);

  useEffect(() => {
    if (isViewOnly && trip?.status === 'SUCCESS') {
      phTrackEvent({
        event: EVENTS.TRIPS_DASHBOARD.TRIP_VIEW_ONLY_START,
      });
      const url = new URL(window.location.href);
      url.searchParams.set('viewOnly', 'true');

      // update url without reloading the page
      window.history.pushState({}, '', url);
    }
  }, [isViewOnly, trip?.status]);

  useEffect(() => {
    if (userId) {
      dispatch(
        getPendingAccessRequestByUserIdAndTripId({
          variables: { tripId: slug, userId: userId },
        })
      );
    }
  }, [userId]);

  useEffect(() => {
    // disable scroll on body
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    // under explore tab, date should be updated for the 1st time only.
    if (trip && shouldDateUpdate) {
      setShouldDateUpdate(false);
      if (!bookingDataObj) {
        dispatch(
          BookingsActions.initializeTripState({
            tripId: slug,
            startDate: trip?.startDate,
            endDate: trip?.endDate,
          })
        );
      } else if (
        bookingDataObj?.startDate !== trip?.startDate ||
        bookingDataObj?.endDate !== trip?.endDate
      ) {
        dispatch(
          BookingsActions.setSearchDate({
            tripId: slug,
            date: {
              from: trip?.startDate && new Date(trip?.startDate),
              to: trip?.endDate && new Date(trip?.endDate),
            },
          })
        );
      }
    }
  }, [trip]);

  useEffect(() => {
    return () => {
      dispatch(ViewActions.setIsFilesTabOpen(false));
      dispatch(FilesV2Actions.resetFilePreview());
    };
  }, [trip?.id]);

  useEffect(() => {
    let activeBookingId = new URLSearchParams(location?.search).get(
      'openBooking'
    );
    if (activeBookingId && activeBookingId !== 'undefined') {
      if (activeBookingId === 'latest_booking') {
        activeBookingId = trip?.bookings?.[trip?.bookings?.length - 1]?.id;
      }
      dispatch(
        actions.TripsV2.setActiveBookingId({
          tripId: slug,
          bookingId: activeBookingId,
        })
      );
    }
  }, []);

  // trigger to update data in the components.
  const { user } = useContext(firebaseAuth);

  // to add an activity to the last column in a location. Used for pins.
  const [triggerAddActivity, setTriggerAddActivity] = useState(null);
  const [updateItineraryState, setUpdateItineraryState] = useState(false);
  // state that opens up a map popup if set with the suggestion from google-places-api.
  const [openMapPopup, setOpenMapPopup] = useState(null);
  const [changeCoverModalOpen, setChangeCoverModalOpen] = useState(false);
  const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);
  const [isExportPDFModalOpen, setExportPDFModalOpen] = useState(false);
  const [popupAccessAlert, setPopupAccessAlert] = useState(false);

  const classes = useStyles({ tripNavHeight, isCommandBarEnabled });

  const [gettingCity, setGettingCity] = useState(-1);
  const citySuggestionRef = useRef({
    suggestion: null,
  });
  const toggleExportPDFModal = () => {
    if (activeTourName === 'quickStartFlowWeb' && tourOpen) {
      getTour().closeTour();
      return setExportPDFModalOpen((val) => !val);
    }
    if (isExportPDFModalOpen && tourInProgress) {
      getTour().complete();
    }
    return setExportPDFModalOpen((val) => !val);
  };

  // function to render a hidden iframe and render the pdf on the print window.
  const exportOptions = useSelector((state) => state.TripsV2.exportPdf.options);
  const handleExportPdf = async () => {
    const type =
      exportOptions.format === 'img' ? 'image/png' : 'application/pdf';
    setPdfLoading(true);
    toggleExportPDFModal();
    try {
      const connector = await getCfConnector();
      const res = await connector.post(
        process.env.REACT_APP_EXPORT_TRIP_APIV2,
        {
          tripId: slug,
          includeActivityNotes: exportOptions.includeNotes,
          includeImages: exportOptions.includeImages,
          exportMode: exportOptions.format,
        },
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: type,
          },
        }
      );
      const blob = new Blob([res.data], { type });
      const blobUrl = URL.createObjectURL(blob);
      setPdfLoading(false);
      if (!window.open(blobUrl, '_blank')) {
        return setPopupAccessAlert(true);
      }
      trackEvents(Events.TripExported);
    } catch (error) {
      setPdfLoading(false);
    }
    return null;
  };

  function CoverImage() {
    const { canEdit } = useTripAccess();
    const [filledCoverImageHovered, setFilledCoverImageHovered] =
      useState(false);
    if (!['', 'empty'].includes(trip?.coverImage))
      return (
        <div
          id="trip-cover-image"
          className={`${classes.coverImage} ${classList.tripCoverImage}`}
          onMouseEnter={() => setFilledCoverImageHovered(true)}
          onMouseLeave={() => setFilledCoverImageHovered(false)}>
          <img
            style={{ objectFit: 'cover', height: 200, width: '100%' }}
            src={trip?.coverImage}
            alt={trip?.title}
          />
          {filledCoverImageHovered && canEdit && (
            <LightGreyButton
              startIcon={<CameraAltIcon />}
              style={{ position: 'absolute', right: '12px', bottom: '8px' }}
              onClick={() => {
                setChangeCoverModalOpen(true);
                setFilledCoverImageHovered(false);
              }}>
              Change cover photo
            </LightGreyButton>
          )}
        </div>
      );
    return (
      <div
        className={`${classes.emptyCoverImage} ${classList.emptyTripCoverImage}`}
        onClick={() => canEdit && setChangeCoverModalOpen(true)}>
        {canEdit && (
          <Typography variant="h5" color="text.light">
            + Add a trip photo
          </Typography>
        )}
      </div>
    );
  }

  const getRandomCity = () => {
    const randIdx = Math.floor(Math.random() * CitiesList.length);
    citySuggestionRef.current.suggestion = CitiesList[randIdx];
  };

  const handleAddLocation = async (index, setNewLocation) => {
    if (index === 0) {
      getTour()
        .onActiveStep(stepName.ENTER_DESTINATION)
        .closeTour()
        .goToNextStep();
    }

    setGettingCity(index + 1);
    if (trip?.items.some((item) => item.location)) {
      getRandomCity();
    }

    try {
      const res = await dispatch(
        createLocation({
          variables: {
            name: '',
            tripID: slug,
            index: index + 1,
          },
        })
      );
      setNewLocation(res.payload.createLocation.id);
      setUpdateItineraryState(true);
    } catch (e) {
      return e;
    }
    setGettingCity(-1);
    return true;
  };

  const showBookingConfirmation = useMemo(() => {
    return Boolean(
      trip?.activeBookingId &&
        trip?.bookings?.some((item) => item?.id === trip?.activeBookingId)
    );
  }, [trip?.activeBookingId, trip?.bookings]);

  if (!trip || trip.status !== 'SUCCESS') {
    return <LoadingLayout />;
  }
  if (trip.error) {
    return (
      <Typography>{`Error Occured! Contact Admin. ${trip.error}`}</Typography>
    );
  }
  const tripOwner = trip?.owner || [];
  const tripSharedUsers =
    trip?.users?.filter((user) => user?.id !== trip?.owner?.id) || [];
  const tripUsers = [tripOwner, ...tripSharedUsers];
  const tripStartDate = trip.startDate;
  const tripEndDate = trip.endDate;

  function TripDateSelectorMain() {
    return (
      <TripDateSelector
        trip={trip}
        tripStartDate={tripStartDate}
        tripEndDate={tripEndDate}
      />
    );
  }

  function CustomTravelRestrictionButton({ onClick = () => {} }) {
    return (
      <Tooltip title="Visa requirements" arrow placement="top">
        <IconButton
          disableTouchRipple
          disableFocusRipple
          sx={{
            '&:hover': {
              backgroundColor: '#D9D9D9',
              borderRadius: '3px',
            },
          }}
          onClick={onClick}>
          <VisaRequirementsIcon />
        </IconButton>
      </Tooltip>
    );
  }

  function SmartImportButton() {
    const { showUnreadForwardedFilesNotificationDot } = useSelector(
      (state) => state.View
    );
    const { isViewOnly } = useTripAccess();
    return (
      <Tooltip title="Import files" arrow placement="top">
        <IconButton
          disableTouchRipple
          disableFocusRipple
          sx={{
            '&:hover': {
              backgroundColor: '#D9D9D9',
              borderRadius: '3px',
            },
            ...(isViewOnly && {
              opacity: 0.5,
              cursor: 'not-allowed',
            }),
          }}
          onClick={() => {
            if (!isViewOnly) {
              dispatch(actions.View.setIsFilesTabOpen(!isFilesTabOpen));
              phTrackEvent({
                event: EVENTS.SMART_IMPORT.PLAN_IMPORT_CLICK,
              });
            }
          }}>
          <ImportIcon />
          {showUnreadForwardedFilesNotificationDot && (
            <UnreadForwardedFilesNotificationDot
              style={{
                position: 'absolute',
                top: '5px',
                right: '7px',
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    );
  }

  function ActionsBar() {
    return (
      <Grid
        container
        alignItems="center"
        flex-direction="column"
        className={classes.spacingTripTitle}>
        <Box
          bgcolor="#F4F4F4"
          borderRadius="8px"
          px="14px"
          className={classes.buttonWrapper}>
          <div className={classes.buttonLeft}>
            <Stack direction="row" spacing="8px">
              <TravelRestrictions
                CustomButton={CustomTravelRestrictionButton}
              />
              <ExportPDFButton
                style={{ flex: 1 }}
                handleExport={toggleExportPDFModal}
                pdfLoading={pdfLoading}
              />
              <PostHogFeature flag={FLAGS.SMART_IMPORT} match>
                <SmartImportButton />
              </PostHogFeature>
            </Stack>
          </div>
        </Box>
      </Grid>
    );
  }
  return (
    <AppLayout>
      <Helmet>
        <title>{trip?.title ? `${trip.title} | Pilot` : ' '}</title>
      </Helmet>
      <div ref={tmpAudioDivRef} />
      <ImageSelectorModalV2
        handleUpload={(e) => handleTripCoverImage(e, slug)}
        changeCoverModalOpen={changeCoverModalOpen}
        setChangeCoverModalOpen={setChangeCoverModalOpen}
        handleUpdateCoverImage={(image) => {
          dispatch(
            updateTripUsingAtc({
              variables: {
                id: slug,
                coverImage: image,
              },
            })
          ).then(() => {
            phTrackEvent({
              event: EVENTS.PLAN_HEADER.COVER_PHOTO_UPDATE,
            });
            playSound(SOUNDS.photoUpload);
          });
        }}
        showInitialImages
        setErrorSnackbarOpened={setErrorSnackbarOpened}
        trip={trip}
        tripItems={tripItems}
      />

      <BookingsModal />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={popupAccessAlert}
        autoHideDuration={5000}
        onClose={() => setPopupAccessAlert(false)}
        key="auto-flight-search-success-popup">
        <SnackbarContent
          sx={{
            backgroundColor: 'success.main',
            justifyContent: 'center',
          }}
          message="Please enable popups to download your trip."
        />
      </Snackbar>
      <ExportPDFModal
        onExport={handleExportPdf}
        open={isExportPDFModalOpen}
        onClose={toggleExportPDFModal}
      />

      <ItineraryDndProvider
        trip={trip}
        updateItineraryState={updateItineraryState}
        setUpdateItineraryState={setUpdateItineraryState}
        triggerAddActivity={triggerAddActivity}
        setTriggerAddActivity={setTriggerAddActivity}>
        <TripNavbarV2
          user={user}
          tripData={trip}
          people={tripUsers}
          setOpenMessenger={setOpenMessenger}
          activePath={tab}
          refetch={() =>
            dispatch(
              getCompleteTripUsingAtc({ tripId: slug, setLoader: false })
            )
          }
          TripDateSelector={TripDateSelectorMain}
        />
        {tab === 'planner' ? (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid item className={classes.plannerContainer} id="planner">
                {showBookingConfirmation ? <BookingView /> : null}
                <div
                  style={
                    showBookingConfirmation
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <Box>
                    <ActionsBar />

                    <Grid item xs={12} className={classes.spacing}>
                      <CoverImage />
                    </Grid>

                    <div className={classes.secondarySpacing}>
                      <TripItemsList
                        tripId={slug}
                        isCommandBarEnabled={isCommandBarEnabled}
                      />
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid
                id="map-container"
                item
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                  isCommandBarEnabled={isCommandBarEnabled}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        ) : tab === 'notes' ? (
          <Notes tripId={slug} tripNotes={trip?.notes} />
        ) : tab === 'files' ? (
          <MapProvider>
            <FilesTab trip={trip} />
          </MapProvider>
        ) : (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid
                item
                className={`${classes.plannerContainer} ${classes.exploreContainer}`}
                sx={{
                  paddingBottom: showPoiDetailsPage ? '0px !important' : 'auto',
                }}
                id="explore">
                {showPoiDetailsPage ? <CityPage /> : null}
                <div
                  style={
                    showPoiDetailsPage
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <div className={classes.exploreSpacing}>
                    <ExploreV1 />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                id="map-container"
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                  isCommandBarEnabled={isCommandBarEnabled}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        )}
      </ItineraryDndProvider>
      <MessengerBlade
        setOpenMessenger={setOpenMessenger}
        openMessenger={openMessenger}
        chatId={slug}
        tripName={trip?.title}
        tripUsers={tripUsers}
      />
      <AutoPilotV2 />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={errorSnackbarOpened}
        onClose={() => setErrorSnackbarOpened(false)}
        message="Error uploading. Cover photos must be under 10MB."
        ContentProps={{
          sx: {
            backgroundColor: '#F45E62',
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: 16,
            boxShadow: 'none',
            textAlign: 'center',
            justifyContent: 'center',
          },
        }}
      />
      <div className={classes.snackBarContainer} id="snackbar-container" />
    </AppLayout>
  );
}

export default ItineraryV2;
