import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCheckoutDetails } from '../organisms/Stays/checkout/api';
import { CHECKOUT_SESSION_STATUS } from '../organisms/PublicCheckout/constants';
import LoadingLayout from '../../templates/LoadingLayout';

function PublicCheckoutLoading() {
  const { slug: checkoutSessionId } = useParams();
  const navigate = useNavigate();
  const [polling, setPolling] = useState(true);

  useEffect(() => {
    const pollStatus = async () => {
      const checkoutSession = await getCheckoutDetails(checkoutSessionId);

      if (!checkoutSession || !checkoutSession.data) return;

      if (
        checkoutSession.data?.status !== CHECKOUT_SESSION_STATUS.PAYMENT_PENDING
      ) {
        setPolling(false);
        navigate(`/checkout/${checkoutSessionId}`);
      }
    };

    const interval = setInterval(() => {
      if (polling) {
        pollStatus();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [polling, checkoutSessionId, navigate]);

  return <LoadingLayout isNewDesign />;
}

export default PublicCheckoutLoading;
